import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';
import MenuIcon from '@mui/icons-material/Menu';
import { AppBarMenu } from '../../style/BottonAppBar.module.js'
import React, { useState, useEffect } from 'react';
import { useNavigate } from "react-router-dom";
import { Layout, Container, TitleContainer } from '../../style/Home/HomeBanner.module.js'
import BgImg from '../../resources/img_top_bg@2x.png';

export const HomeBanner = () => {
  

  return (
    <Layout>
      <Container>
        <TitleContainer>
          <div>내가 찾던</div>
          <div>면세점 가격이 한곳에!</div>
        </TitleContainer>
        <img
          src={BgImg}
          alt="로고 이미지"
          style={{
            position: "absolute",
            height: "100px",
            right: "0px",
            top: "-30px",
          }}
        />
      </Container>
    </Layout>
  );
}

