import styled from "styled-components";

export const Layout = styled.div`
    display: flex;
    justify-content: center;
    width: 100%;
`

export const Container = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    height: 46px;
    width: 100%;

    border-width: 1px 0px;
    border-color: #eee;
    border-style: solid; 
`

export const MenuItem = styled.div`   
    font-family: Pretendard;
    font-size: 15px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    color: ${props => (props.active ? '#703dff' : '#777')};

    display: flex;
    flex-direction: column;
    justify-content: center;
    text-align: center;
    height: 46px;
    flex-grow: 1;


    border-bottom: ${props => (props.active ? '3px solid #703dff' : '#777')}; 
    // box-sizing: border-box;

    position: relative;
    z-index: 0;
`


export const BorderShortenerL = styled.div`
    width: 65px;
    height: 4px;
    background-color: #fff;
    left: 0;
    bottom: -2.8px;
    z-index: 100;
    position: absolute;
    color: #fff;

    border-width: 0px 0px 1px 0px;
    border-color: #eee;
    border-style: solid; 
`
export const BorderShortenerR = styled.div`
    width: 65px;
    height: 4px;
    background-color: #fff;
    right: 0;
    bottom: -2.8px;
    z-index: 100;
    position: absolute;
    color: #fff;

    border-width: 0px 0px 1px 0px;
    border-color: #eee;
    border-style: solid; 
`

// background-color: ${props => (props.active ? '#703dff' : '#efefef')};
// color: ${props => (props.active ? '#fff' : '#444')}; ;