import {
  CategoryAdvContatiner,
  LogoWrapper,
  AdBanner,
  AdLogo,
} from "../../../style/Home/RecommendSection.module";
import Logo from "../../../resources/Ad/KCND LOGO 1.png";
import { useState } from "react";
import { useEffect } from "react";
import {
  BannerClicked,
  LogoClicked,
  SingleComponentCreateObserver,
  createObserver,
} from "./Ad.module";
import { useRef } from "react";
import { GET_AD_BANNER } from "../gql/queries";
import { client } from "../../../client";

const LogType = {
  AD: "AD",
  GENERAL_LOG: "GENERAL_LOG",
  TEST: "TEST",
};

export const CategoryAdv = (categoryName) => {
  const [logoDisplay, setLogoDisplay] = useState("block");
  const [items, setItems] = useState([]);

  useEffect(() => {
    const fetchAds = async () => {
      try {
        const { data } = await client.query({
          query: GET_AD_BANNER,
          variables: {
            visible: true,
          },
        });

        const tempAds = data.getAdBannerList
          .filter((ad) => ad.account_id >= 2 && ad.account_id <= 9)
          .map((ad, idx) => {
            return {
              ...ad,
              key: idx,
            };
          });

        setItems(tempAds);
      } catch (error) {
        console.error("Error fetching ads:", error);
      }
    };

    fetchAds();
  }, []);

  useEffect(() => {
    const isAnyBannerVisible = items.some(
      (item) => item.name === categoryName.categoryName
    );

    setLogoDisplay(isAnyBannerVisible ? "block" : "none");
  }, [items]);

  const boxRef = useRef([]);

  useEffect(() => {
    const fetchBannerIndex = async () => {
      const index = boxRef.current.findIndex(
        (box) => box.name === categoryName.categoryName
      );
      return index;
    };

    const Observer = async () => {
      const index = await fetchBannerIndex();

      if (index !== -1 && index !== null) {
        SingleComponentCreateObserver(index, items, boxRef);
        setLogoDisplay("block");
      }
    };

    fetchBannerIndex().then((index) => {
      Observer(index);
    });
  }, [items]);

  return (
    <div>
      <CategoryAdvContatiner
        style={{
          display: items.every(
            (item) => item.name !== categoryName.categoryName
          )
            ? "none"
            : "block",
        }}
      >
        {items.map((item, i) => (
          <>
            <LogoWrapper>
              <AdLogo
                src={Logo}
                alt=""
                onClick={LogoClicked}
                style={{
                  display:
                    item.name === categoryName.categoryName ? "block" : "none",
                }}
              />
            </LogoWrapper>
            <AdBanner
              key={item.key}
              name={item.name}
              src={item.image_url}
              alt={item.memo}
              style={{
                display:
                  item.name === categoryName.categoryName ? "block" : "none",
              }}
              onClick={BannerClicked(item)}
              ref={(ref) => (boxRef.current[i] = ref)}
            />
          </>
        ))}
      </CategoryAdvContatiner>
    </div>
  );
};
