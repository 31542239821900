import { gql } from "@apollo/client";

export const GET_AD_BANNER = gql`
  query getAdBannerList($visible: Boolean) {
    getAdBannerList(visible: $visible) {
      id
      account_id
      advertiser
      name
      image_url
      url_link
      visible
      memo
      created_at
      updated_at
    }
  }
`;

export const DELETE_AD_BANNER = gql`
  query deleteAdBanner($id: Int) {
    deleteAdBanner(id: $id) {
      message
    }
  }
`;

export const CREATE_AD_BANNER = gql`
  mutation createAdBanner(
    $account_id: Int
    $advertiser: String
    $image_url: String
    $name: String
    $url_link: String
    $visible: Boolean
    $memo: String
  ) {
    createAdBanner(
      account_id: $account_id
      advertiser: $advertiser
      image_url: $image_url
      name: $name
      url_link: $url_link
      visible: $visible
      memo: $memo
    ) {
      message
    }
  }
`;

export const MODIFY_AD_BANNER = gql`
  mutation modifyAdBanner(
    $id: Int
    $account_id: Int
    $advertiser: String
    $name: String
    $image_url: String
    $url_link: String
    $visible: Boolean
    $memo: String
  ) {
    modifyAdBanner(
      id: $id
      account_id: $account_id
      advertiser: $advertiser
      name: $name
      image_url: $image_url
      url_link: $url_link
      visible: $visible
      memo: $memo
    ) {
      message
    }
  }
`;
