import { useEffect, useState } from "react";
import { GET_AD_BANNER, DELETE_AD_BANNER } from "./Ad.gql";
import { client } from "../../client";

import { AgGridReact } from "ag-grid-react";

import styled from "styled-components";
import { Box } from "@mui/material";

import "@ag-grid-community/styles/ag-grid.css";
import "@ag-grid-community/styles/ag-theme-quartz.css";
import "ag-grid-community/styles/ag-theme-alpine.css";
import { ClientSideRowModelModule } from "@ag-grid-community/client-side-row-model";
import { ModuleRegistry } from "@ag-grid-community/core";
import { TopMenu } from "../_molecule/TopMenu";
import { Footer } from "../common/Footer";
import { AdminFooter } from "./AdminFooter";
ModuleRegistry.registerModules([ClientSideRowModelModule]);

export const AdAdmin = () => {
  const [rowData, setRowData] = useState([]);
  const [bannerData, setBannerData] = useState([]);
  const [rowHeight, setRowHeight] = useState(50);
  const pagination = true;
  const paginationPageSize = 10;
  const paginationPageSizeSelector = [5, 10, 15];

  useEffect(() => {
    const fetchAds = async () => {
      try {
        const { data } = await client.query({
          query: GET_AD_BANNER,
          variables: {},
        });

        const tempAds = data.getAdBannerList;
        setBannerData(tempAds);
      } catch (err) {
        console.error("Error fetching ads:", err);
      }
    };

    fetchAds();
  }, []);

  useEffect(() => {
    const data = bannerData.map((item) => ({
      id: item.id,
      account_id: item.account_id,
      advertiser: item.advertiser,
      name: item.name,
      image_url: item.image_url,
      url_link: item.url_link,
      visible: item.visible,
      memo: item.memo,
      created_at: item.created_at,
      updated_at: item.updated_at,
    }));

    setRowData(data);
  }, [bannerData]);

  // GRID

  const linkRenderer = ({ value }) => {
    return value ? (
      <a href={value} target="_blank" rel="noopener noreferrer">
        {value}
      </a>
    ) : null;
  };

  const register = () => {
    return <Button onClick={handleRegister}>등록</Button>;
  };

  const handleRegister = () => {
    window.location.href = "/uploadad";
  };

  const handleEditClick = (data) => {
    const queryString = new URLSearchParams(data).toString();
    window.location.href = `/details?${queryString}`;
  };

  const handleDeleteClick = async (data) => {
    const confirm = window.confirm("정말 삭제하시겠습니까?");

    if (confirm) {
      try {
        const res = await client.query({
          query: DELETE_AD_BANNER,
          variables: {
            id: data.id,
          },
        });
        window.alert("삭제되었습니다.");
        console.log("delete success :", res);
        window.location.reload();
      } catch (err) {
        console.error(err);
      }
    }
  };

  const imgRenderer = ({ value }) => {
    return value ? <img src={value} /> : null;
  };

  const handleDate = ({ value }) => {
    const intValue = parseInt(value);
    const date = new Date(intValue);

    const formattedDate = date.toLocaleString("ko-kr", {
      timeZone: "Asia/Seoul",
      year: "numeric",
      month: "long",
      day: "numeric",
      // hour: "numeric",
      // minute: "numeric",
      // second: "numeric",
    });

    return <div>{formattedDate}</div>;
  };

  const [colDefs, setColDefs] = useState([]);

  useEffect(() => {
    const handleResize = () => {
      const viewportWidth = window.innerWidth;

      if (viewportWidth <= 400) {
        setColDefs([
          {
            headerName: "구좌",
            field: "account_id",
            flex: 0.6,
            filter: false,
          },
          { headerName: "ID", field: "id", flex: 1 },
          { headerName: "노출", field: "visible", flex: 0.6, filter: false },
          { headerName: "광고명", field: "name", flex: 1.5, filter: true },

          {
            headerName: "",
            field: "상세보기",
            flex: 0.8,
            filter: false,
            cellRenderer: (params) => (
              <Button onClick={() => handleEditClick(params.data)}>
                자세히
              </Button>
            ),
          },
          {
            headerName: "",
            field: "삭제",
            flex: 0.8,
            filter: false,
            cellRenderer: (params) => (
              <Button onClick={() => handleDeleteClick(params.data)}>
                삭제
              </Button>
            ),
          },
        ]);
      } else {
        let newColDefs = [
          {
            headerName: "구좌",
            field: "account_id",
            flex: 0.6,
            filter: false,
          },
          { headerName: "노출", field: "visible", flex: 0.6, filter: false },
          { headerName: "광고명", field: "name", flex: 1.5, filter: true },
        ];

        let tempColDefs = [
          { headerName: "광고주", field: "advertiser", flex: 1 },
          {
            headerName: "URL",
            field: "url_link",
            flex: 1,
            cellRenderer: linkRenderer,
          },
          { headerName: "설명", field: "memo" },
          {
            headerName: "생성일",
            field: "created_at",
            cellRenderer: handleDate,
          },
          {
            headerName: "수정일",
            field: "updated_at",
            cellRenderer: handleDate,
          },
          {
            headerName: "이미지",
            field: "image_url",
            flex: 2.5,
            cellRenderer: imgRenderer,
          },
        ];

        const maxAdditionalColumns = 6;
        const additionalColumns = Math.min(
          maxAdditionalColumns,
          Math.floor((viewportWidth - 400) / 120)
        );

        for (let i = 0; i < additionalColumns; i++) {
          newColDefs.push(tempColDefs[i]);
        }

        newColDefs.push(
          {
            headerName: "",
            field: "상세보기",
            flex: 0.8,
            filter: false,
            cellRenderer: (params) => (
              <Button onClick={() => handleEditClick(params.data)}>
                자세히
              </Button>
            ),
          },
          {
            headerName: "배너 등록",
            field: "삭제",
            flex: 0.8,
            filter: false,
            cellRenderer: (params) => (
              <Button onClick={() => handleDeleteClick(params.data)}>
                삭제
              </Button>
            ),
            headerComponent: register,
          }
        );

        setColDefs(newColDefs);
      }
    };

    if (window.innerWidth >= 1120) {
      setRowHeight(250);
    }

    handleResize();

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const defaultColDef = {
    flex: 1,
    sortable: true,
    filter: true,
  };

  return (
    <div>
      <InnerContainer>
        <TopMenu title={"AD Admin"}></TopMenu>
        <AdminContainer>
          <Box
            className="ag-theme-quartz"
            width={"100%"}
            height={"70vh"}
            fontSize={11}
            marginTop={"30px"}
          >
            <AgGridReact
              rowData={rowData}
              columnDefs={colDefs}
              defaultColDef={defaultColDef}
              pagination={pagination}
              getRowHeight={() => rowHeight}
              paginationPageSize={paginationPageSize}
              paginationPageSizeSelector={paginationPageSizeSelector}
            />
          </Box>
        </AdminContainer>
      </InnerContainer>
      <AdminFooter></AdminFooter>
    </div>
  );
};

const InnerContainer = styled.div`
  background-color: white;
  min-width: 400px;
  max-width: 100vw;
  min-height: 70vh;
  box-shadow: rgba(0, 0, 0, 0.25) 0px 25px 50px -12px;
  padding-left: 30px;
  padding-right: 30px;

  @media (max-width: 400px) {
    min-width: 360px; /* 400px 미만인 경우 */
    width: 100vw; /* 400px 이상인 경우 */
  }
`;

const AdminContainer = styled.div`
  img {
    width: 350px;
  }
`;

const Button = styled.button`
  background-color: inherit;
  color: blue;
  border: none;

  padding: 0;
  margin: 0;
  font-size: 11px;

  width: 40px;

  &:hover {
    cursor: grab;
  }
`;
