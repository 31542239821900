import { useEffect, useState } from "react";
import ReactGA from "react-ga4";
import {
  BrowserRouter as Router,
  Route,
  useHistory,
  useLocation,
} from "react-router-dom";

/**
 * uri 변경 추적 컴포넌트
 * uri가 변경될 때마다 pageview 이벤트 전송
 */
const RouteChangeTracker = () => {
  const location = useLocation();
  const [initialized, setInitialized] = useState(false);

  console.log("이동...");

  // 구글 애널리틱스 운영서버만 적용
  useEffect(() => {
    // if (process.env.REACT_APP_GOOGLE_ANALYTICS) {
    //   ReactGA.initialize(process.env.REACT_APP_GOOGLE_ANALYTICS);
    //   setInitialized(true);
    // }
    // 'G-1CB0ZSGML5'
    ReactGA.initialize("G-1CB0ZSGML5");
    setInitialized(true);
  }, []);

  // location 변경 감지시 pageview 이벤트 전송
  useEffect(() => {
    if (initialized) {
      // ReactGA.set({ page: location.pathname });
      // ReactGA.send("pageview");
      // console.log('ga 기록...', location.pathname)
    }
  }, [initialized, location]);
};

export default RouteChangeTracker;
