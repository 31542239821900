
import React, { useState, useEffect } from 'react';
import { useNavigate } from "react-router-dom";
import {
  ConfirmModalContainer,
  ConfirmModalTitle,
  ConfirmModalMain,
  MoveBtn2,
  CancelBtn,
  MembershipPrice,
} from "./ProductDetail.module";

export const ConfirmModal = ({ isOpen, onClickClose, url, message }) => {
  const navigate = useNavigate();
  const onClickMove = (url) => {
    window.open(url, "_blank");
  };

  return (
    <>
      {isOpen && (
        <ConfirmModalContainer>
          <CancelBtn
            onClick={() => onClickClose()}
            src="https://dfx-img.s3.ap-northeast-2.amazonaws.com/btn_gnb_close%402x.png"
            style={{ position: "absolute", top: "-50px", right: "-5px" }} // Position the button to the top right corner
          >
            {" "}
            닫기 {/* Change the text to "닫기" */}
          </CancelBtn>
          <ConfirmModalTitle>알림!</ConfirmModalTitle>
          <ConfirmModalMain>
            '면세점 가격비교' 는 <br />각 면세점 별{" "}
            <MembershipPrice>실시간 온라인 회원가 </MembershipPrice>를 <br />
            알려드려요
          </ConfirmModalMain>
          <br />
          <ConfirmModalMain>
            면세점 로그인 후, 방금 확인하신 최저가로 <br />
            구매하실 수 있어요.
          </ConfirmModalMain>
          <br />
          <br />
          <MoveBtn2 onClick={() => onClickMove(url)}>면세점 구매하기</MoveBtn2>
        </ConfirmModalContainer>
      )}
    </>
  );
};

