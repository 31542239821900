import React, { useState } from "react";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import { useLocation } from "react-router-dom";
import { Box, Button } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { Title } from "../../component/_molecule/TopMenu.module.js";

export const CategoryMenu = ({ categoryName }) => {
  const [currentCategory, setCurrentCategory] = useState(categoryName);

  const navigate = useNavigate();

  const categoryChange = (categoryName) => {
    setCurrentCategory(categoryName);
    navigate(`/mainCategoryFilter`, {
      state: { categoryName: categoryName },
    });
  };

  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    // setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleMenuItemClick = (categoryName) => {
    categoryChange(categoryName);
    setAnchorEl(null);
  };

  return (
    <>
      <Title
        id="basic-button"
        aria-controls={open ? "basic-menu" : undefined}
        aria-haspopup="true"
        aria-expanded={open ? "true" : undefined}
        onClick={handleClick}
      >
        {categoryName}
      </Title>
      <Menu
        id="basic-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        sx={{ padding: "0", marginTop: "15px" }}
        MenuListProps={{
          "aria-labelledby": "basic-button",
        }}
      >
        {categoryList.map((category) => (
          <MenuItem
            sx={{ width: "360px" }}
            key={category.id}
            onClick={() => handleMenuItemClick(category.categoryName)}
            value={category.categoryName}
          >
            {category.categoryName}
          </MenuItem>
        ))}
      </Menu>
    </>
  );
};

export const categoryList = [
  {
    id: 0,
    categoryName: "스킨케어",
  },
  {
    id: 1,
    categoryName: "메이크업",
  },
  {
    id: 2,
    categoryName: "향수",
  },
  {
    id: 3,
    categoryName: "주류",
  },
  {
    id: 4,
    categoryName: "가방/지갑",
  },
  {
    id: 5,
    categoryName: "시계/쥬얼리",
  },
  {
    id: 6,
    categoryName: "패션/잡화",
  },
  {
    id: 7,
    categoryName: "건강식품",
  },
];
