import styled from "styled-components";

export const Layout = styled.div`
  display: flex;
  // flex-direction: column;
  justify-content: center;
  height: 120px;
  // padding: 0 20px;
`;

export const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 60px;
  width: 360px;
  position: relative;
`;

export const TitleContainer = styled.div`
    position: absolute;
    top : 39px;
    padding-left: 10px;
    font-family: Pretendard;
    font-size: 26px;
    font-weight: 800;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.38;
    letter-spacing: normal;
    text-align: left;
`
