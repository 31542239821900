import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { gql } from "@apollo/client";
import {
  Container,
  ProductTitleContainer,
  ProductInfoContainer,
  ProductNameContainer,
  ProductRefCode,
  ShareBtn,
  PriceGuide,
  InfoArea,
  DutyFreePriceContainer,
  Logo,
  LowestBadge,
  LowestBadgeContainer,
  SoldoutBadge,
  PriceInfo,
  PriceInfoStrong,
  LoadingDisplay,
  LogoContainer,
} from "./ProductDetail.module";
import styled from "styled-components";
import { ConfirmModal } from "./ConfirmModal";
import Cookies from "js-cookie";
import { client } from "../../client";

export const ProductDetailContainer = ({ data }) => {
  const navigate = useNavigate();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedUrl, setSelectedUrl] = useState(null);
  const [isModalAlreadyShown, setIsModalAlreadyShown] = useState(false);

  const CREATE_LOG_MUTATION = gql`
    mutation createLog($create_log_input: CreateLogInput) {
      createLog(create_log_input: $create_log_input)
    }
  `;

  const handleProductClick = (url, duty_free_site, ref_code) => {
    // 1. 모달을 띄운다.
    console.log("modal 을 띄운다.");
    if (isModalAlreadyShown) {
      window.open(url, "_blank");
    } else {
      setIsModalOpen(true);
      setSelectedUrl(url);
    }

    // 2. 모달에서 확인을 누르면 이동한다.
    // Call the mutation function when a product is clicked
    client
      .mutate({
        mutation: CREATE_LOG_MUTATION,
        variables: {
          create_log_input: {
            duty_free_site: duty_free_site,
            brand: data?.brand,
            ref_code: data?.ref_code,
          },
        },
      })
      .then((result) => {
        // Handle successful mutation
        console.log(result.data);
      })
      .catch((error) => {
        // Handle mutation error
        console.log(error);
      });
  };

  const onClickClose = () => {
    setIsModalOpen(false);

    // 모달이 닫힐 때 쿠키 설정 (모달이 더 이상 뜨지 않게 함)
    Cookies.set("modalShown", "true", { expires: 1 / 144, path: "/" }); // expires: 1 / 144는 약 10분 (1일은 1440분)
  };

  useEffect(() => {
    // modal 체크
    // 컴포넌트 마운트 시에 쿠키 체크
    const isModalShown = Cookies.get("modalShown");
    if (isModalShown && isModalShown === "true") {
      setIsModalAlreadyShown(true);
    }
  }, []);

  useEffect(() => {
    // kakao
    const script = document.createElement("script");
    script.src = "https://developers.kakao.com/sdk/js/kakao.js";
    script.async = true;
    document.body.appendChild(script);
    return () => document.body.removeChild(script);
  }, []);

  // send kakao
  const sendKakao = () => {
    // alert('카카오 공유')
    if (window.Kakao) {
      const kakao = window.Kakao;
      // Check if Kakao is already initialized
      if (!kakao.isInitialized()) {
        kakao.init("bd6295d7c2426350afac958e9355f2c6");
      }

      kakao.Link.sendDefault({
        objectType: "feed", // 카카오 링크 공유 여러 type들 중 feed라는 타입 -> 자세한 건 카카오에서 확인
        content: {
          title: data?.brand, // 인자값으로 받은 title
          description: data?.name + " 면세품 최저가를 확인하세요", // 인자값으로 받은 title
          imageUrl: data?.img_resized_url,
          link: {
            mobileWebUrl: window.location.href, // 인자값으로 받은 route(uri 형태)
            webUrl: window.location.href,
          },
        },
        buttons: [
          {
            title: "면세가격 최저가 확인하기",
            link: {
              mobileWebUrl: window.location.href,
              webUrl: window.location.href,
            },
          },
        ],
      });
    } else {
      console.log("kakao 없음");
    }
  };

  const [loading, setLoading] = useState(false);
  const [minPrice, setMinPrice] = useState();
  useEffect(() => {
    let minPriceTmp = 999999;
    data?.product_price.map((price, index) => {
      if (price["is_soldout"] == "TRUE") {
      } else if (
        minPriceTmp >=
        parseFloat(price["price_member_eng"].replace(/[^0-9.-]+/g, ""))
      ) {
        minPriceTmp = parseFloat(
          price["price_member_eng"].replace(/[^0-9.-]+/g, "")
        );
      }
    });
    setMinPrice(minPriceTmp);
  }, [data]);

  if (data == null) {
    return (
      <LogoContainer>
        <img
          src={
            "https://dfx-img.s3.ap-northeast-2.amazonaws.com/img_gnb_logo_kor%403x.png"
          }
          alt="로고 이미지"
          style={{ width: "60%" }}
        />
        {/* #style={{ width: "100px",  height: "auto" }} */}
      </LogoContainer>
    );
    // <LoadingDisplay>
    // </LoadingDisplay>
  }

  return (
    <Container>
      <ConfirmModal
        url={selectedUrl}
        isOpen={isModalOpen}
        onClickClose={onClickClose}
      ></ConfirmModal>
      <img
        src={
          !data?.img_url?.includes("brnd") &&
          data?.img_url != null &&
          data?.img_url != ""
            ? data?.img_url
            : data?.img_resized_url
        }
      ></img>
      <InfoArea>
        <ProductTitleContainer>
          <ProductInfoContainer>
            <ProductNameContainer>
              <div>{data?.brand + " / "}</div>
              <div>{data?.name}</div>
            </ProductNameContainer>
            <ProductRefCode>{data?.ref_code}</ProductRefCode>
          </ProductInfoContainer>
          <ShareBtn
            onClick={sendKakao}
            style={{
              zIndex: "100",
              paddingLeft: "40px",
              paddingBottom: "5px",
            }}
          >
            <img
              src="https://dfx-img.s3.ap-northeast-2.amazonaws.com/btn-share%402x.png"
              style={{ width: "40px" }}
            ></img>
          </ShareBtn>
        </ProductTitleContainer>
        <PriceGuide>
          * 표시되는 상품 가격은 각 면세점의 인터넷 회원가격 입니다.{" "}
        </PriceGuide>

        <div>
          {data?.product_price.map((price, index) => {
            return (
              <DutyFreePriceContainer
                key={index}
                onClick={() =>
                  handleProductClick(
                    price["detail_page_url"].toString(),
                    price?.duty_free_site
                  )
                }
              >
                <Logo>
                  {(() => {
                    switch (price?.duty_free_site) {
                      case "HYUNDAI":
                        return (
                          <img
                            src="https://dfx-img.s3.ap-northeast-2.amazonaws.com/logo_hyundai.png"
                            alt="메뉴"
                            style={{
                              width: "77px",
                              marginLeft: "15px",
                              marginRight: "2px",
                            }}
                          />
                        );
                      case "SHILLA":
                        return (
                          <img
                            src="https://dfx-img.s3.ap-northeast-2.amazonaws.com/logo_shilla.png"
                            alt="메뉴"
                            style={{
                              width: "77px",
                              marginLeft: "15px",
                              marginRight: "2px",
                            }}
                          />
                        );
                      case "LOTTE":
                        return (
                          <img
                            src="https://dfx-img.s3.ap-northeast-2.amazonaws.com/logo_lotte.png"
                            alt="메뉴"
                            style={{
                              width: "77px",
                              marginLeft: "15px",
                              marginRight: "2px",
                            }}
                          />
                        );
                      default:
                        return (
                          <img
                            src="https://dfx-img.s3.ap-northeast-2.amazonaws.com/logo_ssg.png"
                            alt="메뉴"
                            style={{
                              width: "77px",
                              marginLeft: "15px",
                              marginRight: "2px",
                            }}
                          />
                        );
                    }
                  })()}
                </Logo>
                <LowestBadgeContainer>
                  {"TRUE" == price["is_soldout"] ? (
                    <SoldoutBadge>품절</SoldoutBadge>
                  ) : minPrice ==
                    parseFloat(
                      price["price_member_eng"].replace(/[^0-9.-]+/g, "")
                    ) ? (
                    <LowestBadge>최저가</LowestBadge>
                  ) : null}
                </LowestBadgeContainer>
                <PriceInfo>
                  {"(" + price?.price_member_KRW.toLocaleString() + "원)"}
                  <PriceInfoStrong>{price?.price_member_eng}</PriceInfoStrong>
                </PriceInfo>

                <img
                  src="https://dfx-img.s3.ap-northeast-2.amazonaws.com/btn-price-more%402x.png"
                  style={{
                    width: "12px",
                    marginRight: "12px",
                    marginLeft: "7px",
                  }}
                ></img>
              </DutyFreePriceContainer>
            );
          })}
        </div>
      </InfoArea>
    </Container>
  );
};

const HomeInnerContainer = styled.div`
  background-color: white;
  padding-left: 30px;
  padding-right: 30px;
  width: 360px;
  min-height: 100vh;
  box-shadow: rgba(0, 0, 0, 0.25) 0px 25px 50px -12px;
`;
