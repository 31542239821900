
import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

import { SearchContainer, 
  StyledInput , 
  Layout, 
  Container, 
  SearchButton} from '../search/search.module'
import { client } from "../../client"
import { gql } from "@apollo/client";
import debounce from 'lodash/debounce'; // Import debounce
import { AutoCompleteBox, AutoCompleteText, AutoCompleteContainer } from '../search/search.module';
import { KeywordContainer, KeywordItem } from './SearchRank.module';


export const RecentKeyword = ({}) => {

    const navigate = useNavigate(); // Initialize useNavigate
    const [ recentKeywordList, setRecentKeywordList ]  = useState([]);

    useEffect(() => {

      let storedList = localStorage.getItem('recentKeyword');
      let retrievedObject = []
      if(storedList != null ) {
        retrievedObject = JSON.parse(storedList);
      }
      setRecentKeywordList(retrievedObject)

    }, [])
  

  return (
    // <Layout>
    //   <div>아아</div>
    // </Layout>
    <div>
      <h3>최근 검색어</h3>
      <KeywordContainer>
        {recentKeywordList.map((item) => {
            return <KeywordItem 
            onClick={(e) => {
                e.preventDefault(); // Prevent the default anchor behavior
                navigate(`/search?keyword=${encodeURIComponent(item)}`); // Use navigate to handle navigation
              }}>{item}</KeywordItem>
        })}
      </KeywordContainer>
    </div>
  );
};

