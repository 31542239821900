import React, { memo, useEffect, useState } from "react";
import { CREATE_AD_BANNER } from "./Ad.gql";
import styled from "styled-components";
import { client } from "../../client";
import { Container, Layout } from "../../style/Home/RecommendSection.module";

import AWS from "aws-sdk";

import {
  HomeContainer,
  HomeInnerContainer,
} from "../../style/Home/Home.module";

import {
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  TextField,
  Button,
  Input,
} from "@mui/material";
import { TopMenu } from "../_molecule/TopMenu";
import { Footer } from "../common/Footer";

export const AdUploader = () => {
  const [selectedFile, setSelectedFile] = useState(null);
  const [selectedImg, setSelectedImg] = useState(null);

  const ACCESS_KEY = "AKIASCKXHNIYMQBREL6F";
  const SECRET_KEY = "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";
  const REGION = "ap-northeast-2";
  const S3_BUCKET = "dfx-img";

  // AWS S3 설정
  AWS.config.update({
    accessKeyId: ACCESS_KEY,
    secretAccessKey: SECRET_KEY,
    region: REGION,
    logger: console,
  });

  const myBucket = new AWS.S3({
    params: { Bucket: S3_BUCKET },
  });

  const handleFileChange = (e) => {
    setSelectedFile(e.target.files[0]);
    setSelectedImg(URL.createObjectURL(e.target.files[0]));
  };

  const handleUpload = async () => {
    if (!selectedFile) {
      alert("Please select a file");
      return;
    }

    const params = {
      Body: selectedFile,
      Bucket: S3_BUCKET,
      Key: `upload/${selectedFile.name}`,
    };

    try {
      const data = await myBucket.upload(params).promise();
      console.log("File uploaded successfully. ");

      const tempUrl = myBucket.getSignedUrl("getObject", {
        Key: params.Key,
      });

      const imgUrl = tempUrl.split("?")[0];

      handleSubmit(imgUrl);
    } catch (err) {
      console.error("Error uploading file:", err);
    }
  };

  const handleSubmit = async (imgUrl) => {
    try {
      const res = await client.mutate({
        mutation: CREATE_AD_BANNER,
        variables: {
          account_id: parseInt(formData.account_id),
          advertiser: formData.advertiser,
          image_url: imgUrl,
          name: formData.name,
          url_link: formData.url_link,
          visible: formData.visible,
          memo: formData.memo,
        },
      });
      console.log("success!:", res);
      window.location.href = "/adminad";
    } catch (err) {
      console.error(err);
    }
  };

  const [formData, setFormData] = useState({
    account_id: "",
    advertiser: "",
    image_url: "",
    name: "",
    url_link: "",
    visible: true,
    memo: "",
  });

  const handleChange = (event) => {
    const { name, value } = event.target;

    setFormData({ ...formData, [name]: value });
  };

  return (
    <HomeContainer>
      <HomeInnerContainer>
        <TopMenu title={"Upload AD"}></TopMenu>
        <form>
          <FormControl fullWidth sx={{ marginBottom: 2, marginTop: 2 }}>
            <TextField
              label="account_id"
              id="account_id"
              name="account_id"
              value={formData.account_id}
              onChange={handleChange}
            ></TextField>
          </FormControl>

          <FormControl fullWidth sx={{ marginBottom: 2 }}>
            <TextField
              label="advertiser"
              id="advertiser"
              name="advertiser"
              value={formData.advertiser}
              onChange={handleChange}
            ></TextField>
          </FormControl>

          <FormControl fullWidth sx={{ marginBottom: 2 }}>
            <TextField
              label="name"
              id="name"
              name="name"
              value={formData.name}
              onChange={handleChange}
            ></TextField>
          </FormControl>

          <FormControl fullWidth sx={{ marginBottom: 2 }}>
            <TextField
              label="url_link"
              id="url_link"
              name="url_link"
              value={formData.url_link}
              onChange={handleChange}
            ></TextField>
          </FormControl>

          <FormControl fullWidth sx={{ marginBottom: 2 }}>
            <TextField
              label="memo"
              id="memo"
              name="memo"
              value={formData.memo}
              onChange={handleChange}
            ></TextField>
          </FormControl>

          <FormControl fullWidth sx={{ marginBottom: 2 }}>
            <InputLabel>visible</InputLabel>
            <Select
              label="visible"
              name="visible"
              value={formData.visible}
              onChange={handleChange}
            >
              <MenuItem value={true}>노출</MenuItem>
              <MenuItem value={false}>비노출</MenuItem>
            </Select>
          </FormControl>

          <FormControl fullWidth sx={{ marginBottom: 2 }}>
            <ImageContainer>
              <img src={selectedImg} />
            </ImageContainer>

            <Input type="file" onChange={handleFileChange} />
          </FormControl>

          <Button fullWidth onClick={handleUpload}>
            Upload
          </Button>
        </form>
      </HomeInnerContainer>
      <Footer></Footer>
    </HomeContainer>
  );
};

const ImageContainer = styled.div`
  width: 360px;

  img {
    width: 100%;
    height: auto;
    max-height: 250px;
    display: block;
  }
`;
