import styled from "styled-components";

export const Container = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    width: 360px;

    position: relative;
`


export const CancelBtn = styled.div`
    background-image: url( ${props => props.url} );
    background-size: cover;
    width : 50px;
    height : 50px;
    position: absolute;
    right: 14px;
    top: 5px;
`