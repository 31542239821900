import React, { useState, useEffect } from 'react';
import { useNavigate } from "react-router-dom";
import { Layout, Container, LogoContainer, DrawerMenuButton} from '../../style/Home/TopMenu.module.js'
import LogoImg from '../../resources/img_logo@3x.png';
import MenuImg from '../../resources/btn_menu.png';
import { DrawerMenu } from '../_molecule/DrawerMenu.js';
import styled from "styled-components";
import { jwtDecode } from "jwt-decode"; // 여기를 수정했습니다.

const LoginInfo = styled.div`
  display: flex;
  justify-content: flex-end; /* 우측 정렬 */
  align-items: center;
  flex-grow: 1;
  width: 100%;
`;

const getCookie = (name) => {
  const value = `; ${document.cookie}`;
  const parts = value.split(`; ${name}=`);
  if (parts.length === 2) return parts.pop().split(";").shift();
};

export const TopMenu = () => {
  const [drawerOpen, setDrawerOpen] = useState(false);
  const [username, setUsername] = useState(null);
  const navigate = useNavigate();

  useEffect(() => {
    const tokenFromCookie = getCookie("accessToken");
    if (tokenFromCookie) {
      localStorage.setItem("accessToken", tokenFromCookie);
    }
    const token = localStorage.getItem("accessToken");

    if (token) {
      try {
        const decodedToken = jwtDecode(token);
        console.log("decodedToken", decodedToken);
        setUsername(decodedToken.name); // assuming 'name' is the key in the token
      } catch (error) {
        console.error("Failed to decode token", error);
      }
    }
  }, []);

  return (
    <Layout>
      <Container>
        <LogoContainer>
          <img
            src={"https://dfx-front.s3.ap-northeast-2.amazonaws.com/logo1.png"}
            alt="로고 이미지"
            style={{
              minWidth: "180px",
              maxWidth: "90%",
              maxHeight: "70%",
              height: "auto",
            }}
          />
        </LogoContainer>
        <LoginInfo>
          {username ? (
            <div> {username}님</div>
          ) : // <button onClick={() => navigate("/login")}>로그인</button>
          null}
        </LoginInfo>
        <DrawerMenuButton
          onClick={() => {
            setDrawerOpen(!drawerOpen);
          }}
        >
          <img
            src={MenuImg}
            alt="메뉴"
            style={{
              zIndex: "100",
              height: "32px",
              marginRight: "40px",
            }}
          />
        </DrawerMenuButton>
      </Container>
      <DrawerMenu open={drawerOpen} setDrawerOpen={setDrawerOpen}></DrawerMenu>
    </Layout>
  );
};

