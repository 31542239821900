import styled from "styled-components";

export const Layout = styled.div`
  display: flex;
  justify-content: center;
  height: 46px;
  padding: 0 20px;
  padding-top: 10px;
  padding-bottom: 25px;
`;

export const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;

  height: 46px;
  width: 360px;
  position: relative;
`;

export const StyledInput = styled.input`
  width: 320px;
  height: 46px;
  padding-left: 20px;
  border-radius: 23px;
  border: solid 1.5px #000;
  background-color: #fff;
  ::placeholder {
    color: black;
    font-size: 14px;
    opacity: 1; /* Firefox */
  }
`;

export const ResultsContainer = styled.div`
  position: abolute;
  top: 10px;
  left: 10px;
  background-color: white; /* 하얀색 배경 */
  padding: 10px; /* 원하는 여백 */
  /* 추가 스타일을 여기에 적용 */
`;
