import styled from "styled-components";

export const Layout = styled.div`
  display: flex;
  justify-content: center;
  height: 60px;
  width: 100%;
  // margin-top: 1rem;

  flex-direction: column;
`;

export const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 60px;
  padding: 0px 10px;
  // width: 100%;
  // padding: auto;
`;

export const LogoContainer = styled.div`
  display: flex;
  align-items: center; /* Vertically centers the content */
  height: 60px;
`;

export const DrawerMenuButton = styled.div`
  display: flex;
  align-items: center; /* Vertically centers the content */
  height: 50px;
`;