
import React, { useState, useEffect } from 'react';
import { SearchContainer, 
  StyledInput , 
  Layout, 
  Container, 
  SearchButton} from '../search/search.module'
import { client } from "../../client"
import { useNavigate } from "react-router-dom";
import { gql } from "@apollo/client";
import { AutoCompleteBox, AutoCompleteText, AutoCompleteContainer } from '../search/search.module';
import { MostViewdItem, MostViewdItemContainer, ItemImg, ItemName, ItemBrand, 
  ItemPrice, RankStatusContaienr } from './SearchRank.module';


export const MostViewed = ({}) => {

    const navigate = useNavigate(); // Initialize useNavigate
    const img = 'https://cdn.hddfs.com/files/goos/0051/20221117/66e09ec4.jpg?sf=webp&RS=212X212'
    const [ itemList, setItemList ]  = useState([
      {
       'img' : 'https://static.lottedfs.com/prod/prd-img/03/49/91/00/00/02/20000914903_2.jpg/dims/resize/460x460',
       'brand' : 'KANEBO', 
       'name' : 'CREAM IN DAY AND NIGHT', 
       'price' : '120$', 
       'ref_code' : '95486',
      },
      {
       'img' : 'https://static.lottedfs.com/prod/prd-img/13/61/08/01/00/02/20001086113_1.jpg/dims/resize/180x180',
       'brand' : '글렌피딕', 
       'name' : '21 년산 그랑 레제르바', 
       'price' : '30$', 
       'ref_code' : 'GF_21',
      },
      {
       'img' : 'https://image1.shilladfs.com/files/product2/2023/02/22/134010000008_20230222_02251052_11_134010000008_2.JPG',
       'brand' : '유기농 고보습 크림 듀오', 
       'name' : '쁘리마쥬', 
       'price' : '10$', 
       'ref_code' : '크림듀오',
      },
      {
       'img' : 'https://static.lottedfs.com/prod/prd-img/81/97/05/03/00/01/10003059781_4.jpg/dims/resize/180x180',
       'brand' : '맥', 
       'name' : '스트롭 크림', 
       'price' : '20$', 
       'ref_code' : 'S48L01',
      },
    ]);

   
    useEffect(() => {

    }, [])

  

  return (
    <div>
      <h3>많이 본 상품</h3>
      <MostViewdItemContainer>
        {itemList.map((item =>  {
          return <MostViewdItem 
            onClick={(e) => {
              e.preventDefault(); // Prevent the default anchor behavior
              navigate(`/product/${encodeURIComponent(item['ref_code'])}?brand=${encodeURIComponent(item['brand'])}`); // Use navigate to handle navigation
            }}
          >
            <ItemImg src={item['img']}></ItemImg>
            <ItemBrand>{item['brand']}</ItemBrand>
            <ItemName>{item['name']}</ItemName>
            {/* <ItemPrice>{item['price']}</ItemPrice> */}
          </MostViewdItem>
        }))}
      </MostViewdItemContainer>
      {/* <RealTimePopularContainer>
        {keywordList.map((item, idx) => {
            return <RealTimePopularItem>
                    <div>{idx+1}</div> 
                    <div  
                      onClick={(e) => {
                          e.preventDefault(); // Prevent the default anchor behavior
                          navigate(`/search?keyword=${encodeURIComponent(item)}`); // Use navigate to handle navigation
                        }}>{item}
                    </div>
                    <div>{getRandomRankStatus()}</div>
                </RealTimePopularItem>
        })}
      </RealTimePopularContainer> */}
    </div>
  );
};

