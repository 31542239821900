
import React, { useState, useEffect } from 'react';
import { Layout, Container, TitleContainer, 
    CuoponListContainer, CuoponItem
} from '../../style/Home/AirportCouponSection.module.js'
import { CategoryContainer, CategoryChip, 
} from '../../style/Home/RecommendSection.module.js'

import { ButtonSeeMore } from '../../style/common.module.js';
import { useNavigate } from "react-router-dom";
import { TabMenu } from '../_molecule/TabMenu.js';
import { DutyFreeTabLayout } from './DutyFreeTabLayout.js';
import { AirportTabLayout } from './AirportTabLayout.js';

export const TabDisplayLayout = () => {
  const navigate = useNavigate();
  const handleMoreButtonClick = () => {
    navigate('/coupon');
  };

  const getInitialSelectedMenu = () => {
    const savedMenu = sessionStorage.getItem("selectedMenu");
    return savedMenu ? parseInt(savedMenu) : 1;
  };

  const [selectedMenu, setSelectedMenu] = useState(getInitialSelectedMenu);

useEffect(() => {
  sessionStorage.setItem("selectedMenu", selectedMenu);
}, [selectedMenu]);

  const menuList = [
    { id: 1, name : '면세점 쿠폰'}, 
    { id: 2, name : '공항 쿠폰'}
  ]

const DutyFreeSection = <DutyFreeTabLayout>아아</DutyFreeTabLayout>
const AirportSection = <AirportTabLayout>아아</AirportTabLayout>
  

  return (
    <Layout>
      <Container >
      <TabMenu menuList = {menuList} selectedMenu = {selectedMenu} setSelectedMenu={setSelectedMenu}> </TabMenu>
      { selectedMenu == 1 ? DutyFreeSection : AirportSection}
      </Container>
    </Layout>
  );
}

