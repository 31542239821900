import {
  ApolloClient,
  InMemoryCache,
  ApolloProvider,
  createHttpLink,
  gql,
} from "@apollo/client";
import { setContext } from "@apollo/client/link/context";

// HTTP 링크 생성
const httpLink = createHttpLink({
  // uri: "https://dev-api.dutyfree-price.com/graphql",
  uri: `${process.env.REACT_APP_API_URL}`,
});

// 컨텍스트에 토큰 설정
const authLink = setContext((_, { headers }) => {
  const token = localStorage.getItem("accessToken");
  return {
    headers: {
      ...headers,
      authorization: token ? `Bearer ${token}` : "",
    },
  };
});

export const client = new ApolloClient({
  link: authLink.concat(httpLink),
  cache: new InMemoryCache(),
});
