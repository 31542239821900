
import React, { useState, useEffect, useRef } from 'react';
import styled from "styled-components";

import { useHistory } from 'react-router-dom';
import { useNavigate } from "react-router-dom";
import debounce from 'lodash/debounce'; // Import debounce

import Box from '@mui/material/Box';
import CircularProgress from '@mui/material/CircularProgress';
import ReactGA from "react-ga4";



export const InputComponent = ({ typingBrandKeyword, setTypingBrandKeyword, handleSearchBtnClick }) => {
  
  const onValueChange = (value) => {
    setTypingBrandKeyword(value)
  };


  return (
        <SearchContainer>
          <StyledInput 
            // ref={inputRef} // Assign the ref to the input element
            type="text"
            placeholder="브랜드명을 검색해주세요"
            value={typingBrandKeyword}
            onChange={(e) => {onValueChange(e.target.value)}} 
            // onKeyDown={(e) => {
            //   if (e.key === "Enter") {
            //     handleSearchBtnClick(inputValue);
            //     setSearchKeyword(keyword);
            //   }
            // }}
          />
          <SearchButton 
            // onClick={() => handleSearchBtnClick(typingBrandKeyword)} 
            url = {"https://dfx-img.s3.ap-northeast-2.amazonaws.com/btn_search%402x.png"}
          >
         
          </SearchButton>
      </SearchContainer>
  );
};




export const SearchContainer = styled.div`
    display: flex; 
    flex-direction: row;
    justify-content: center;
    width :100%;
    position : relative;
`

export const StyledInput = styled.input`  
    width: 100%;
    height: 42px;
    border-radius: 21px;
    background-color: #f5f5f5;
    padding-left: 1rem;
    border: none; /* or border: 0; */
    outline: none; /* 포커스 시 테두리 제거 */
`

export const SearchButton = styled.div`
    background-image: url( ${props => props.url} );
    background-size: cover;
    width : 24px;
    height : 24px;
    margin-left: 1rem;

    position: absolute;
    right: 1rem; /* Adjust the right positioning as needed */
    top: 20%; /* Center vertically */

`