import React from "react";
import { FaStar, FaStarHalfAlt, FaRegStar } from "react-icons/fa"; // 별 아이콘

const Rating = ({ rating }) => {
  const totalStars = 5;

  return (
    <div className="flex items-center w-[165px] justify-between">
      {Array.from({ length: totalStars }, (_, index) => {
        const starValue = index + 1;
        return (
          <span key={index}>
            {starValue <= rating ? (
              <FaStar className="text-yellow-500 w-[22px] h-[22px]" />
            ) : starValue - 0.5 <= rating ? (
              <FaStarHalfAlt className="text-yellow-500 w-[22px] h-[22px]" />
            ) : (
              <FaRegStar className="text-yellow-500 w-[22px] h-[22px]" />
            )}
          </span>
        );
      })}
      {/* 점수 표시 */}
      <span className="ml-2 font-bold text-black text-[22px] leading-[26px]">
        {rating.toFixed(1)}
      </span>
    </div>
  );
};

export default Rating;
