
import React, { useState, useEffect } from 'react';
import { client } from "../../client"
import { useNavigate } from "react-router-dom";
import { gql } from "@apollo/client";
import { TopMenu } from '../_molecule/TopMenu';
import { Container, Table, CenterContainer } from './Agreement.module';

export const AgreementPrivacy = ({}) => {
  

  return (
    <Container>
      <TopMenu title="개인정보 처리방침"></TopMenu>
      <div>
        <h3>개인정보 처리방침</h3>
        <p>
          <li>
            주식회사 디에프피알(이하 “회사”)는 이용자의 개인정보를 매우 중요하게
            생각하며 정보통신서비스 제공자가 준수하여야 하는 관련 법령 및 규정을
            준수하고 있습니다.
          </li>
          <li>
            본 개인정보처리방침은 회사의 웹사이트 및 모바일 웹, 앱 등에
            적용되며, 다음과 같은 내용을 담고 있습니 다.
            <br />
          </li>
        </p>
        <h3>1. 개인정보의 수집∙이용 목적</h3>
        <li>
          회사는 아래와 같은 목적으로 서비스 제공을 위한 최소한의 개인정보만을
          수집하며, 수집한 정보를 목적 외 로 사용하거나 이용자의 동의없이
          제3자에게 제공하는 등 외부에 공개하지 않습니다.
        </li>
        <li>
          다만, 전자상거래 등에서의 소비자보호에 관한 법률, 국세기본법,
          전자금융거래법 등 관련 법령에 따라 주민 등록번호 및 은행계좌번호의
          수집•보관이 불가피한 경우에는 이용자에게 고지하여 해당 정보를 수집할
          수 있습니다.
        </li>
        <Table>
          <thead>
            <tr>
              <th>구분</th>
              <th>목적</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>회원가입 (관리)</td>
              <td>
                회원제 서비스 제공에 따른 개인식별 및 본인여부, 가입의사 확인,
                이용약관 위반 회원에 대한 이용제한 조치, 가입 및 가입횟수 제한,
                서비스 부정 이용 제재, 비인가 사용 방지, 만 14세 미 만 아동의
                개인정보 수집 시 법정대리인 동의 여부 확인, 추후 법 정대리인
                본인 확인, 고충 처 리 및 분쟁 조정을 위한 기록 보존, 고지사항
                전달, 회원 탈퇴 의사의 확인 등
              </td>
            </tr>
            <tr>
              <td>마케팅 및 광고</td>
              <td>
                접속빈도 파악, 회원의 서비스 이용에 대한 통계, 이벤트 등 광고성
                정보 전달
              </td>
            </tr>
            <tr>
              <td>서비스 이용 및 제공</td>
              <td>
                신규 서비스 개발 및 맞춤 서비스 제공, 인구통계학적 특성에 따른
                서비스 제공 및 광고 게재, 서비스의 유효성 확인, 이벤트•광고성
                정보 및 참여 기회 제공, 서비스 만족도 조사, 계약서•청 구서 발송,
                마케팅 활동, 본인인증, 연령인증 등
              </td>
            </tr>
          </tbody>
        </Table>
        <h3>2. 수집하는 개인정보 항목 및 수집방법</h3>
        <li>회사는 아래와 같은 개인정보 항목을 수집하여 처리하고 있습니다.</li>
        <Table>
          <thead>
            <tr>
              <th>목적</th>
              <th>구분</th>
              <th>수집항목 (필수)</th>
              <th>수집항목 (선택)</th>
              <th>수집 방법</th>
              <th>보유 및 이용기간</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>회원가입</td>
              <td>일반가입</td>
              <td>아이디, 비밀번호, 휴대폰 번호, 이메일, 출생연도</td>
              <td>성별</td>
              <td>홈페이지, 모바일 웹, 모바일 앱</td>
              <td>
                회원 탈퇴 시 즉시 삭제 단, 부정이용 방 지를 위해 회원 ID, IP, 로
                그 기록은 1년까지 보관
              </td>
            </tr>
            <tr>
              <td>회원가입</td>
              <td>SNS가입</td>
              <td>SNS계정, 이메일, 출생 연도</td>
              <td>휴대번호, 성별</td>
              <td>-</td>
              <td>-</td>
            </tr>
            <tr>
              <td>서비스 이용 및 제공</td>
              <td>서비스 제공 및 상담</td>
              <td>접속IP, 접속환경, 접속일시</td>
              <td></td>
              <td>-</td>
              <td>-</td>
            </tr>
            <tr>
              <td>서비스 이용 및 제공</td>
              <td>신규 서비스 개발</td>
              <td>서비스이용기록, APP버전, 고유기기식별</td>
              <td></td>
              <td>-</td>
              <td>-</td>
            </tr>
            <tr>
              <td>서비스 이용 및 제공</td>
              <td>부정이용 기록 확인</td>
              <td>ID, 기기구분타입</td>
              <td></td>
              <td>-</td>
              <td>-</td>
            </tr>
            <tr>
              <td>서비스 이용 및 제공</td>
              <td>주문상품 배송</td>
              <td>
                구매자 이름, 구매자 휴대폰 번호, 수취인 이름, 수취인 휴대폰번호,
                구매상품정보, 배송지 주소
              </td>
              <td></td>
              <td>모바일 앱</td>
              <td>법정 의무 보유기간</td>
            </tr>
            <tr>
              <td>서비스 이용 및 제공</td>
              <td>서비스 입점</td>
              <td>담당자 이름, 담당자 연락처, 담당자 이메일</td>
              <td></td>
              <td>홈페이지</td>
              <td>법정 의무 보유기간</td>
            </tr>
            <tr>
              <td>맞춤 서비스 제공</td>
              <td></td>
              <td>위치정보, 연령대, 성별</td>
              <td></td>
              <td>모바일 앱</td>
              <td>보유하지 않음</td>
            </tr>
          </tbody>
        </Table>
        <li>
          ※ 회사의 서비스 이용 과정에서 서비스 이용기록, 방문기록, 불량
          이용기록, IP 주소, 쿠키, MAC 주소, 모바 일 기기정보(앱 버전, OS버전)
          등의 정보가 자동으로 수집될 수 있습니다.
        </li>
        <li>
          ※ 회사 서비스 제공을 위해서 필요한 최소한의 개인정보이므로 동의를 해
          주셔야 서비스를 이용하실 수 있 습니다.
        </li>
        <li>
          ※ 진행하는 이벤트에 따라 수집 항목이 상이할 수 있으며 응모 시 별도
          동의 후, 목적 달성 즉시 파기합니다.
        </li>
        <li>
          ※ 본인인증은 기관을 통해 처리하고 있으며, 동의 받은 수집 항목 외
          개인정보는 저장하지 않습니다.
        </li>
        <h3>3. 개인정보의 제3자 제공</h3>
        <li>
          회사는 이용자의 개인정보를 수집 시 명시한 범위 내에서만 개인정보를
          처리하며, 원칙적으로 이용자의 개 인정보를 제3자에게 제공하지 않습니다.
        </li>
        <Table>
          <thead>
            <tr>
              <th>제공 항목</th>
              <th>제공 목적</th>
              <th>보유 및 이용기간</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>광고ID, 앱 설치 정보, 통신사 및 통신 위치, 연령대, 성별</td>
              <td>맞춤형회원서비스제공,마케팅 </td>
              <td>법정의무보유기간 </td>
            </tr>
          </tbody>
        </Table>
        <li>
          ※ 법령의 규정에 의거하거나, 수사 목적으로 법령에 정해진 절차와 방법에
          따라 수사기관의 요구가 있는 경 우 이용 자의 개인정보를 제공할 수
          있습니다.
        </li>
        <li>
          ※ 원활한 서비스 제공을 위해 해당 상품 구매 및 제3자 제공 동의하신
          경우에만 개인정보가 제공됩니다.
        </li>
        <h3>4. 개인정보 처리 업무의 위탁</h3>
        <li>
          회사는 기본적인 서비스 제공, 더 나은 서비스 제공, 고객편의 제공 등
          원활한 업무 수행을 위하여 다음과 같이 개인정보 처리 업무를
          외부전문업체에 위탁하여 운영하고 있습니다.
        </li>
        <Table>
          <thead>
            <tr>
              <th>위탁업무내용</th>
              <th>수탁자</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>제품 및 경품 배송</td>
              <td>CJ 대한통운 </td>
            </tr>
          </tbody>
        </Table>
        <li>
          * 위탁 업체 리스트는 해당 서비스 변경 및 계약기간에 따라 변경될 수
          있으며 변경 시 공지사항을 통해 사 전 공지합니다.
        </li>
        <h3>5.개인정보 보유 및 이용기간</h3>
        회사는 이용자의 개인정보 수집•이용 목적이 달성되면 지체 없이 해당
        개인정보를 파기합니다. 단, 1년 이 상 미사용 계정의 경우 관련 법령에 따라
        다른 이용자의 개인정보와 분리하여 별도로 저장•관리하고 있으며, 기타 예외
        사항은 아래와 같습니다.
        <br />
        <Table>
          <thead>
            <tr>
              <th>보존 근거</th>
              <th>보존하는 개인정보 항목</th>
              <th>보유 및 이용기간</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>통신비밀보호법</td>
              <td>웹사이트 방문기록 </td>
              <td>3개월 </td>
            </tr>
            <tr>
              <td>회사 내부 방침</td>
              <td>
                부정이용기록 (불량 혹은 비정상 이용기록): 회원 ID, IP, 로그기록{" "}
              </td>
              <td>1년 </td>
            </tr>
            <tr>
              <td>전자상거래 등에서 소비자 보호에 관한 법률</td>
              <td>계약 또는 청약철회 등에 관한 기록 </td>
              <td>5년 </td>
            </tr>
            <tr>
              <td>-</td>
              <td>대금결제 및 재화 등의 공급에 관한 기록 </td>
              <td>5년 </td>
            </tr>
            <tr>
              <td>-</td>
              <td>소비자의 불만 또는 분쟁처리에 관한 기록 </td>
              <td>3년 </td>
            </tr>
            <tr>
              <td>전자금융거래법</td>
              <td>전자금융에 관한 기록 </td>
              <td>5년 </td>
            </tr>
          </tbody>
        </Table>
        <h3>6. 개인정보 파기절차 및 방법</h3>
        • 회사는 원칙적으로 개인정보 처리목적이 달성된 경우 지체 없이 해당
        개인정보를 파기하며, 파기절차 및 파 기방법은 다음과 같습니다.
        <br />
        <br />
        • 1 파기절차 <br />
        <br />
        • 이용자가 회원가입 등을 위해 입력한 정보는 수집 목적이 달성된 후 별도의
        DB에 옮겨져(종이의 경우 별도 의 서류) 내부 방침 및 기타 관련 법령에 따라
        일정기간 저장된 후 혹은 즉시 파기됩니다. 이 때, 별도의 DB 로 옮겨진
        개인정보는 법률에 의한 경우가 외에는 다른 목적으로 이용되지 않습니다
        <br />
        <br />
        • 2 파기방법 <br />
        <br />
        • 전자적 파일 형태의 정보는 기록을 재생할 수 없는 기술적 방법을 사용하여
        삭제합니다. - 종이에 출력된 개인 정보는 분쇄기로 분쇄하거나 소각을
        통하여 파기합니다.
        <br />
        <h3>7. 이용자 및 법정대리인의 권리와 행사방법</h3>
        이용자 또는 법정대리인은 회사에 대해 언제든지 자신 혹은 만 14세 미만
        아동의 개인정보 보호 관련 권리 를 행사할 수 있습니다. 이용자 또는
        법정대리인은 회사의 개인정보 처리에 동의하지 않는 경우 동의 철회 혹은
        회원 탈퇴를 요청할 수 있습니다. 단, 이 경우 서비스의 일부 또는 전부의
        이용이 어려울 수 있습니다.
        <br />
        <br />
        <br />
        <br />1 개인정보 조회, 수정을 위해서는 “개인정보변경” (또는
        '회원정보수정' 등)을, 회원탈퇴를 위해서는 웹사이 트에서 "회원탈퇴"를
        클릭하여 본인 확인 절차를 거치신 후 직접 열람, 정정 또는 탈퇴가
        가능합니다.
        <br />
        <br />2 혹은 개인정보관리책임자에게 서면, 전화 또는 이메일로 연락하시면
        지체 없이 조치하겠습니다.
        <br />
        <br />3 이용자가 개인정보의 오류에 대한 정정을 요청하는 경우에는 정정을
        완료하기 전까지 당해 개인정보를 이용 또는 제공하지 않습니다. 또한 잘못된
        개인정보를 제3자에게 이미 제공한 경우에는 정정 처리결과를 제3자에게 지체
        없이 통지하여 정정이 이루어지도록 하겠습니다.
        <br />
        <br />4 회사는 이용자 또는 법정 대리인의 요청에 의해 해지 또는 삭제된
        개인정보는 "4. 개인정보의 보유 및 이용기간"에 명시된 바에 따라 처리하고
        그 외의 용도로 열람 또는 이용할 수 없도록 처리하고 있습니다.
        <h3>8. 쿠키의 운영 및 수집 거부 방법</h3>
        1 회사는 이용자에게 맞춤형 서비스를 제공하고, 보다 신속한 서비스 제공을
        위해 이용자에 대한 정보를 저장하고 수시로 불러오는 ‘쿠키(Cookie)’를
        사용합니다.
        <br />
        <br />
        쿠키란 웹사이트 방문 시, 서버가 이용자의 하드디스크에 저장하는 작은 기록
        정보 파일을 말합니다. 이후 이용자가 웹사이트에 방문할 경우 웹사이트
        서버는 이용자의 하드디스크에 저장되어 있는 쿠키의 내용을 읽 어 이용자의
        환경설정을 유지하고 맞춤 화된 서비스를 제공합니다. 쿠키는 개인을
        식별하는 정보를 자동적·능동적으로 수집하지 않으며, 이용자는 언제든지
        이러한 쿠키의 저장 을 거부하거나 삭제할 수 있습니다. 단, 쿠키 저장을
        거부하는 경우에는 일부 서비스 이용이 어려울 수 있습 니다.
        <br />
        <br />2 쿠키의 설치/운용 및 거부 방법 안내
        <br />
        <br />
        이용자는 사용하는 웹 브라우저의 옵션을 설정함으로써 모든 쿠키를 허용
        혹은 거부하거나, 쿠키가 저장될 때마다 확인을 거치도록 지정할 수
        있습니다.
        <br />
        <br />
        <br />
        <br />- Internet Explorer의 경우
        <br />
        <br />
        [도구] 메뉴에서 [인터넷 옵션]을 선택합니다. [개인정보 탭]에서
        설정합니다.
        <br />
        <br />
        [개인정보취급 수준]을 설정합니다.
        <br />
        <br />- Chrome의 경우
        <br />
        <br />
        [설정] 하단 [고급설정 표시]를 클릭합니다.
        <br />
        <br />
        [개인정보] 컨텐츠 설정 → [쿠키] 영역에서 원하시는 정책을 선택합니다.
        <h3>9. 개인정보 보호를 위한 기술적∙관리적 보호 대책</h3>
        “회사”는 이용자들의 개인정보 보호를 위해 다음과 같은 기술적∙관리적
        노력을 하고 있습니다.
        <br />
        <br />1 비밀번호 암호화
        <br />
        <br />
        이용자의 비밀번호는 복호화 되지 않는 방법으로 암호화되어 저장, 관리되고
        있습니다.
        <br />
        <br />2 해킹 등에 대비한 대책
        <br />
        <br />
        회사는 해킹이나 악성코드에 의하여 이용자들의 개인정보가 유출• 훼손되는
        것을 방지하기 위하여 침입차단 시스템을 24시간 운영하여 외부로부터의
        무단접근을 통제하고 있으며, 백신 프로그램을 설치하여 시스템이 악성코드나
        바이러스에 감염되지 않도록 노력하고 있습니다. 또한 개인정보의 훼손에
        대비하여 자료를 수시 로 백업하고 있고, 암호화통신 등을 통하여 네트워크
        상에서 개인정보를 안전하게 전송할 수 있도록 하고 있습니다. 더불어 회사는
        더욱 안전한 개인정보 취급을 위해 최대한의 기술적 방법을 구비하기 위해
        노력하 고 있습니다.
        <h3>10. 개인정보 관리책임자 및 담당부서</h3>
        이용자는 회사의 서비스를 이용하는 중 발생하는 모든 개인정보 관련 문의,
        불만처리 등에 관한 사항을 개 인정보 관리 책임자 혹은 담당부서로 문의할
        수 있습니다. 회사는 이용자의 문의에 대한 신속하고 성실한 답변 및 처리를
        위해 노력하고 있습니다.
        <br />
        <br />
        개인정보 관리 책임자 <br />
        담당부서: 서비스 운영파트 <br />
        개인정보관리 책임자: 안상준 <br />
        이메일 주소:
        <a href="mailto:sweet@dfpr.kr">sweet@dfpr.kr</a>
        <br />
        <br />
        기타 개인정보침해에 대한 신고나 상담이 필요하신 경우에는 아래 기관으로
        문의하시기 바랍니다.
        <br />
        <a href="http://cyberbureau.police.go.kr">
          <br />- 경찰청 사이버안전국
          <br />
          http://cyberbureau.police.go.kr / (국번없이) 182
          <br />
        </a>
        <a href="http://www.spo.go.kr">
          <br />- 대검찰청 사이버수사과
          <br />
          http://www.spo.go.kr / (국번없이) 1301
          <br />
        </a>
        <a href="http://privacy.kisa.or.kr">
          <br />- 개인정보 침해신고센터 (한국인터넷진흥원 운영)
          <br />
          http://privacy.kisa.or.kr / (국번없이) 118
          <br />
        </a>
        <a href="http://www.kopico.go.kr">
          <br />- 개인정보 분쟁조정위원회 (한국인터넷진흥원 운영)
          <br />
          http://www.kopico.go.kr / (국번없이) 118
          <br />
        </a>
        <h3>11. 링크 사이트에 대한 책임</h3>
        회사는 이용자에게 다른 웹사이트에 대한 링크를 제공할 수 있습니다. 그러나
        링크 웹사이트들은 회사의 개 인정보처리방침이 적용되지 않으므로, 해당
        링크를 통해 외부 웹사이트로 이동하시는 경우, 해당 서비스의 정책을
        검토하시기 바랍니다.
        <h3>12. 개인정보처리방침의 고지 의무</h3>
        회사는 개인정보처리방침에 대한 변경이 있을 경우에는 개정
        개인정보처리방침의 시행일로부터 최소 7일 전 에 홈페이지의 공지사항 또는
        이메일을 통해 고지합니다.
        <br />
        <h3>13. 개정이력</h3>
        본 개인정보처리방침은 아래 시행일자부터 시행됩니다.
        <br />- 개인정보처리방침 시행일: 2023년 11월 21일
        <CenterContainer>
          <div>개인정보처리방침</div>
          <br />
          <div>주식회사 디에프피알 사업자 정보</div>
        </CenterContainer>
        <p>
          <br />
          <br />
          주식회사 디에프피알
          <br />
          <br />
          주소 : 서울특별시 마포구 독막로 9길 18, 3층 B2호 (서교동) 사업자
          등록번호: 408-86-17825
          <br />
          <br />
          고객센터:
          <a href="mailto:betheone@dfpr.kr">betheone@dfpr.kr</a>
          <br />
          <br />
          <br />
          면세가격비교는 상품에 직접 관여하지 않으며 상품주문, 배송 및 환불의
          의무와 책임은 각 판매업체에 있습니다.
          <br />
          면세가격비교와 해당 상품을 판매하는 쇼핑몰에서 제공하는 상품정보와
          가격은 일치하지 않을 수 있습니다. 해당 쇼핑몰에서 상품정보 및 가격을
          반드시 확인하여 주시기 바랍니다.
          <br />
          <br />© 2023. DFPR Co., LTD All Rights Reserved.
        </p>
      </div>
    </Container>
  );
};

