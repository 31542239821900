import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import { TopMenu } from '../component/_molecule/TopMenu';
import { HomeContainer } from '../style/Home/Home.module';
import { TabDisplayLayout } from '../component/coupon/TabDisplayLayout';

export const CouponV2 = () => {
  return (
    <HomeContainer >
      <TopMenu title = '쿠폰'></TopMenu>
      <TabDisplayLayout></TabDisplayLayout>
   </HomeContainer >
  );
}

