import React, { useState, useEffect } from "react";
import {
  Layout,
  Container,
  TitleContainer,
  CategoryContainer,
  CategoryChip,
  ProductListContainer,
  ProductItemContainer,
  ProductItemImageContainer,
  ProductItemInfoContainer,
  ProductItemPriceInfo,
  ProductItemBrandNameInfo,
  ProductBrand,
  ProductName,
  ProductDutyFreeSite,
  ProductPriceEng,
  ProductPriceKr,
  ProductPriceContainer,
  LowestPrice,
  Underline,
} from "../../style/Home/RecommendSection.module.js";
import { client } from "../../client";
import { GET_RECO_PRODUCT } from "./gql/queries.js";
import { useNavigate } from "react-router-dom";

export const RecommendSection = () => {
  const [selectedCategory, setSelectedCategory] = useState(
    localStorage.getItem("selectedCategory") == null
      ? "향수/바디/헤어"
      : localStorage.getItem("selectedCategory")
  );
  const [itemList, setItemList] = useState([]);

  const navigate = useNavigate();
  const handleClick = (ref_code, brand, price_normal_eng) => {
    navigate(
      `/product/${ref_code}?brand=${brand}&price_normal=${price_normal_eng}`
    );
  };

  const handleCategorySelect = (index, category) => {
    console.log("category...", category);
    localStorage.setItem("selectedCategory", category);
    localStorage.setItem("selectedCategoryIndex", index);
    setSelectedCategory(category);
    client
      .query({
        query: GET_RECO_PRODUCT,
        // fetchPolicy: "no-cache",

        variables: {
          index: index,
        },
      })
      .then((result) => {
        console.log("!!!");
        console.log(result.data.getHomeRecommendData);
        setItemList(
          result.data.getHomeRecommendData[0]["category_data"]
          //   .map((item) => {
          //     return {
          //         brand : item['data'][0]['brand'],
          //         product :item['data'][0]['name'],
          //         duty_free_site :item['data'][0]['duty_free_site'],
          //         price_eng : item['data'][0]['price_member_eng'],
          //         price_kr : item['data'][0]['price_normal_eng'],
          //         resized_img_url : item['data'][0]['img_resized_url'] != '' ? item['data'][0]['img_resized_url'] : item['data'][0]['img_url']
          //     }
          //   })
        );
      })
      .catch((err) => {
        console.log("error 발생", err);
      });
  };

  useEffect(() => {
    client
      .query({
        query: GET_RECO_PRODUCT,
        //   fetchPolicy: "no-cache",
        variables: {
          index:
            localStorage.getItem("selectedCategoryIndex") == null
              ? 0
              : parseInt(localStorage.getItem("selectedCategoryIndex")),
        },
      })
      .then((result) => {
        setItemList(result.data.getHomeRecommendData[0]["category_data"]);
      });
  }, []);

  const categoryList = [
    { id: 0, categoryName: "향수/바디/헤어" },
    { id: 1, categoryName: "화장품" },
    { id: 2, categoryName: "가방" },
    { id: 3, categoryName: "시계" },
    { id: 4, categoryName: "쥬얼리" },
    { id: 5, categoryName: "주류" },
  ];

  return (
    <Layout>
      <Container>
        {/* <StyledInput type="text" placeholder="상품을 검색해주세요" /> */}
        <Underline></Underline>
        <TitleContainer> 가격 비교 추천 아이템 </TitleContainer>
        <CategoryContainer>
          {categoryList.map((item) => {
            return (
              <CategoryChip
                key={item["id"]}
                active={selectedCategory === item["categoryName"]}
                onClick={() =>
                  handleCategorySelect(item["id"], item["categoryName"])
                }
              >
                {" "}
                {item["categoryName"]}{" "}
              </CategoryChip>
            );
          })}
        </CategoryContainer>
        <ProductListContainer>
          {/* {JSON.stringify(itemList)} */}
          {itemList.slice(0, 5).map((item) => {
            console.log("item", item);
            return (
              <ProductItemContainer
                onClick={() =>
                  handleClick(
                    item["ref_code"],
                    item["data"]?.[0]["brand"],
                    item["data"]?.[0]["price_normal_eng"]
                  )
                }
              >
                <ProductItemImageContainer>
                  <img src={item.thumbnail} />
                </ProductItemImageContainer>
                <ProductItemInfoContainer>
                  <ProductItemBrandNameInfo>
                    <ProductBrand> {item["data"]?.[0]["brand"]} </ProductBrand>
                    <ProductName> {item["data"]?.[0]["name"]} </ProductName>
                  </ProductItemBrandNameInfo>
                  <ProductItemPriceInfo>
                    {item["data"]?.map((product, index) => {
                      return (
                        <ProductPriceContainer key={index}>
                          <ProductDutyFreeSite>
                            {(() => {
                              switch (product.duty_free_site) {
                                case "HYUNDAI":
                                  return "현대";
                                case "SHILLA":
                                  return "신라";
                                case "LOTTE":
                                  return "롯데";
                                default:
                                  return "신세계";
                              }
                            })()}
                          </ProductDutyFreeSite>
                          <ProductPriceEng>
                            {product.price_member_eng}
                          </ProductPriceEng>
                          <LowestPrice>
                            {index == 0 ? "최저가" : null}
                          </LowestPrice>
                        </ProductPriceContainer>
                      );
                    })}
                  </ProductItemPriceInfo>
                </ProductItemInfoContainer>
              </ProductItemContainer>
            );
          })}
        </ProductListContainer>
      </Container>
    </Layout>
  );
};
