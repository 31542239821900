import styled from "styled-components";

export const Layout = styled.div`
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
`

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 360px;
  padding-bottom: 30px;
`;


export const TitleContainer = styled.div`

    font-family: Pretendard;
    font-size: 22px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #000;

    margin-top: 60px;

`

export const CuoponListContainer = styled.div`
display: grid;
grid-template-columns: repeat(2, 1fr); /* 3개의 열을 가진 그리드 */
gap: 20px; /* 아이템 사이의 간격 */
margin-top: 25px;
`

export const CouponItem = styled.div`
  display: flex;
  flex-direction: row;
  height: 110px;
  width: 156px;

  img {
    max-width: 100%; /* 이미지의 최대 너비를 컨테이너 너비에 맞게 조절 */
    height: auto; /* 이미지 높이 자동 조절하여 비율 유지 */
    display: block; /* 인라인 요소 사이의 공백 제거 */
  }
`;
