import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import {
  Layout,
  Container,
  LogoContainer,
  DrawerMenuButton,
} from "../../style/Home/TopMenu.module.js";
import LogoImg from "../../resources/img_logo@3x.png";
import MenuImg from "../../resources/btn_menu.png";
import { DrawerMenu } from "../_molecule/DrawerMenu.js";
import styled from "styled-components";

const BannerContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 6rem;
  background-color: #dcdcdc;

  font-family: Pretendard;
  font-size: 16px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.38;
  letter-spacing: normal;
  color: black;
`;

const ModalOverlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(
    0,
    0,
    0,
    ${({ showModal }) => (showModal ? "0.5" : "0")}
  );
  display: flex;
  justify-content: center;
  align-items: center;
  transition: background-color 0.3s ease-in-out;
  z-index: 120;
`;

const ModalContent = styled.div`
  background-color: #fff;
  padding: 20px;
`;

const ModalImage = styled.img`
  width: 280px;
  height: auto;
  z-index: 99;
`;

const Image = styled.img`
  width: 100px; /* 이미지의 크기를 조정해주세요 */
  height: auto;
  cursor: pointer;
`;

export const TopBanner = () => {
  const [showModal, setShowModal] = useState(false);
  const [modalImage, setModalImage] = useState("");

  const handleImageClick = (imageSrc) => {
    setModalImage(imageSrc);
    setShowModal(true);
  };

  const closeModal = () => {
    setShowModal(false);
    setModalImage("");
  };

  return (
    <Layout>
      <BannerContainer>
        <div>어플에서 보다 편하고 많은 혜택을 받으세요</div>

        <img
          src={"https://dfx-front.s3.ap-northeast-2.amazonaws.com/as.webp"}
          alt="로고 이미지"
          style={{
            width: "40px",
            height: "40px",
            height: "auto",
            marginLeft: "8px",
          }}
          onClick={() =>
            handleImageClick(
              "https://dfx-img.s3.ap-northeast-2.amazonaws.com/pc_down_app.png"
            )
          }
        />
        <img
          src={"https://dfx-img.s3.ap-northeast-2.amazonaws.com/gp.png"}
          alt="로고 이미지"
          style={{
            width: "37px",
            height: "30px",
            height: "auto",
            marginTop: "4px",
            marginLeft: "4px",
          }}
          onClick={() =>
            handleImageClick(
              "https://dfx-img.s3.ap-northeast-2.amazonaws.com/pc_down_google.png"
            )
          }
        />
      </BannerContainer>

      {showModal && (
        <ModalOverlay showModal={showModal} onClick={closeModal}>
          {/* <ModalContent> */}
          <ModalImage src={modalImage} alt="클릭된 이미지" />
          {/* </ModalContent> */}
        </ModalOverlay>
      )}
    </Layout>
  );
};
