import React, { useState, useEffect } from "react";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormControl from "@mui/material/FormControl";
import FormLabel from "@mui/material/FormLabel";
import {
  SearchContainer,
  ProductItemContainer,
  ProductItemInfo,
} from "../style/Home/Home.module";
import Autocomplete from "@mui/material/Autocomplete";
import { gql } from "@apollo/client";
import { client } from "../client";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import {
  SignUpInputItemText,
  SignUpContainer,
  SignUpInputListContainer,
  SignUpInputItem,
} from "../style/Login.module";

import { useNavigate } from "react-router-dom";

const SIGNUP = gql`
  mutation signUp($create_user_input: create_user_input) {
    signUp(create_user_input: $create_user_input)
  }
`;

export const SignUp = () => {
  const navigate = useNavigate();

  const [name, setName] = useState("");
  const [birthDate, setBirthDate] = useState("");
  const [email, setEmail] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [isMale, setIsMale] = useState(false); // New state for

  const handleSignUp = () => {
    // Redirect to the signup page
    if (!name || !birthDate || !email || !password) {
      toast.error("모든 값을 입력해주세요.");
      return;
    }

    if (birthDate.length !== 6) {
      toast.error("생년월일을 6자리로 입력해주세요.");
      return;
    }
    if (phoneNumber.length !== 11) {
      toast.error("핸드폰번호 11자리로 입력해주세요.");
      return;
    }

    if (password !== confirmPassword) {
      toast.error("비밀번호가 일치하지 않습니다.");
      return;
    }
    console.log(name, birthDate, email, password, phoneNumber, isMale);

    client
      .mutate({
        mutation: SIGNUP,
        variables: {
          create_user_input: {
            name: name,
            birth_date: birthDate,
            email: email,
            password: password,
            phone_number: phoneNumber,
            is_male: isMale,
          },
        },
      })
      .then((result) => {
        alert("가입 성공");
        navigate("/login");
      })
      .catch((error) => {
        console.log(error);
        alert(error);
      });
  };

  return (
    <SignUpContainer>
      <h1> 회원가입 </h1>
      <SignUpInputListContainer>
        <SignUpInputItem>
          <SignUpInputItemText> 이름 </SignUpInputItemText>
          <TextField
            sx={{ width: "100%", flexGrow: 1 }}
            id="standard-basic"
            variant="standard"
            value={name}
            onChange={(e) => setName(e.target.value)}
          />
        </SignUpInputItem>
        <SignUpInputItem>
          <SignUpInputItemText> 생년월일 </SignUpInputItemText>
          <TextField
            sx={{ width: "100%", flexGrow: 1 }}
            id="standard-basic"
            placeholder="예: 900101"
            variant="standard"
            value={birthDate}
            onChange={(e) => setBirthDate(e.target.value)}
          />
        </SignUpInputItem>
        <SignUpInputItem>
          <SignUpInputItemText> 성별 </SignUpInputItemText>
          <FormControl component="fieldset">
            <RadioGroup
              row
              value={isMale}
              onChange={(e) => setIsMale(e.target.value)}
            >
              <FormControlLabel value={true} control={<Radio />} label="남성" />
              <FormControlLabel
                value={false}
                control={<Radio />}
                label="여성"
              />
            </RadioGroup>
          </FormControl>
        </SignUpInputItem>
        <SignUpInputItem>
          <SignUpInputItemText> 이메일 </SignUpInputItemText>
          <TextField
            sx={{ width: "100%", flexGrow: 1 }}
            id="standard-basic"
            placeholder="dutyfree@naver.com"
            variant="standard"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
          />
        </SignUpInputItem>
        <SignUpInputItem>
          <SignUpInputItemText> 핸드폰번호 </SignUpInputItemText>
          <TextField
            sx={{ width: "100%", flexGrow: 1 }}
            id="standard-basic"
            placeholder="01077778888 (11자리)"
            variant="standard"
            value={phoneNumber}
            onChange={(e) => setPhoneNumber(e.target.value)}
          />
        </SignUpInputItem>
        <SignUpInputItem>
          <SignUpInputItemText> 비밀번호 </SignUpInputItemText>
          <TextField
            sx={{ width: "100%", flexGrow: 1 }}
            id="standard-basic"
            variant="standard"
            type="password"
            placeholder="영문, 숫자 조합 7자리"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
          />
        </SignUpInputItem>
        <SignUpInputItem>
          <SignUpInputItemText>비밀번호 확인</SignUpInputItemText>
          <TextField
            sx={{ width: "100%", flexGrow: 1 }}
            variant="standard"
            type="password"
            placeholder="영문, 숫자 조합 7자리"
            value={confirmPassword}
            onChange={(e) => setConfirmPassword(e.target.value)}
          />
        </SignUpInputItem>
      </SignUpInputListContainer>

      <Button
        sx={{
          width: "10rem",
          marginTop: "5rem",
          backgroundColor: "#000000",
          "&:hover": {
            backgroundColor: "#00000080", // Replace with your desired hover color
          },
        }}
        variant="contained"
        onClick={handleSignUp}
      >
        가입하기
      </Button>
      <ToastContainer />
    </SignUpContainer>
  );
};
