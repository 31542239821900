import {
  Title,
  ContentItem,
  CopyrightItem,
} from "../../style/Footer.module.js";
import React, { useState, useEffect } from "react";
import { useLocation } from "react-router-dom"; // Import useLocation hook from React Router
import styled from "styled-components";

export const AdminFooter = () => {
  const location = useLocation(); // Get the current location
  const isProductPage = location.pathname.startsWith("/product");
  if (isProductPage) {
    return null; // Return null if on the product page
  }

  return (
    !location.pathname.includes("event") && (
      <Layout>
        <Title>(주)디에프피알</Title>
        <ContentItem>대표이사 : 이창원</ContentItem>
        <ContentItem>사업자등록번호 : 408-86-17825</ContentItem>
        <ContentItem>연락처 : betheone@dfpr.kr</ContentItem>
        <ContentItem>
          주소 : 서울특별시 마포구 독막로 9길 18, 3층 B2호 (서교동)
        </ContentItem>
        <CopyrightItem>
          © Copyright 2023 Duty Free Price Research. All Rights Reserved.
        </CopyrightItem>
      </Layout>
    )
  );
};

const Layout = styled.div`
  width: 100%;
  height: 280px;
  // margin: 54px 0 0;
  background-color: #f2f2f2;
  padding-left: 30px;
  padding-bottom: 100px;
  box-sizing: border-box; /* padding을 width에 포함시키기 위해 box-sizing을 설정 */
`;
