
import { AppBarMenu } from '../../style/BottonAppBar.module.js'
import React, { useState, useEffect } from 'react';
import { useNavigate } from "react-router-dom";
import { Layout, Container, Title, DrawerMenuButton, MenuItem, 
  BorderShortenerL, BorderShortenerR} from './TabMenu.module.js'



export const TabMenu = ( {menuList, selectedMenu, setSelectedMenu}) => {

  return (
    <Layout>
      <Container >
        {menuList.map((item) => {
          if (item['id'] == selectedMenu) {
            return <MenuItem active = {true} >
             {item['name']}
             <BorderShortenerL>_</BorderShortenerL>
             <BorderShortenerR>_</BorderShortenerR>
          </MenuItem>
          } else {
            return <MenuItem active = {false} onClick={() => setSelectedMenu(item['id'])}>
              {item['name']}
            </MenuItem>

          }
        })}
      </Container>
    </Layout>
  );
}

