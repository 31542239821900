import { client } from '../../../client';
import { CREATE_LOG_MUTATION } from '../gql/queries';

const LogType = {
  AD: 'AD',
  GENERAL_LOG: 'GENERAL_LOG',
  TEST: 'TEST',
};

const EventType = {
  CLICK: 'CLICK',
  VIEW: 'VIEW',
};

const getSessionId = async () => {
  return new Promise((resolve) => {
    const checkSessionId = () => {
      const sessionId = sessionStorage.getItem('sessionId');
      if (sessionId) {
        resolve(sessionId);
      } else {
        setTimeout(checkSessionId, 50);
      }
    };
    checkSessionId();
  });
};

// click logging

export const LogoClicked = () => {
  window.location.href = 'https://www.koreanairdfs.com/';
};

export const BannerClicked = (item) => async (e) => {
  const url = item.url_link;
  const log = item.log_type;
  const accountId = item.account_id;
  const adId = item.ad_id;

  const sessionId = await getSessionId();

  handleBannerClick(log, accountId, adId, url, sessionId);

  e.stopPropagation();
};

const handleBannerClick = (log, accountId, adId, url, sessionId) => {
  client
    .mutate({
      mutation: CREATE_LOG_MUTATION,
      variables: {
        create_log_input: {
          log_type: log,
          event_type: EventType.CLICK,
          account_id: accountId,
          ad_id: adId,
          user_session_id: parseInt(sessionId),
        },
      },
    })
    .then((result) => {
      window.location.href = url;
    })
    .catch((error) => {
      console.log(error);
    });
};

// view logging

export const createObserver = async (index, items, boxRef) => {
  let options = {
    root: null,
    rootMargin: '0px',
    thresholds: 1.0,
  };

  const sessionId = await getSessionId();

  const currentItems = items[index];

  const observer = new IntersectionObserver(
    (entries) => handleIntersect(entries, observer, currentItems, sessionId),
    options
  );

  observer.observe(boxRef.current[index]);
};

const handleIntersect = (entries, observer, currentItems, sessionId) => {
  entries.forEach((entry) => {
    if (entry.isIntersecting) {
      client
        .mutate({
          mutation: CREATE_LOG_MUTATION,
          variables: {
            create_log_input: {
              event_type: EventType.VIEW,
              user_session_id: parseInt(sessionId),
              account_id: currentItems.account_id,
              log_type: currentItems.log_type,
              ad_id: currentItems.ad_id,
            },
          },
        })
        .then((result) => {})
        .catch((error) => {
          console.log(error);
        });
    } else {
      observer.disconnect();
    }
  });
};

export const SingleComponentCreateObserver = async (
  bannerIndex,
  items,
  boxRef
) => {
  let options = {
    root: null,
    rootMargin: '0px',
    thresholds: 1.0,
  };

  const currentItems = items[bannerIndex];

  const sessionId = await getSessionId();

  const observer = new IntersectionObserver(
    (entries) =>
      SingleComponenthandleIntersect(
        entries,
        observer,
        currentItems,
        sessionId
      ),
    options
  );

  observer.observe(boxRef.current[bannerIndex]);
};

const SingleComponenthandleIntersect = (
  entries,
  observer,
  currentItems,
  sessionId
) => {
  entries.forEach((entry) => {
    if (entry.isIntersecting) {
      client
        .mutate({
          mutation: CREATE_LOG_MUTATION,
          variables: {
            create_log_input: {
              event_type: EventType.VIEW,
              user_session_id: parseInt(sessionId),
              account_id: currentItems.account_id,
              log_type: currentItems.log_type,
              ad_id: currentItems.ad_id,
            },
          },
        })
        .then((result) => {})
        .catch((error) => {
          console.log(error);
        });
    }
  });
};
