// CenteredImage.js
import React from "react";
import styled from "styled-components";
import { useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";

const Container = styled.div`
  display: flex;
  justify-content: center;
  width: 100%;
`;

const ImageContainer = styled.div`
  position: relative;
  display: flex;
  justify-content: center;
  height: 950px;
  width: 100%;
  min-width: 400px;
  max-width: 420px;
  background-image: url("https://dfx-img.s3.ap-northeast-2.amazonaws.com/dfp_back2.png);
  background-size: 100% auto; /* Adjust width while maintaining aspect ratio */
  background-repeat: no-repeat; /* No repetition */
`;

// 버튼 리스트
// const transparent = "#77777750";
const transparent = "#007bff00";

// 버튼1
const ButtonContainer = styled.div`
  position: absolute;
  top: 2%;
  right: 1%;
`;
const Button = styled.button`
  background-color: ${transparent};
  border: none;
  padding: 8px 16px;
  border-radius: 4px;
  cursor: pointer;
  width: 50px;
  height: 50px;
`;

// 버튼2
const Button2Container = styled.div`
  position: absolute;
  top: 10%;
  right: 3%;
`;
const Button2 = styled.button`
  background-color: ${transparent};
  border: none;
  padding: 8px 16px;
  border-radius: 4px;
  cursor: pointer;
  width: 50px;
  height: 50px;
`;

// 버튼3
const Button3Container = styled.div`
  position: absolute;
  top: 70%;
  right: 30%;
`;
const Button3 = styled.button`
  background-color: ${transparent};
  border: none;
  padding: 8px 16px;
  border-radius: 4px;
  cursor: pointer;
  width: 160px;
  height: 120px;
`;

// 버튼4
const Button4Container = styled.div`
  position: absolute;
  top: 88%;
  right: 10%;
`;
const Button4 = styled.button`
  background-color: ${transparent};
  border: none;
  padding: 8px 16px;
  border-radius: 4px;
  cursor: pointer;
  width: 30px;
  height: 70px;
`;

// 버튼3
const Button5Container = styled.div`
  position: absolute;
  top: 88%;
  right: 2%;
`;
const Button5 = styled.button`
  background-color: ${transparent};
  border: none;
  padding: 8px 16px;
  border-radius: 4px;
  cursor: pointer;
  width: 30px;
  height: 70px;
`;

const DfpEvent1 = ({ src, alt }) => {
  const navigate = useNavigate();

  const handleButtonClick = () => {
    navigate("/aiappevent2");
  };

  useEffect(() => {
    window.scrollTo(0, 0);

    const script = document.createElement("script");
    script.src = "https://developers.kakao.com/sdk/js/kakao.js";
    script.async = true;
    document.body.appendChild(script);
    return () => document.body.removeChild(script);
  }, []);

  //handleButton2Click
  const handleButton2Click = () => {
    // alert('카카오 공유')
    if (window.Kakao) {
      if (!window.Kakao.isInitialized()) {
        const kakao = window.Kakao;
        kakao.init("bd6295d7c2426350afac958e9355f2c6");
      }
      const kakao = window.Kakao;
      kakao.Link.sendDefault({
        objectType: "feed", // 카카오 링크 공유 여러 type들 중 feed라는 타입 -> 자세한 건 카카오에서 확인
        content: {
          title: "DFP", // 인자값으로 받은 title
          description: "할인 쿠폰 이벤트", // 인자값으로 받은 title
          imageUrl:
            "https://dfx-img.s3.ap-northeast-2.amazonaws.com/event_back.gif",
          link: {
            mobileWebUrl: window.location.href, // 인자값으로 받은 route(uri 형태)
            webUrl: window.location.href,
          },
        },
        buttons: [
          {
            title: "쿠폰받기",
            link: {
              mobileWebUrl: window.location.href,
              webUrl: window.location.href,
            },
          },
        ],
      });
    } else {
      console.log("kakao 없음");
    }
  };

  return (
    <Container>
      <ImageContainer>
        <ButtonContainer>
          <Button onClick={handleButtonClick}></Button>
        </ButtonContainer>
        <Button2Container>
          <Button2 onClick={handleButton2Click}></Button2>
        </Button2Container>
        <Button3Container>
          <Button3 onClick={handleButtonClick}></Button3>
        </Button3Container>
        <Button4Container>
          {" "}
          <a
            target="_blank"
            href="https://play.google.com/store/apps/details?id=com.dfpr.apps"
          >
            <Button4></Button4>
          </a>
        </Button4Container>
        <Button5Container>
          <a
            target="_blank"
            href="https://apps.apple.com/kr/app/%EB%A9%B4%EC%84%B8%EA%B0%80%EA%B2%A9%EB%B9%84%EA%B5%90/id6472394872"
          >
            <Button5></Button5>
          </a>
        </Button5Container>
        {/* <Image
          src={"https://dfx-img.s3.ap-northeast-2.amazonaws.com/event1.png"}
          alt={alt}
        /> */}
      </ImageContainer>
    </Container>
  );
};

export default DfpEvent1;
