import { AppBarMenu } from "../../style/BottonAppBar.module.js";
import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import {
  Layout,
  Container,
  Title,
  DrawerMenuButton,
} from "../../component/_molecule/TopMenu.module.js";
import { DrawerMenu } from "./DrawerMenu.js";

import { Underline } from "../../style/Home/RecommendSection.module.js";

import BackImg from "../../resources/btn_back.png";
import MenuImg from "../../resources/btn_menu.png";

import { CategoryMenu, categoryList } from "../category-filter/CategoryMenu.js";

export const TopMenu = ({
  title,
  showDefaultPage,
  itemName,
  setShowDefaultPage,
}) => {
  const navigate = useNavigate();

  const handleGoBack = () => {
    // Navigate back to the previous page
    console.log(window.location);
    // console.log(window.location);
    if (
      title === "상품 검색하기" &&
      window.location.pathname === "/search" &&
      window.location.search != ""
    ) {
      console.log("case1");
      // 수정 시작
      navigate(-1); // Go back one step
      // const lastVisitedURL = sessionStorage.getItem("lastVisitedURL");
      // const categoryName = sessionStorage.getItem("categoryName");
      // if (lastVisitedURL === "/mainCategoryFilter") {
      //   navigate(`/mainCategoryFilter?categoryName=${categoryName}&id=${1}`);
      // } else {
      //   navigate(`/mainCategoryFilter?categoryName=${categoryName}&id=${1}`);
      //   // navigate(-1); // Go back one step
      // }
      return;
    } else if (title === "상품 상세") {
      console.log("case2");
      if (itemName === null) {
        navigate(`/`); // Navigate to the home page if itemName is null
      } else {
        // Navigate back only if the current page is not the home page
        if (window.location.pathname !== "/") {
          navigate(-1); // Go back one step
        }
      }
      return;
    } else {
      console.log("case3");
      navigate(-1); // Navigate to the home page if itemName is null
    }
  };

  const [drawerOpen, setDrawerOpen] = useState(0);

  return (
    <div>
      <Layout>
        <Container>
          <img
            onClick={handleGoBack}
            src={BackImg}
            alt="뒤로가기"
            style={{ width: "32px" }}
          />
          {categoryList.find((category) => category.categoryName === title) ? (
            <CategoryMenu categoryName={title} />
          ) : (
            <Title>{title}</Title>
          )}

          <DrawerMenuButton
            onClick={() => {
              setDrawerOpen(!drawerOpen);
            }}
          >
            <img src={MenuImg} alt="메뉴" style={{ height: "32px" }} />
          </DrawerMenuButton>
        </Container>
        <DrawerMenu
          open={drawerOpen}
          setDrawerOpen={setDrawerOpen}
        ></DrawerMenu>
        <Underline />
      </Layout>
    </div>
  );
};
