
import React, { useState, useEffect } from 'react';
import { client } from "../../client"
import { useNavigate } from "react-router-dom";
import { gql } from "@apollo/client";
import { Container } from './SearchRank.module';
import { TopMenu } from '../_molecule/TopMenu';
import { RealTimeRank } from './RealTimeRank';
import { MostViewd } from './MostViewd';

export const SearchRank = ({}) => {
  

  return (
    <Container> 
      <div>
        <h3> 실시간 키워드 순위</h3>
        <RealTimeRank></RealTimeRank>
      </div>
      <div>
        <h3> 많이 본 상품</h3>
        <MostViewd></MostViewd>
      </div>
      <div>
        <h3> 인기 상품</h3>
      </div>
        
    </Container>)
};

