export const brandListData = [
  {
    brand_kor: "입생로랑",
    brand_eng: "Yves Saint Laurent",
    main_category: 1,
  },
  {
    brand_kor: "입생로랑",
    brand_eng: "Yves Saint Laurent",
    main_category: 2,
  },
  // {
  //   brand_kor: "입생로랑",
  //   brand_eng: "Yves Saint Laurent",
  //   main_category: 7,
  // },
  {
    brand_kor: "후",
    brand_eng: "WHOO",
    main_category: 1,
  },
  {
    brand_kor: "바닐라코",
    brand_eng: "Vanillaco",
    main_category: 1,
  },
  {
    brand_kor: "투쿨포스쿨",
    brand_eng: "Too Cool for School",
    main_category: 1,
  },
  // {
  //   brand_kor: "띠아느",
  //   brand_eng: "THYANE",
  //   main_category: 1,
  // },
  {
    brand_kor: "띰뷰티",
    brand_eng: "thim beauty",
    main_category: 1,
  },
  // {
  //   brand_kor: "더레드립",
  //   brand_eng: "THEREDLIP",
  //   main_category: 1,
  // },
  {
    brand_kor: "더라퓨즈",
    brand_eng: "THERAPUEZ",
    main_category: 1,
  },
  // {
  //   brand_kor: "더바르다",
  //   brand_eng: "the_barda",
  //   main_category: 1,
  // },
  {
    brand_kor: "더샘",
    brand_eng: "THE SAEM",
    main_category: 1,
  },
  {
    brand_kor: "디오키드스킨",
    brand_eng: "The Orchid Skin",
    main_category: 1,
  },
  {
    brand_kor: "더페이스샵",
    brand_eng: "THE FACE SHOP",
    main_category: 1,
  },
  {
    brand_kor: "더바디샵",
    brand_eng: "The Body Shop",
    main_category: 1,
  },
  {
    brand_kor: "딸로",
    brand_eng: "TALLO",
    main_category: 1,
  },
  {
    brand_kor: "설화수",
    brand_eng: "SULWHASOO",
    main_category: 1,
  },
  {
    brand_kor: "시슬리",
    brand_eng: "Sisley",
    main_category: 1,
  },
  {
    brand_kor: "실크테라피",
    brand_eng: "SILK THERAPY",
    main_category: 1,
  },
  {
    brand_kor: "슈에무라",
    brand_eng: "Shu Uemura",
    main_category: 1,
  },
  {
    brand_kor: "시세이도",
    brand_eng: "SHISEIDO",
    main_category: 1,
  },
  // {
  //   brand_kor: "려",
  //   brand_eng: "Ryo",
  //   main_category: 1,
  // },
  // {
  //   brand_kor: "루벨리",
  //   brand_eng: "RUBELLI",
  //   main_category: 1,
  // },
  {
    brand_kor: "라운드어라운드",
    brand_eng: "roundaround",
    main_category: 1,
  },
  {
    brand_kor: "로제드자르딘",
    brand_eng: "ROSE DE JARDIN",
    main_category: 1,
  },
  {
    brand_kor: "루트리",
    brand_eng: "ROOTREE",
    main_category: 1,
  },
  // {
  //   brand_kor: "롬앤",
  //   brand_eng: "Rom&nd",
  //   main_category: 1,
  // },
  // {
  //   brand_kor: "리에티",
  //   brand_eng: "RIETI",
  //   main_category: 1,
  // },
  {
    brand_kor: "레노덤",
    brand_eng: "RENODERM",
    main_category: 1,
  },
  {
    brand_kor: "리쥬란",
    brand_eng: "REJURAN",
    main_category: 1,
  },
  {
    brand_kor: "레쥬큐어",
    brand_eng: "REJUCURE",
    main_category: 1,
  },
  {
    brand_kor: "리파",
    brand_eng: "ReFa",
    main_category: 1,
  },
  // {
  //   brand_kor: "레시피",
  //   brand_eng: "RECIPE",
  //   main_category: 1,
  // },
  // {
  //   brand_kor: "로우퀘스트",
  //   brand_eng: "RAWQUEST",
  //   main_category: 1,
  // },
  // {
  //   brand_kor: "라엘",
  //   brand_eng: "RAEL",
  //   main_category: 1,
  // },
  {
    brand_kor: "나의온도",
    brand_eng: "owndo",
    main_category: 1,
  },
  {
    brand_kor: "눅스",
    brand_eng: "NUXE",
    main_category: 1,
  },
  {
    brand_kor: "뉴즈",
    brand_eng: "Nuuz",
    main_category: 1,
  },
  // {
  //   brand_kor: "누리앙",
  //   brand_eng: "NURIANT",
  //   main_category: 1,
  // },
  {
    brand_kor: "넘버즈인",
    brand_eng: "numbuzin",
    main_category: 1,
  },
  {
    brand_kor: "누페이스",
    brand_eng: "NUFACE",
    main_category: 1,
  },
  {
    brand_kor: "낫츠",
    brand_eng: "NOT's",
    main_category: 1,
  },
  {
    brand_kor: "노엘로힐스",
    brand_eng: "Noelo Hills",
    main_category: 1,
  },
  {
    brand_kor: "너리쉬",
    brand_eng: "NERISH",
    main_category: 1,
  },
  {
    brand_kor: "네오스트라타",
    brand_eng: "Neostrata",
    main_category: 1,
  },
  {
    brand_kor: "네오젠",
    brand_eng: "NEOGEN",
    main_category: 1,
  },
  {
    brand_kor: "니들리",
    brand_eng: "NEEDLY",
    main_category: 1,
  },
  {
    brand_kor: "닐스야드 레머디스",
    brand_eng: "NEAL'S YARD REMEDIES",
    main_category: 1,
  },
  {
    brand_kor: "네이처리퍼블릭",
    brand_eng: "naturerepublic",
    main_category: 1,
  },
  {
    brand_kor: "나스",
    brand_eng: "NARS",
    main_category: 1,
  },
  {
    brand_kor: "난리",
    brand_eng: "nanly",
    main_category: 1,
  },
  // {
  //   brand_kor: "마이넥",
  //   brand_eng: "MY NECK",
  //   main_category: 1,
  // },
  {
    brand_kor: "미샤",
    brand_eng: "MISSHA",
    main_category: 1,
  },
  {
    brand_kor: "메디힐",
    brand_eng: "MEDIHEAL",
    main_category: 1,
  },
  {
    brand_kor: "마리엔메이",
    brand_eng: "MARY&MAY",
    main_category: 1,
  },
  // {
  //   brand_kor: "마녀공장",
  //   brand_eng: "manyo",
  //   main_category: 1,
  // },
  {
    brand_kor: "마몽드",
    brand_eng: "MAMONDE",
    main_category: 1,
  },
  {
    brand_kor: "메이크업 포에버",
    brand_eng: "MAKE UP FOR EVER",
    main_category: 1,
  },
  // {
  //   brand_kor: "마지두마뗑",
  //   brand_eng: "Magie du Matin",
  //   main_category: 1,
  // },
  {
    brand_kor: "맥",
    brand_eng: "Mac",
    main_category: 1,
  },
  // {
  //   brand_kor: "리아진",
  //   brand_eng: "LYAJIN",
  //   main_category: 1,
  // },
  {
    brand_kor: "러븀",
    brand_eng: "LUVUM",
    main_category: 1,
  },
  {
    brand_kor: "루나솔",
    brand_eng: "LUNASOL",
    main_category: 1,
  },
  {
    brand_kor: "룰라러브",
    brand_eng: "LULALOVE",
    main_category: 1,
  },
  {
    brand_kor: "루피움",
    brand_eng: "LU'PIUM",
    main_category: 1,
  },
  // {
  //   brand_kor: "럽럽",
  //   brand_eng: "LOVB LOVB",
  //   main_category: 1,
  // },
  // {
  //   brand_kor: "린제이",
  //   brand_eng: "LINDSAY",
  //   main_category: 1,
  // },
  // {
  //   brand_kor: "렛츠슬림",
  //   brand_eng: "LET'S SLIM",
  //   main_category: 1,
  // },
  {
    brand_kor: "리더스코스메틱",
    brand_eng: "LEADERS COSMETICS",
    main_category: 1,
  },
  {
    brand_kor: "르 라보",
    brand_eng: "LE LABO",
    main_category: 1,
  },
  // {
  //   brand_kor: "레이어프",
  //   brand_eng: "LAYER.P",
  //   main_category: 1,
  // },
  {
    brand_kor: "런드리유",
    brand_eng: "LAUNDRYOU",
    main_category: 1,
  },
  // {
  //   brand_kor: "랩코스",
  //   brand_eng: "LAPCOS",
  //   main_category: 1,
  // },
  {
    brand_kor: "라네즈",
    brand_eng: "Laneige",
    main_category: 1,
  },
  {
    brand_kor: "랑콤",
    brand_eng: "Lancome",
    main_category: 1,
  },
  {
    brand_kor: "랑콤",
    brand_eng: "Lancome",
    main_category: 2,
  },
  {
    brand_kor: "랑케스터",
    brand_eng: "LANCASTER",
    main_category: 1,
  },
  {
    brand_kor: "라뮤즈",
    brand_eng: "LAMUSE",
    main_category: 1,
  },
  {
    brand_kor: "라루셀",
    brand_eng: "LALUCELL",
    main_category: 1,
  },
  // {
  //   brand_kor: "라라레서피",
  //   brand_eng: "LALARECIPE",
  //   main_category: 1,
  // },
  {
    brand_kor: "라곰",
    brand_eng: "LAGOM",
    main_category: 1,
  },
  {
    brand_kor: "라보페",
    brand_eng: "LABOPE",
    main_category: 1,
  },
  {
    brand_kor: "랩시리즈",
    brand_eng: "LAB SERIES",
    main_category: 1,
    same_brand: ["랩 시리즈"],
  },
  {
    brand_kor: "라뷔게르",
    brand_eng: "La Vigueur",
    main_category: 1,
  },
  {
    brand_kor: "라로슈포제",
    brand_eng: "La Roche-Posay",
    main_category: 1,
  },
  {
    brand_kor: "라 프레리",
    brand_eng: "LA PRAIRIE",
    main_category: 1,
  },
  {
    brand_kor: "라프레리",
    brand_eng: "LA PRAIRIE",
    main_category: 1,
  },
  {
    brand_kor: "라피타",
    brand_eng: "LA PITTA",
    main_category: 1,
  },
  {
    brand_kor: "라 메르",
    brand_eng: "La Mer",
    main_category: 1,
  },
  // {
  //   brand_kor: "라부르켓",
  //   brand_eng: "LA BRUKET",
  //   main_category: 1,
  // },
  {
    brand_kor: "로레알 파리",
    brand_eng: "L'Oréal Paris",
    main_category: 1,
  },
  {
    brand_kor: "록시땅",
    brand_eng: "L'OCCITANE",
    main_category: 1,
  },
  {
    brand_kor: "록시땅",
    brand_eng: "L'OCCITANE",
    main_category: 2,
  },
  {
    brand_kor: "김정문알로에",
    brand_eng: "Kim Jeong Moon Aloe",
    main_category: 1,
  },
  {
    brand_kor: "키엘",
    brand_eng: "KIEHL'S",
    main_category: 1,
  },
  {
    brand_kor: "키엘",
    brand_eng: "KIEHL'S",
    main_category: 2,
  },
  {
    brand_kor: "가네보",
    brand_eng: "KANEBO",
    main_category: 1,
  },
  {
    brand_kor: "가히",
    brand_eng: "KAHI",
    main_category: 1,
  },
  {
    brand_kor: "조 말론 런던",
    brand_eng: "JO MALONE LONDON",
    main_category: 1,
  },
  {
    brand_kor: "질스튜어트",
    brand_eng: "Jill Stuart",
    main_category: 1,
  },
  {
    brand_kor: "아이오페",
    brand_eng: "IOPE",
    main_category: 1,
  },
  {
    brand_kor: "이니스프리",
    brand_eng: "Innisfree",
    main_category: 1,
  },
  {
    brand_kor: "휘게",
    brand_eng: "HYGGEE",
    main_category: 1,
  },
  {
    brand_kor: "후다뷰티",
    brand_eng: "HUDA BEAUTY",
    main_category: 1,
  },
  {
    brand_kor: "아워글래스",
    brand_eng: "HOURGLASS",
    main_category: 1,
  },
  {
    brand_kor: "히말라야 허벌",
    brand_eng: "HIMALAYA HERBAL",
    main_category: 1,
  },
  // {
  //   brand_kor: "에르메스",
  //   brand_eng: "HERMES",
  //   main_category: 1,
  // },
  {
    brand_kor: "헤라",
    brand_eng: "HERA",
    main_category: 1,
  },
  {
    brand_kor: "헬레나 루빈스타인",
    brand_eng: "Helena Rubinstein",
    main_category: 1,
    same_brand: ["헬레나루빈스타인"],
  },
  {
    brand_kor: "겔랑",
    brand_eng: "Guerlain",
    main_category: 1,
  },
  // {
  //   brand_kor: "게릴라",
  //   brand_eng: "GUERILLA",
  //   main_category: 1,
  // },
  // {
  //   brand_kor: "구찌 뷰티",
  //   brand_eng: "GUCCI BEAUTY",
  //   main_category: 1,
  // },
  {
    brand_kor: "구찌",
    brand_eng: "GUCCI",
    main_category: 2,
    same_brand: ["구찌 뷰티"],
  },
  {
    brand_kor: "궁중비책",
    brand_eng: "GOONGBE",
    main_category: 1,
  },
  // {
  //   brand_kor: "구달",
  //   brand_eng: "GOODAL",
  //   main_category: 1,
  // },
  {
    brand_kor: "글랜무어",
    brand_eng: "GLAN MOOR",
    main_category: 1,
  },
  {
    brand_kor: "지방시 BEAUTY",
    brand_eng: "GIVENCHY BEAUTY",
    main_category: 1,
  },
  {
    brand_kor: "지방시",
    brand_eng: "GIVENCHY",
    main_category: 2,
    same_brand: ["지방시 BEAUTY"],
  },
  {
    brand_kor: "지방시",
    brand_eng: "GIVENCHY",
    main_category: 3,
    same_brand: ["지방시 BEAUTY"],
  },
  {
    brand_kor: "지방시",
    brand_eng: "GIVENCHY",
    main_category: 5,
  },
  {
    brand_kor: "GIVENCHY BEAUTY",
    brand_eng: "GIVENCHY BEAUTY",
    main_category: 1,
  },
  // {
  //   brand_kor: "갸마르드",
  //   brand_eng: "GAMARDE",
  //   main_category: 1,
  // },
  {
    brand_kor: "에뛰드",
    brand_eng: "Etude",
    main_category: 1,
  },
  {
    brand_kor: "에스티 로더",
    brand_eng: "Estee Lauder",
    main_category: 1,
  },
  {
    brand_kor: "에스티 로더",
    brand_eng: "Estee Lauder",
    main_category: 2,
    same_brand: ["에스티로더"],
  },
  {
    brand_kor: "엘리자베스 아덴",
    brand_eng: "ELIZABETH ARDEN",
    main_category: 1,
  },
  // {
  //   brand_kor: "듀벨",
  //   brand_eng: "duvel",
  //   main_category: 1,
  // },
  {
    brand_kor: "더프트앤도프트",
    brand_eng: "DUFT&DOFT",
    main_category: 1,
  },
  {
    brand_kor: "듀얼소닉",
    brand_eng: "DUALSONIC",
    main_category: 1,
  },
  {
    brand_kor: "드렁크 앨리펀트",
    brand_eng: "DRUNK ELEPHANT",
    main_category: 1,
  },
  // {
  //   brand_kor: "드림웍스",
  //   brand_eng: "DREAMWORKS",
  //   main_category: 1,
  // },
  // {
  //   brand_kor: "닥터탱글",
  //   brand_eng: "Dr.tengle",
  //   main_category: 1,
  // },
  {
    brand_kor: "닥터올가",
    brand_eng: "DR.ORGA",
    main_category: 1,
  },
  // {
  //   brand_kor: "닥터마시멜로",
  //   brand_eng: "Dr.marshmallow",
  //   main_category: 1,
  // },
  {
    brand_kor: "닥터클레오",
    brand_eng: "Dr.CLEO",
    main_category: 1,
  },
  {
    brand_kor: "닥터시라보",
    brand_eng: "DR.CILABO",
    main_category: 1,
  },
  // {
  //   brand_kor: "닥터씨60",
  //   brand_eng: "DR.C60",
  //   main_category: 1,
  // },
  // {
  //   brand_kor: "닥터방기원",
  //   brand_eng: "DR.BANGGIWON",
  //   main_category: 1,
  // },
  {
    brand_kor: "닥터아리보",
    brand_eng: "DR.ARRIVO",
    main_category: 1,
  },
  {
    brand_kor: "닥터엘시아",
    brand_eng: "DR.ALTHEA",
    main_category: 1,
  },
  {
    brand_kor: "닥터자르트",
    brand_eng: "Dr. Jart",
    main_category: 1,
  },
  {
    brand_kor: "닥터하스킨",
    brand_eng: "Dr. HASKIN",
    main_category: 1,
  },
  {
    brand_kor: "닥터지",
    brand_eng: "Dr. G",
    main_category: 1,
  },
  {
    brand_kor: "동인비",
    brand_eng: "DONGINBI",
    main_category: 1,
  },
  {
    brand_kor: "도미나스",
    brand_eng: "DOMINAS",
    main_category: 1,
  },
  {
    brand_kor: "닥토브",
    brand_eng: "DOCTOB",
    main_category: 1,
  },
  // {
  //   brand_kor: "다이브",
  //   brand_eng: "DIVE",
  //   main_category: 1,
  // },
  {
    brand_kor: "디올",
    brand_eng: "Dior",
    main_category: 1,
  },
  {
    brand_kor: "디올",
    brand_eng: "Dior",
    main_category: 2,
  },
  {
    brand_kor: "디뮤",
    brand_eng: "DIIMUU",
    main_category: 1,
  },
  {
    brand_kor: "다이아피아",
    brand_eng: "DIAPIA",
    main_category: 1,
  },
  {
    brand_kor: "듀이트리",
    brand_eng: "DEWYTREE",
    main_category: 1,
  },
  {
    brand_kor: "듀이셀",
    brand_eng: "DEWYCEL",
    main_category: 1,
  },
  // {
  //   brand_kor: "더마토리",
  //   brand_eng: "DERMATORY",
  //   main_category: 1,
  // },
  {
    brand_kor: "더말로지카",
    brand_eng: "Dermalogica",
    main_category: 1,
  },
  // {
  //   brand_kor: "더마라인",
  //   brand_eng: "DERMALINE",
  //   main_category: 1,
  // },
  {
    brand_kor: "더마픽스",
    brand_eng: "dermafix",
    main_category: 1,
  },
  {
    brand_kor: "더마펌",
    brand_eng: "DERMAFIRM",
    main_category: 1,
  },
  // {
  //   brand_kor: "더마벨",
  //   brand_eng: "DERMABELL",
  //   main_category: 1,
  // },
  {
    brand_kor: "더멀매트릭스",
    brand_eng: "Derm·all Matrix",
    main_category: 1,
  },
  {
    brand_kor: "디마르3",
    brand_eng: "DEMAR3",
    main_category: 1,
  },
  {
    brand_kor: "디폰데",
    brand_eng: "DEEPONDE",
    main_category: 1,
  },
  {
    brand_kor: "디페어",
    brand_eng: "Deepair",
    main_category: 1,
  },
  {
    brand_kor: "데코르테",
    brand_eng: "DECORTE",
    main_category: 1,
  },
  {
    brand_kor: "달팡",
    brand_eng: "DARPHIN",
    main_category: 1,
  },
  {
    brand_kor: "댄싱웨일",
    brand_eng: "dancing whale",
    main_category: 1,
  },
  // {
  //   brand_kor: "디플셀",
  //   brand_eng: "D.PL CELL",
  //   main_category: 1,
  // },
  {
    brand_kor: "코스메 데코르테",
    brand_eng: "Cosme Decorte",
    main_category: 1,
  },
  {
    brand_kor: "클리오",
    brand_eng: "CLIO",
    main_category: 1,
  },
  {
    brand_kor: "크리니크",
    brand_eng: "CLINIQUE",
    main_category: 1,
  },
  {
    brand_kor: "크리니크",
    brand_eng: "CLINIQUE",
    main_category: 2,
  },
  {
    brand_kor: "끌레드벨",
    brand_eng: "Cledbel",
    main_category: 1,
  },
  {
    brand_kor: "끌레드뽀보떼",
    brand_eng: "CLE DE PEAU BEAUTE",
    main_category: 1,
  },
  {
    brand_kor: "클라랑스",
    brand_eng: "Clarins",
    main_category: 1,
  },
  {
    brand_kor: "클라랑스",
    brand_eng: "Clarins",
    main_category: 2,
  },
  {
    brand_kor: "샤넬",
    brand_eng: "CHANEL",
    main_category: 1,
  },
  {
    brand_kor: "샤넬",
    brand_eng: "CHANEL",
    main_category: 2,
  },
  {
    brand_kor: "꼬달리",
    brand_eng: "Caudalie",
    main_category: 1,
  },
  {
    brand_kor: "까띠에",
    brand_eng: "CATTIER",
    main_category: 1,
  },
  // {
  //   brand_kor: "보태니 이센셜즈",
  //   brand_eng: "BOTANY ESSENTIALS",
  //   main_category: 1,
  // },
  {
    brand_kor: "바비브라운",
    brand_eng: "Bobbi Brown",
    main_category: 1,
  },
  // {
  //   brand_kor: "비오템 옴므",
  //   brand_eng: "BIOTHERM HOMME",
  //   main_category: 1,
  // },
  // {
  //   brand_kor: "비오템",
  //   brand_eng: "BIOTHERM",
  //   main_category: 1,
  //   same_brand: ["비오템 옴므"],
  // },
  // {
  //   brand_kor: "비오템",
  //   brand_eng: "BIOTHERM",
  //   main_category: 2,
  // },
  {
    brand_kor: "베네피트",
    brand_eng: "Benefit",
    main_category: 1,
  },
  // {
  //   brand_kor: "아베다",
  //   brand_eng: "AVEDA",
  //   main_category: 1,
  // },
  {
    brand_kor: "조르지오 아르마니",
    brand_eng: "ARMANI",
    main_category: 1,
  },
  {
    brand_kor: "조르지오 아르마니",
    brand_eng: "ARMANI",
    main_category: 2,
  },
  // {
  //   brand_kor: "안나수이",
  //   brand_eng: "ANNASUI",
  //   main_category: 1,
  // },
  {
    brand_kor: "어뮤즈",
    brand_eng: "AMUSE",
    main_category: 1,
  },
  {
    brand_kor: "아모레퍼시픽",
    brand_eng: "AMORE PACIFIC",
    main_category: 1,
  },
  {
    brand_kor: "에이에이치씨",
    brand_eng: "AHC",
    main_category: 1,
  },
  {
    brand_kor: "어딕션",
    brand_eng: "ADDICTION",
    main_category: 1,
  },
  {
    brand_kor: "9wishes",
    brand_eng: "9wishes",
    main_category: 1,
  },
  {
    brand_kor: "12그랩스",
    brand_eng: "12GRABS",
    main_category: 1,
  },
  // {
  //   brand_kor: "제냐",
  //   brand_eng: "ZEGNA",
  //   main_category: 2,
  // },
  {
    brand_kor: "후",
    brand_eng: "WHOO",
    main_category: 2,
  },
  {
    brand_kor: "바닐라코",
    brand_eng: "Vanillaco",
    main_category: 2,
  },
  {
    brand_kor: "띰 뷰티",
    brand_eng: "thim beauty",
    main_category: 2,
    same_brand: ["띰뷰티"],
  },
  {
    brand_kor: "더툴랩",
    brand_eng: "The Tool Lab",
    main_category: 2,
  },
  {
    brand_kor: "설화수",
    brand_eng: "SULWHASOO",
    main_category: 2,
  },
  {
    brand_kor: "슈에무라",
    brand_eng: "Shu Uemura",
    main_category: 2,
  },
  {
    brand_kor: "시세이도",
    brand_eng: "SHISEIDO",
    main_category: 2,
  },
  // {
  //   brand_kor: "루치펠로",
  //   brand_eng: "RUCIPELLO",
  //   main_category: 2,
  // },
  {
    brand_kor: "롬앤",
    brand_eng: "Rom&nd",
    main_category: 2,
  },
  {
    brand_kor: "리얼테크닉스",
    brand_eng: "REAL TECHNIQUES",
    main_category: 2,
  },
  {
    brand_kor: "피카소",
    brand_eng: "PICASSO",
    main_category: 2,
  },
  // {
  //   brand_kor: "누즈",
  //   brand_eng: "NUSE",
  //   main_category: 2,
  // },
  // {
  //   brand_kor: "누그레이",
  //   brand_eng: "nuegray",
  //   main_category: 2,
  // },
  {
    brand_kor: "누디크",
    brand_eng: "NUDIQUE",
    main_category: 2,
  },
  {
    brand_kor: "네오젠",
    brand_eng: "NEOGEN",
    main_category: 2,
  },
  {
    brand_kor: "나틴다",
    brand_eng: "NATINDA",
    main_category: 2,
  },
  {
    brand_kor: "나스",
    brand_eng: "NARS",
    main_category: 2,
  },
  // {
  //   brand_kor: "네이밍",
  //   brand_eng: "NAMING",
  //   main_category: 2,
  // },
  {
    brand_kor: "뮤즈마크",
    brand_eng: "MUSEMARK",
    main_category: 2,
  },
  // {
  //   brand_kor: "마우나로아",
  //   brand_eng: "MAUNA LOA",
  //   main_category: 2,
  // },
  {
    brand_kor: "마스카컴퍼니",
    brand_eng: "MASKACOMPANY",
    main_category: 2,
  },
  // {
  //   brand_kor: "마리엔메이",
  //   brand_eng: "MARY&MAY",
  //   main_category: 2,
  // },
  // {
  //   brand_kor: "마니아홀릭",
  //   brand_eng: "mania holic",
  //   main_category: 2,
  // },
  {
    brand_kor: "메이크업 포에버",
    brand_eng: "MAKE UP FOR EVER",
    main_category: 2,
    same_brand: ["메이크업포에버"],
  },
  {
    brand_kor: "맥",
    brand_eng: "Mac",
    main_category: 2,
  },
  {
    brand_kor: "루나솔",
    brand_eng: "LUNASOL",
    main_category: 2,
  },
  {
    brand_kor: "루나",
    brand_eng: "LUNA",
    main_category: 2,
  },
  {
    brand_kor: "릴리바이레드",
    brand_eng: "LILYBYRED",
    main_category: 2,
  },
  {
    brand_kor: "리베미",
    brand_eng: "LIBER",
    main_category: 2,
  },
  {
    brand_kor: "라네즈",
    brand_eng: "Laneige",
    main_category: 2,
  },
  {
    brand_kor: "라숌브",
    brand_eng: "LachomB",
    main_category: 2,
  },
  {
    brand_kor: "라피타",
    brand_eng: "LA PITTA",
    main_category: 2,
  },
  {
    brand_kor: "로레알 파리",
    brand_eng: "L'Oréal Paris",
    main_category: 2,
  },
  {
    brand_kor: "가네보",
    brand_eng: "KANEBO",
    main_category: 2,
  },
  {
    brand_kor: "가히",
    brand_eng: "KAHI",
    main_category: 2,
  },
  {
    brand_kor: "아이오페",
    brand_eng: "IOPE",
    main_category: 2,
  },
  {
    brand_kor: "아워글래스",
    brand_eng: "HOURGLASS",
    main_category: 2,
  },
  {
    brand_kor: "헬레나루빈스타인",
    brand_eng: "Helena Rubinstein",
    main_category: 2,
  },
  {
    brand_kor: "글로오아시스",
    brand_eng: "glowoasis",
    main_category: 2,
  },
  {
    brand_kor: "글린트",
    brand_eng: "Glint",
    main_category: 2,
  },
  {
    brand_kor: "디와이알앤",
    brand_eng: "DYRN",
    main_category: 2,
  },
  {
    brand_kor: "닥터쥬크르",
    brand_eng: "Dr.JUCRE",
    main_category: 2,
  },
  {
    brand_kor: "닥터자르트",
    brand_eng: "Dr. Jart",
    main_category: 2,
  },
  // {
  //   brand_kor: "디피씨",
  //   brand_eng: "DPC",
  //   main_category: 2,
  // },
  {
    brand_kor: "딘토",
    brand_eng: "DINTO",
    main_category: 2,
  },
  {
    brand_kor: "듀이셀",
    brand_eng: "DEWYCEL",
    main_category: 2,
  },
  {
    brand_kor: "더마펌",
    brand_eng: "DERMAFIRM",
    main_category: 2,
  },
  {
    brand_kor: "델리뷰티",
    brand_eng: "delibeauty",
    main_category: 2,
  },
  {
    brand_kor: "데자뷰",
    brand_eng: "dejavu",
    main_category: 2,
  },
  {
    brand_kor: "데싱디바",
    brand_eng: "DASHING DIVA",
    main_category: 2,
  },
  // {
  //   brand_kor: "데이지크",
  //   brand_eng: "Daisyque",
  //   main_category: 2,
  // },
  {
    brand_kor: "대박푸드",
    brand_eng: "daebakfood",
    main_category: 2,
  },
  {
    brand_kor: "꾸셀",
    brand_eng: "Courcelles",
    main_category: 2,
  },
  {
    brand_kor: "끌레드뽀 보떼",
    brand_eng: "CLE DE PEAU BEAUTE",
    main_category: 2,
  },
  // {
  //   brand_kor: "꼬달리",
  //   brand_eng: "Caudalie",
  //   main_category: 2,
  // },
  {
    brand_kor: "까탈릭",
    brand_eng: "CATALIC",
    main_category: 2,
  },
  {
    brand_kor: "버버리 뷰티",
    brand_eng: "Burberry Beauty",
    main_category: 2,
  },
  {
    brand_kor: "바비브라운",
    brand_eng: "Bobbi Brown",
    main_category: 2,
  },
  // {
  //   brand_kor: "비오템 옴므",
  //   brand_eng: "BIOTHERM HOMME",
  //   main_category: 2,
  // },
  {
    brand_kor: "베네피트",
    brand_eng: "Benefit",
    main_category: 2,
  },
  {
    brand_kor: "삐아",
    brand_eng: "BBIA",
    main_category: 2,
  },
  {
    brand_kor: "아모레퍼시픽",
    brand_eng: "AMORE PACIFIC",
    main_category: 2,
  },
  {
    brand_kor: "나인위시스",
    brand_eng: "9wishes",
    main_category: 2,
  },
  {
    brand_kor: "입생로랑",
    brand_eng: "Yves Saint Laurent",
    main_category: 3,
  },
  {
    brand_kor: "더블유드레스룸",
    brand_eng: "W.Dressroom",
    main_category: 3,
  },
  {
    brand_kor: "베르사체 향수",
    brand_eng: "VERSACE PFM",
    main_category: 3,
  },
  {
    brand_kor: "베르사체 C&P",
    brand_eng: "VERSACE C&P",
    main_category: 3,
  },
  {
    brand_kor: "발렌티노 뷰티",
    brand_eng: "VALENTINO Beauty",
    main_category: 3,
  },
  {
    brand_kor: "티에스 샴푸",
    brand_eng: "TS Shampoo",
    main_category: 3,
  },
  {
    brand_kor: "티에스샴푸",
    brand_eng: "TS Shampoo",
    main_category: 3,
  },
  // {
  //   brand_kor: "톰 포드",
  //   brand_eng: "Tom Ford Beauty",
  //   main_category: 1,
  //   same_brand: ["톰 포드 뷰티"],
  // },
  // {
  //   brand_kor: "톰 포드",
  //   brand_eng: "Tom Ford Beauty",
  //   main_category: 2,
  //   same_brand: ["톰 포드 뷰티"],
  // },
  {
    brand_kor: "톰포드 뷰티",
    brand_eng: "Tom Ford Beauty",
    main_category: 3,
  },
  {
    brand_kor: "티파니앤코",
    brand_eng: "TIFFANY & CO.",
    main_category: 3,
  },
  {
    brand_kor: "띰",
    brand_eng: "thim",
    main_category: 3,
  },
  {
    brand_kor: "더라퓨즈",
    brand_eng: "THERAPUEZ",
    main_category: 3,
  },
  {
    brand_kor: "디오키드스킨",
    brand_eng: "The Orchid Skin",
    main_category: 3,
  },
  {
    brand_kor: "더바디샵",
    brand_eng: "The Body Shop",
    main_category: 3,
  },
  {
    brand_kor: "탬버린즈",
    brand_eng: "tamburins",
    main_category: 3,
  },
  {
    brand_kor: "시슬리",
    brand_eng: "Sisley",
    main_category: 3,
  },
  {
    brand_kor: "실크테라피",
    brand_eng: "SILK THERAPY",
    main_category: 3,
  },
  {
    brand_kor: "루트헤어",
    brand_eng: "Ruthair",
    main_category: 3,
  },
  {
    brand_kor: "루치펠로",
    brand_eng: "RUCIPELLO",
    main_category: 3,
  },
  {
    brand_kor: "라운드어라운드",
    brand_eng: "roundaround",
    main_category: 3,
  },
  {
    brand_kor: "로로벨",
    brand_eng: "ROROBELL",
    main_category: 3,
  },
  {
    brand_kor: "루톤",
    brand_eng: "ROOTON",
    main_category: 3,
  },
  {
    brand_kor: "리츄얼스",
    brand_eng: "RITUALS",
    main_category: 3,
  },
  {
    brand_kor: "리민",
    brand_eng: "RHEEMIN",
    main_category: 3,
  },
  {
    brand_kor: "르네휘테르",
    brand_eng: "RENE FURTERER",
    main_category: 3,
  },
  {
    brand_kor: "리파브러쉬",
    brand_eng: "ReFa BRUSH",
    main_category: 3,
  },
  {
    brand_kor: "리엔",
    brand_eng: "reen",
    main_category: 3,
  },
  {
    brand_kor: "레드씰",
    brand_eng: "redseal",
    main_category: 3,
  },
  {
    brand_kor: "리얼리굿스윔",
    brand_eng: "REALLYGOODSWIM",
    main_category: 3,
  },
  {
    brand_kor: "리얼리티뷰티",
    brand_eng: "Reality Beauty",
    main_category: 3,
  },
  {
    brand_kor: "라비엘",
    brand_eng: "RAVIEL",
    main_category: 3,
  },
  {
    brand_kor: "라우쉬",
    brand_eng: "RAUSCH",
    main_category: 3,
  },
  {
    brand_kor: "라타플랑",
    brand_eng: "rataplan",
    main_category: 3,
  },
  {
    brand_kor: "랑세",
    brand_eng: "RANCE",
    main_category: 3,
  },
  {
    brand_kor: "랄프 로렌 향수",
    brand_eng: "RALPH LAUREN(perfume)",
    main_category: 3,
  },
  {
    brand_kor: "랄프로렌 향수",
    brand_eng: "RALPH LAUREN(perfume)",
    main_category: 3,
  },
  {
    brand_kor: "라엘",
    brand_eng: "RAEL",
    main_category: 3,
  },
  {
    brand_kor: "프라다 향수",
    brand_eng: "PRADA PFM",
    main_category: 3,
  },
  {
    brand_kor: "누텍스처",
    brand_eng: "NuTexture",
    main_category: 1,
  },
  {
    brand_kor: "노워터",
    brand_eng: "NOWATER COSMETIC",
    main_category: 1,
  },
  {
    brand_kor: "나우앤댄",
    brand_eng: "NOW&THAN",
    main_category: 3,
  },
  // {
  //   brand_kor: "노우라이",
  //   brand_eng: "NOLIE",
  //   main_category: 1,
  // },
  {
    brand_kor: "니씨",
    brand_eng: "NISSI",
    main_category: 3,
  },
  {
    brand_kor: "니샤네",
    brand_eng: "Nishane",
    main_category: 3,
  },
  {
    brand_kor: "니콜라이",
    brand_eng: "NICOLAI",
    main_category: 3,
  },
  {
    brand_kor: "니치어",
    brand_eng: "niccier",
    main_category: 3,
  },
  {
    brand_kor: "뉴스템",
    brand_eng: "Newstem",
    main_category: 1,
  },
  {
    brand_kor: "네안데르탈",
    brand_eng: "Neandertal",
    main_category: 3,
  },
  {
    brand_kor: "네이처스훼밀리",
    brand_eng: "NATURES FAMILY",
    main_category: 8,
    same_brand: ["네이쳐스 훼밀리"],
  },
  {
    brand_kor: "네이처앤",
    brand_eng: "NATUREN",
    main_category: 3,
  },
  {
    brand_kor: "네이쳐스 탑",
    brand_eng: "Nature's Top",
    main_category: 8,
    same_brand: ["네이쳐스탑"],
  },
  {
    brand_kor: "내추럴더마 프로젝트",
    brand_eng: "naturaldermaproject",
    main_category: 1,
  },
  {
    brand_kor: "나르시소 로드리게즈",
    brand_eng: "NARCISO RODRIGUEZ",
    main_category: 3,
  },
  {
    brand_kor: "모스키노",
    brand_eng: "Moschino",
    main_category: 3,
  },
  {
    brand_kor: "모스키노 향수",
    brand_eng: "Moschino",
    main_category: 3,
  },
  {
    brand_kor: "몽블랑 향수",
    brand_eng: "Montblanc",
    main_category: 3,
  },
  {
    brand_kor: "몽클레르 향수",
    brand_eng: "MONCLER",
    main_category: 3,
  },
  {
    brand_kor: "미샤",
    brand_eng: "MISSHA",
    main_category: 3,
  },
  {
    brand_kor: "마이클 코어스 향수",
    brand_eng: "Michael Kors",
    main_category: 3,
  },
  {
    brand_kor: "마이클코어스",
    brand_eng: "Michael Kors",
    main_category: 3,
  },
  {
    brand_kor: "마이클코어스(COS)",
    brand_eng: "Michael Kors",
    main_category: 3,
  },
  {
    brand_kor: "메디힐",
    brand_eng: "MEDIHEAL",
    main_category: 3,
  },
  {
    brand_kor: "마더네스트",
    brand_eng: "mathernest",
    main_category: 3,
  },
  {
    brand_kor: "마스카컴퍼니",
    brand_eng: "MASKACOMPANY",
    main_category: 3,
  },
  {
    brand_kor: "마비스",
    brand_eng: "MARVIS",
    main_category: 3,
  },
  {
    brand_kor: "마르마르디",
    brand_eng: "MARMAR;D",
    main_category: 3,
  },
  {
    brand_kor: "마크제이콥스 향수",
    brand_eng: "MARC JACOBS",
    main_category: 3,
    same_brand: ["마크제이콥스 향수", "마크제이콥스", "마크 제이콥스"],
  },
  {
    brand_kor: "마몽드",
    brand_eng: "MAMONDE",
    main_category: 3,
  },
  {
    brand_kor: "캘빈클라인",
    brand_eng: "Calvin Klein",
    main_category: 3,
  },
  {
    brand_kor: "메종 마르지엘라",
    brand_eng: "MAISON MARGIELA",
    main_category: 3,
  },
  {
    brand_kor: "메종 마르지엘라 향수",
    brand_eng: "MAISON MARGIELA",
    main_category: 3,
  },
  {
    brand_kor: "루시스",
    brand_eng: "LUSIS",
    main_category: 3,
  },
  {
    brand_kor: "룰루진",
    brand_eng: "lulugine",
    main_category: 3,
  },
  {
    brand_kor: "루피움",
    brand_eng: "LU'PIUM",
    main_category: 3,
  },
  {
    brand_kor: "로키",
    brand_eng: "LOQI",
    main_category: 3,
  },
  {
    brand_kor: "로마",
    brand_eng: "LOMA",
    main_category: 3,
  },
  {
    brand_kor: "롤리타 렘피카",
    brand_eng: "LOLITA LEMPICKA",
    main_category: 3,
  },
  {
    brand_kor: "로이비",
    brand_eng: "Loivie",
    main_category: 3,
  },
  {
    brand_kor: "로에베 향수",
    brand_eng: "LOEWE PERFUME",
    main_category: 3,
  },
  {
    brand_kor: "로에베 C&P",
    brand_eng: "LOEWE C&F",
    main_category: 3,
  },
  {
    brand_kor: "로에베",
    brand_eng: "LOEWE",
    main_category: 3,
  },
  {
    brand_kor: "로에",
    brand_eng: "LOE",
    main_category: 3,
  },
  {
    brand_kor: "리퀴드퍼퓸바",
    brand_eng: "Liquides Perfume Bar",
    main_category: 3,
  },
  {
    brand_kor: "리퀴드 이마지네르",
    brand_eng: "LIQUIDES IMAGINAIRES",
    main_category: 3,
  },
  {
    brand_kor: "리즈",
    brand_eng: "LIIZ",
    main_category: 3,
  },
  {
    brand_kor: "리베르",
    brand_eng: "LIBER",
    main_category: 3,
  },
  {
    brand_kor: "레오놀그렐",
    brand_eng: "LEONOR GREYL",
    main_category: 3,
  },
  {
    brand_kor: "르망고",
    brand_eng: "LEMANGO",
    main_category: 3,
  },
  {
    brand_kor: "료메르",
    brand_eng: "LEMAIRE",
    main_category: 3,
  },
  {
    brand_kor: "리아브",
    brand_eng: "Leav",
    main_category: 3,
  },
  {
    brand_kor: "르플랑",
    brand_eng: "le-plein",
    main_category: 3,
  },
  {
    brand_kor: "르누부",
    brand_eng: "LE NOUVOU",
    main_category: 3,
  },
  // {
  //   brand_kor: "르 라보",
  //   brand_eng: "LE LABO",
  //   main_category: 3,
  // },
  {
    brand_kor: "르라보",
    brand_eng: "LE LABO",
    main_category: 3,
    same_brand: ["르 라보"],
  },
  {
    brand_kor: "르 쿠방",
    brand_eng: "LE COUVENT",
    main_category: 3,
  },
  {
    brand_kor: "르셀르",
    brand_eng: "le celle",
    main_category: 3,
  },
  {
    brand_kor: "르봉",
    brand_eng: "LE BON",
    main_category: 3,
  },
  {
    brand_kor: "라비앙",
    brand_eng: "LAVIEN",
    main_category: 3,
  },
  {
    brand_kor: "로라 메르시에",
    brand_eng: "LAURA MERCIER",
    main_category: 3,
  },
  {
    brand_kor: "로라메르시에",
    brand_eng: "LAURA MERCIER",
    main_category: 3,
  },
  {
    brand_kor: "랩코스",
    brand_eng: "LAPCOS",
    main_category: 3,
  },
  {
    brand_kor: "랑방 향수",
    brand_eng: "LANVIN PERFUME",
    main_category: 3,
  },
  {
    brand_kor: "랑콤",
    brand_eng: "Lancome",
    main_category: 3,
  },
  {
    brand_kor: "라뮤즈",
    brand_eng: "LAMUSE",
    main_category: 3,
  },
  {
    brand_kor: "라라츄",
    brand_eng: "LALACHUU",
    main_category: 3,
  },
  {
    brand_kor: "라라로즈데이",
    brand_eng: "LALA ROSE DAY",
    main_category: 3,
  },
  {
    brand_kor: "라보라토리오 올파티보",
    brand_eng: "LABORATORIO OLFATTIVO",
    main_category: 3,
  },
  {
    brand_kor: "랩클",
    brand_eng: "LABCLE",
    main_category: 3,
  },
  {
    brand_kor: "라뷔게르",
    brand_eng: "La Vigueur",
    main_category: 3,
  },
  {
    brand_kor: "라프레리",
    brand_eng: "LA PRAIRIE",
    main_category: 3,
  },
  {
    brand_kor: "라메르",
    brand_eng: "La Mer",
    main_category: 3,
  },
  {
    brand_kor: "라부르켓",
    brand_eng: "LA BRUKET",
    main_category: 3,
  },
  {
    brand_kor: "로레알파리",
    brand_eng: "L'Oréal Paris",
    main_category: 3,
  },
  {
    brand_kor: "로더렛",
    brand_eng: "L'ODEURLETTE",
    main_category: 3,
  },
  {
    brand_kor: "록시땅",
    brand_eng: "L'OCCITANE",
    main_category: 3,
  },
  {
    brand_kor: "라티잔 퍼퓨머",
    brand_eng: "L'ARTISAN PARFUMEUR",
    main_category: 3,
  },
  {
    brand_kor: "고유지",
    brand_eng: "Koyuji",
    main_category: 3,
  },
  {
    brand_kor: "기억",
    brand_eng: "KIOK",
    main_category: 3,
  },
  {
    brand_kor: "키엘",
    brand_eng: "KIEHL'S",
    main_category: 3,
  },
  // {
  //   brand_kor: "겐조 향수",
  //   brand_eng: "KENZO PERFUME",
  //   main_category: 3,
  // },
  {
    brand_kor: "겐조",
    brand_eng: "KENZO",
    main_category: 3,
    same_brand: ["겐조 향수"],
  },
  {
    brand_kor: "존 바바토스",
    brand_eng: "JOHN VARVATOS",
    main_category: 3,
  },
  {
    brand_kor: "존바바토스",
    brand_eng: "JOHN VARVATOS",
    main_category: 3,
  },
  {
    brand_kor: "조 말론 런던",
    brand_eng: "JO MALONE LONDON",
    main_category: 3,
  },
  {
    brand_kor: "질스튜어트",
    brand_eng: "Jill Stuart",
    main_category: 3,
  },
  {
    brand_kor: "이니스프리",
    brand_eng: "Innisfree",
    main_category: 3,
  },
  {
    brand_kor: "화사",
    brand_eng: "HWASA",
    main_category: 3,
  },
  {
    brand_kor: "휴고 보스 향수",
    brand_eng: "HUGO BOSS PFM",
    main_category: 3,
  },
  {
    brand_kor: "히말라야 허벌",
    brand_eng: "HIMALAYA HERBAL",
    main_category: 3,
  },
  {
    brand_kor: "에르메스 퍼퓸",
    brand_eng: "HERMES PERFUME",
    main_category: 3,
  },
  {
    brand_kor: "에르메스 향수",
    brand_eng: "HERMES PERFUME",
    main_category: 3,
  },
  {
    brand_kor: "에르메스 뷰티",
    brand_eng: "HERMES BEAUTY",
    main_category: 3,
  },
  {
    brand_kor: "헤라",
    brand_eng: "HERA",
    main_category: 3,
  },
  {
    brand_kor: "겔랑",
    brand_eng: "Guerlain",
    main_category: 3,
  },
  {
    brand_kor: "구찌 향수",
    brand_eng: "GUCCI PERFUME",
    main_category: 3,
  },
  {
    brand_kor: "구찌 PERFUME",
    brand_eng: "GUCCI PERFUME",
    main_category: 3,
  },
  {
    brand_kor: "그룬플러스",
    brand_eng: "grunplus",
    main_category: 3,
  },
  {
    brand_kor: "그라운드플랜",
    brand_eng: "GROUNDPLAN",
    main_category: 3,
  },
  // {
  //   brand_kor: "구딸파리",
  //   brand_eng: "Goutal Paris",
  //   main_category: 3,
  // },
  {
    brand_kor: "구딸",
    brand_eng: "Goutal",
    main_category: 3,
    same_brand: ["구딸파리"],
  },
  {
    brand_kor: "궁중비책",
    brand_eng: "GOONGBE",
    main_category: 3,
  },
  {
    brand_kor: "글루어",
    brand_eng: "GLUIRE",
    main_category: 3,
  },
  {
    brand_kor: "글램팜",
    brand_eng: "Glampalm",
    main_category: 3,
  },
  {
    brand_kor: "길라에잇",
    brand_eng: "Gilla8",
    main_category: 1,
  },
  {
    brand_kor: "게이즈샵",
    brand_eng: "GAZE SHOP",
    main_category: 3,
  },
  {
    brand_kor: "게이즈",
    brand_eng: "GAZE",
    main_category: 3,
  },
  {
    brand_kor: "가스톤루가",
    brand_eng: "Gaston Luga",
    main_category: 3,
  },
  {
    brand_kor: "페라가모 향수",
    brand_eng: "FERRAGAMO PFM",
    main_category: 3,
  },
  {
    brand_kor: "에스티 로더",
    brand_eng: "Estee Lauder",
    main_category: 3,
  },
  {
    brand_kor: "엘리자베스 아덴",
    brand_eng: "ELIZABETH ARDEN",
    main_category: 3,
  },
  {
    brand_kor: "엘리자베스아덴",
    brand_eng: "ELIZABETH ARDEN",
    main_category: 3,
  },
  {
    brand_kor: "듀랑스",
    brand_eng: "durance",
    main_category: 3,
  },
  {
    brand_kor: "듀오",
    brand_eng: "DUO",
    main_category: 3,
  },
  {
    brand_kor: "디스퀘어드 퍼퓸",
    brand_eng: "DSQUARED2",
    main_category: 3,
  },
  {
    brand_kor: "디스퀘어드2",
    brand_eng: "DSQUARED2",
    main_category: 3,
  },
  {
    brand_kor: "디스퀘어드향수",
    brand_eng: "DSQUARED2",
    main_category: 3,
  },
  // {
  //   brand_kor: "닥터원더",
  //   brand_eng: "DRWONDER",
  //   main_category: 7,
  // },
  {
    brand_kor: "드래곤 디퓨전",
    brand_eng: "DRAGON DIFFUSION",
    main_category: 3,
  },
  {
    brand_kor: "닥터포포",
    brand_eng: "Dr.PAWPAW",
    main_category: 3,
  },
  {
    brand_kor: "닥터내츄럴",
    brand_eng: "DR.NATURAL",
    main_category: 8,
  },
  {
    brand_kor: "닥터그루트",
    brand_eng: "DR.GROOT",
    main_category: 3,
  },
  {
    brand_kor: "닥터브로너스",
    brand_eng: "DR.BRONNER'S",
    main_category: 3,
  },
  {
    brand_kor: "닥터바이오",
    brand_eng: "DR.BIO",
    main_category: 1,
  },
  // {
  //   brand_kor: "닥터505",
  //   brand_eng: "DR.505",
  //   main_category: 1,
  // },
  {
    brand_kor: "두피부",
    brand_eng: "doopeeboo",
    main_category: 3,
  },
  {
    brand_kor: "동구밭",
    brand_eng: "Donggubat",
    main_category: 3,
  },
  {
    brand_kor: "더티웍스",
    brand_eng: "DIRTYWORKS",
    main_category: 3,
  },
  {
    brand_kor: "더티런드리",
    brand_eng: "DIRTY LAUNDRY",
    main_category: 3,
  },
  {
    brand_kor: "딥티크",
    brand_eng: "Diptyque",
    main_category: 3,
  },
  {
    brand_kor: "디올",
    brand_eng: "Dior",
    main_category: 3,
  },
  {
    brand_kor: "디뮤",
    brand_eng: "DIIMUU",
    main_category: 3,
  },
  {
    brand_kor: "더마센트릭",
    brand_eng: "dermacentric",
    main_category: 1,
  },
  {
    brand_kor: "덴티스테",
    brand_eng: "Dentiste",
    main_category: 3,
  },
  {
    brand_kor: "데메테르",
    brand_eng: "DEMETER",
    main_category: 3,
  },
  {
    brand_kor: "델리뷰티",
    brand_eng: "delibeauty",
    main_category: 3,
  },
  {
    brand_kor: "디아론",
    brand_eng: "DEARON",
    main_category: 3,
  },
  {
    brand_kor: "디어도어",
    brand_eng: "DEARDOER",
    main_category: 3,
  },
  {
    brand_kor: "디어드라세나",
    brand_eng: "DEAR DRACENA",
    main_category: 3,
  },
  {
    brand_kor: "데미플로",
    brand_eng: "de mi flor",
    main_category: 3,
  },
  {
    brand_kor: "다비도프 향수",
    brand_eng: "Davidoff PERFUME",
    main_category: 3,
  },
  {
    brand_kor: "다비도프",
    brand_eng: "Davidoff",
    main_category: 3,
  },
  {
    brand_kor: "데일리원더",
    brand_eng: "DAILYWONDER",
    main_category: 3,
  },
  {
    brand_kor: "크리드",
    brand_eng: "CREED",
    main_category: 3,
  },
  {
    brand_kor: "꾸레쥬 퍼퓸",
    brand_eng: "COURREGES PERFUME",
    main_category: 3,
  },
  {
    brand_kor: "코스메 데코르테",
    brand_eng: "Cosme Decorte",
    main_category: 3,
  },
  {
    brand_kor: "꼼마꼼마",
    brand_eng: "COMMACOMMA",
    main_category: 8,
  },
  {
    brand_kor: "코치 향수",
    brand_eng: "Coach",
    main_category: 3,
  },
  {
    brand_kor: "크리니크",
    brand_eng: "CLINIQUE",
    main_category: 3,
  },
  {
    brand_kor: "끌로에 향수",
    brand_eng: "CLEO PERFUME",
    main_category: 3,
  },
  {
    brand_kor: "클라랑스",
    brand_eng: "Clarins",
    main_category: 3,
  },
  {
    brand_kor: "크리스찬 루부탱 뷰티",
    brand_eng: "Christian Louboutin Beauty",
    main_category: 3,
  },
  {
    brand_kor: "크리스탈 클라우드",
    brand_eng: "Christian Louboutin Beauty",
    main_category: 3,
  },
  {
    brand_kor: "샤넬",
    brand_eng: "CHANEL",
    main_category: 3,
  },
  {
    brand_kor: "꼬달리",
    brand_eng: "Caudalie",
    main_category: 1,
  },
  {
    brand_kor: "까리에르 프레르",
    brand_eng: "CARRIERE FRERES",
    main_category: 3,
  },
  {
    brand_kor: "바이레도",
    brand_eng: "BYREDO",
    main_category: 3,
  },
  {
    brand_kor: "불가리",
    brand_eng: "BVLGARI",
    main_category: 3,
  },
  {
    brand_kor: "지미추",
    brand_eng: "JIMMY CHOO",
    main_category: 3,
  },
  {
    brand_kor: "지미추",
    brand_eng: "JIMMY CHOO",
    main_category: 5,
  },
  {
    brand_kor: "지미추",
    brand_eng: "JIMMY CHOO",
    main_category: 7,
  },
  {
    brand_kor: "버버리 향수",
    brand_eng: "Burberry",
    main_category: 3,
  },
  {
    brand_kor: "블루독",
    brand_eng: "Bulldog",
    main_category: 3,
  },
  {
    brand_kor: "보타니컬 테라피",
    brand_eng: "BOTANICAL THERAPY",
    main_category: 3,
  },
  {
    brand_kor: "비오템",
    brand_eng: "BIOTHERM",
    main_category: 3,
  },
  {
    brand_kor: "삐아",
    brand_eng: "BBIA",
    main_category: 3,
  },
  {
    brand_kor: "볼빅",
    brand_eng: "BALLVIC",
    main_category: 3,
  },
  {
    brand_kor: "아베다",
    brand_eng: "AVEDA",
    main_category: 3,
  },
  {
    brand_kor: "조르지오 아르마니 뷰티",
    brand_eng: "ARMANI",
    main_category: 3,
  },
  {
    brand_kor: "안나수이",
    brand_eng: "ANNASUI",
    main_category: 3,
  },
  {
    brand_kor: "어뮤즈",
    brand_eng: "AMUSE",
    main_category: 3,
  },
  {
    brand_kor: "어딕션",
    brand_eng: "ADDICTION",
    main_category: 3,
  },
  {
    brand_kor: "아쿠아 디 파르마",
    brand_eng: "Acqua di Parma",
    main_category: 3,
  },
  {
    brand_kor: "쓰리씨이",
    brand_eng: "3CE",
    main_category: 3,
  },
  {
    brand_kor: "1950제주",
    brand_eng: "1950Jeju",
    main_category: 3,
  },
  {
    brand_kor: "킬리안",
    brand_eng: "Kilian",
    main_category: 3,
  },
  {
    brand_kor: "화이트헤더",
    brand_eng: "WHITE HEATHER",
    main_category: 4,
  },
  {
    brand_kor: "더와이즈맨",
    brand_eng: "THE WISEMAN",
    main_category: 4,
  },
  {
    brand_kor: "더 페이머스 그라우스",
    brand_eng: "The Famous Grouse",
    main_category: 4,
  },
  {
    brand_kor: "보타니스트",
    brand_eng: "THE BOTANIST",
    main_category: 4,
  },
  {
    brand_kor: "산토리",
    brand_eng: "SUNTORY",
    main_category: 4,
  },
  {
    brand_kor: "수정방",
    brand_eng: "SHUI JING FANG",
    main_category: 4,
  },
  {
    brand_kor: "로얄살루트",
    brand_eng: "Royal Salute",
    main_category: 4,
  },
  // {
  //   brand_kor: "로즈빌",
  //   brand_eng: "rossville",
  //   main_category: 4,
  // },
  // {
  //   brand_kor: "리노베라",
  //   brand_eng: "RENOVERA",
  //   main_category: 4,
  // },
  {
    brand_kor: "레미마틴",
    brand_eng: "Rémy Martin",
    main_category: 4,
  },
  {
    brand_kor: "레벨",
    brand_eng: "REBEL",
    main_category: 4,
  },
  {
    brand_kor: "람푸르",
    brand_eng: "Rampur",
    main_category: 4,
  },
  // {
  //   brand_kor: "니오칵테일",
  //   brand_eng: "NIO Cocktails",
  //   main_category: 4,
  // },
  {
    brand_kor: "니카",
    brand_eng: "nikka",
    main_category: 4,
  },
  {
    brand_kor: "마오타이",
    brand_eng: "MOUTAI",
    main_category: 4,
  },
  // {
  //   brand_kor: "마비스",
  //   brand_eng: "MARVIS",
  //   main_category: 4,
  // },
  {
    brand_kor: "마르스위스키",
    brand_eng: "MARS Whisky",
    main_category: 4,
  },
  // {
  //   brand_kor: "마스 위스키",
  //   brand_eng: "MARS WHISKY",
  //   main_category: 4,
  // },
  {
    brand_kor: "노주노교",
    brand_eng: "luzhoulaojiao",
    main_category: 4,
  },
  {
    brand_kor: "루이13",
    brand_eng: "louisxiii",
    main_category: 4,
  },
  {
    brand_kor: "루이로여",
    brand_eng: "louisroyer",
    main_category: 4,
  },
  {
    brand_kor: "루이 로드레",
    brand_eng: "Louis Roederer",
    main_category: 4,
  },
  {
    brand_kor: "루이 라뚜르",
    brand_eng: "LOUIS LATOUR",
    main_category: 4,
  },
  {
    brand_kor: "리비코",
    brand_eng: "LIVIKO",
    main_category: 4,
  },
  {
    brand_kor: "로랑 페리에",
    brand_eng: "Laurent Perrier",
    main_category: 4,
  },
  {
    brand_kor: "라프로익",
    brand_eng: "LAPHROAIG",
    main_category: 4,
  },
  {
    brand_kor: "랑주",
    brand_eng: "LANGPAITEQU",
    main_category: 4,
  },
  {
    brand_kor: "라가블린",
    brand_eng: "Lagavulin",
    main_category: 4,
  },
  {
    brand_kor: "라포데리나",
    brand_eng: "La Poderina",
    main_category: 4,
  },
  {
    brand_kor: "교토위스키",
    brand_eng: "KYOTO WHISKY",
    main_category: 4,
  },
  {
    brand_kor: "더 크라켄 블랙 스파이시드 럼",
    brand_eng: "KRAKEN BLACK SPICED RUM",
    main_category: 4,
  },
  {
    brand_kor: "공부가주",
    brand_eng: "KONGFUJIAJIU",
    main_category: 4,
  },
  {
    brand_kor: "깔루아",
    brand_eng: "KAHLUA",
    main_category: 4,
  },
  {
    brand_kor: "호세쿠엘보",
    brand_eng: "JOSE CUERVO",
    main_category: 4,
    same_brand: ["호세 쿠엘보"],
  },
  {
    brand_kor: "조니워커",
    brand_eng: "JOHNNIE WALKER",
    main_category: 4,
  },
  {
    brand_kor: "잭다니엘",
    brand_eng: "JACK DANIEL",
    main_category: 4,
  },
  {
    brand_kor: "더 아일라 보이즈",
    brand_eng: "ISLAY BOYS",
    main_category: 4,
  },
  {
    brand_kor: "화요",
    brand_eng: "hwayo",
    main_category: 4,
  },
  {
    brand_kor: "국교",
    brand_eng: "GUO JIAO",
    main_category: 4,
  },
  {
    brand_kor: "그레이 구스",
    brand_eng: "Grey Goose",
    main_category: 4,
  },
  {
    brand_kor: "그라함",
    brand_eng: "GRAHAM's",
    main_category: 4,
  },
  // {
  //   brand_kor: "고든스",
  //   brand_eng: "GORDON'S",
  //   main_category: 4,
  // },
  // {
  //   brand_kor: "골든블랑",
  //   brand_eng: "Golden Blanc",
  //   main_category: 4,
  // },
  // {
  //   brand_kor: "골드바 위스키",
  //   brand_eng: "Gold Bar Whiskey",
  //   main_category: 4,
  //   same_brand: ["골드바"],
  // },
  {
    brand_kor: "글렌리벳",
    brand_eng: "Glenlivet",
    main_category: 4,
    same_brand: ["더 글렌리벳"],
  },
  {
    brand_kor: "글렌그란트",
    brand_eng: "Glengrant",
    main_category: 4,
  },
  {
    brand_kor: "글렌고인",
    brand_eng: "Glengoyne",
    main_category: 4,
  },
  {
    brand_kor: "글렌피딕",
    brand_eng: "‎Glenfiddich",
    main_category: 4,
  },
  {
    brand_kor: "글렌파클라스",
    brand_eng: "Glenfarclas",
    main_category: 4,
  },
  // {
  //   brand_kor: "글렌드로낙",
  //   brand_eng: "GlenDronach",
  //   main_category: 4,
  // },
  {
    brand_kor: "글렌알라키",
    brand_eng: "GLENALLACHIE",
    main_category: 4,
  },
  {
    brand_kor: "글렌모레이",
    brand_eng: "Glen Moray",
    main_category: 4,
  },
  {
    brand_kor: "가야",
    brand_eng: "GAJA",
    main_category: 4,
  },
  {
    brand_kor: "더글라스랭",
    brand_eng: "Douglas Laing",
    main_category: 4,
  },
  {
    brand_kor: "동방명",
    brand_eng: "Dong Fang Ming",
    main_category: 4,
  },
  {
    brand_kor: "돈훌리오",
    brand_eng: "Don Julio",
    main_category: 4,
  },
  {
    brand_kor: "딩글",
    brand_eng: "Dingle",
    main_category: 4,
  },
  {
    brand_kor: "딕타도르",
    brand_eng: "Dictator",
    main_category: 4,
  },
  {
    brand_kor: "듀어스",
    brand_eng: "Dewar's",
    main_category: 4,
  },
  {
    brand_kor: "드 루즈",
    brand_eng: "deluze",
    main_category: 4,
  },
  {
    brand_kor: "딘스톤",
    brand_eng: "Deanston",
    main_category: 4,
  },
  {
    brand_kor: "단즈카",
    brand_eng: "DANZKA",
    main_category: 4,
  },
  {
    brand_kor: "다나에스테이트",
    brand_eng: "Dana Estates",
    main_category: 4,
  },
  {
    brand_kor: "달위니",
    brand_eng: "Dalwhinnie",
    main_category: 4,
  },
  {
    brand_kor: "달모어",
    brand_eng: "dalmore",
    main_category: 4,
  },
  {
    brand_kor: "꼼데그라스",
    brand_eng: "COMTE DE GRASSE",
    main_category: 4,
  },
  {
    brand_kor: "시바스리갈",
    brand_eng: "CHIVAS REGAL",
    main_category: 4,
  },
  {
    brand_kor: "까사마에스트리",
    brand_eng: "Casa Maestri",
    main_category: 4,
  },
  {
    brand_kor: "까뮤",
    brand_eng: "CAMUS",
    main_category: 4,
  },
  {
    brand_kor: "부쉬밀",
    brand_eng: "BUSHMILLS",
    main_category: 4,
  },
  {
    brand_kor: "보테가",
    brand_eng: "BOTTEGA",
    main_category: 4,
  },
  {
    brand_kor: "봄베이 사파이어",
    brand_eng: "BOMBAY SAPPHIRE",
    main_category: 4,
  },
  {
    brand_kor: "발베니",
    brand_eng: "BALVENIE",
    main_category: 4,
  },
  {
    brand_kor: "발렌타인",
    brand_eng: "BALLANTINE'S",
    main_category: 4,
  },
  {
    brand_kor: "로더스",
    brand_eng: "LAUDERS",
    main_category: 4,
  },
  // {
  //   brand_kor: "투미",
  //   brand_eng: "TUMI",
  //   main_category: 5,
  // },
  {
    brand_kor: "시로카라",
    brand_eng: "Shirocara",
    main_category: 5,
  },
  {
    brand_kor: "쌤소나이트",
    brand_eng: "SAMSONITE",
    main_category: 5,
  },
  {
    brand_kor: "로우로우",
    brand_eng: "Rowrow",
    main_category: 5,
  },
  {
    brand_kor: "론카토",
    brand_eng: "Roncato",
    main_category: 5,
  },
  {
    brand_kor: "로베르타 피에리",
    brand_eng: "ROBERTA PIERI",
    main_category: 5,
  },
  {
    brand_kor: "르브아시스",
    brand_eng: "REVOIRSIS",
    main_category: 5,
  },
  {
    brand_kor: "라브라크",
    brand_eng: "RAVRAC",
    main_category: 5,
  },
  {
    brand_kor: "레인스",
    brand_eng: "RAINS",
    main_category: 5,
  },
  {
    brand_kor: "뉴이",
    brand_eng: "Nuer",
    main_category: 5,
  },
  {
    brand_kor: "네이키드니스",
    brand_eng: "NEIKIDNIS",
    main_category: 5,
  },
  {
    brand_kor: "마이클 코어스",
    brand_eng: "Michael Kors",
    main_category: 5,
    same_brand: ["마이클코어스"],
  },
  {
    brand_kor: "마르니",
    brand_eng: "MARNI",
    main_category: 5,
  },
  {
    brand_kor: "마크엠",
    brand_eng: "MARKM",
    main_category: 5,
  },
  {
    brand_kor: "마르헨제이",
    brand_eng: "MARHEN.J",
    main_category: 5,
  },
  {
    brand_kor: "마지셔우드",
    brand_eng: "MARGESHERWOOD",
    main_category: 5,
  },
  {
    brand_kor: "마크제이콥스",
    brand_eng: "MARC JACOBS",
    main_category: 5,
  },
  {
    brand_kor: "만다리나덕",
    brand_eng: "MANDARINA DUCK",
    main_category: 5,
    same_brand: ["만다리나덕(SPA)"],
  },
  {
    brand_kor: "뤼에르",
    brand_eng: "LUEUR PARIS",
    main_category: 5,
  },
  {
    brand_kor: "루이우이",
    brand_eng: "louie ooie",
    main_category: 5,
  },
  {
    brand_kor: "라츠유",
    brand_eng: "LOTSYOU",
    main_category: 5,
  },
  {
    brand_kor: "로키",
    brand_eng: "LOQI",
    main_category: 5,
  },
  {
    brand_kor: "루즈앤라운지",
    brand_eng: "Loose and Lounge",
    main_category: 5,
  },
  {
    brand_kor: "롱샴",
    brand_eng: "LONGCHAMP",
    main_category: 5,
  },
  {
    brand_kor: "로에카",
    brand_eng: "LOEKA",
    main_category: 5,
  },
  {
    brand_kor: "레스포삭",
    brand_eng: "LESPORTSAC",
    main_category: 5,
    same_brand: ["레스포색"],
  },
  {
    brand_kor: "르메르",
    brand_eng: "LEMAIRE",
    main_category: 5,
  },
  {
    brand_kor: "르마스크",
    brand_eng: "LE MASQUE",
    main_category: 5,
  },
  {
    brand_kor: "레이트리 스튜디오",
    brand_eng: "LATELEE STUDIO",
    main_category: 5,
  },
  {
    brand_kor: "라코스테 레더 굿즈",
    brand_eng: "LACOSTE LEATHER GOODS",
    main_category: 5,
  },
  {
    brand_kor: "키플링",
    brand_eng: "Kipling",
    main_category: 5,
  },
  {
    brand_kor: "훌라",
    brand_eng: "Hula",
    main_category: 5,
  },
  {
    brand_kor: "그랭드보떼",
    brand_eng: "GRAIN DE BEAUTE",
    main_category: 5,
  },
  {
    brand_kor: "글램지",
    brand_eng: "GLAMGM",
    main_category: 5,
  },
  {
    brand_kor: "가이거",
    brand_eng: "GEIGER",
    main_category: 5,
  },
  {
    brand_kor: "가니",
    brand_eng: "GANNI",
    main_category: 5,
  },
  {
    brand_kor: "푸쉐",
    brand_eng: "Fuschia",
    main_category: 5,
  },
  {
    brand_kor: "드릴리스",
    brand_eng: "DRILLEYS",
    main_category: 5,
  },
  {
    brand_kor: "디어니스",
    brand_eng: "DIERNEAS",
    main_category: 5,
  },
  {
    brand_kor: "단색",
    brand_eng: "DANSAEK",
    main_category: 5,
  },
  {
    brand_kor: "닥스런던",
    brand_eng: "DAKS LONDON",
    main_category: 5,
  },
  {
    brand_kor: "칼린",
    brand_eng: "Carin",
    main_category: 5,
  },
  {
    brand_kor: "앨리스마샤",
    brand_eng: "ALICE MARSHA",
    main_category: 5,
  },
  {
    brand_kor: "베르사체 시계",
    brand_eng: "VERSACE WATCH",
    main_category: 6,
  },
  // {
  //   brand_kor: "발렌티노루디",
  //   brand_eng: "VALENTINO RUDY",
  //   main_category: 6,
  // },
  {
    brand_kor: "발렌티노",
    brand_eng: "VALENTINO",
    main_category: 6,
  },
  {
    brand_kor: "토리버치 시계",
    brand_eng: "TORY BURCH",
    main_category: 6,
  },
  {
    brand_kor: "타미힐피거 시계",
    brand_eng: "Tommy Hilfiger",
    main_category: 6,
  },
  {
    brand_kor: "티쏘",
    brand_eng: "Tissot",
    main_category: 6,
  },
  {
    brand_kor: "토마스사보",
    brand_eng: "Thomas Sabo",
    main_category: 6,
  },
  {
    brand_kor: "태그호이어",
    brand_eng: "TAG HEUER",
    main_category: 6,
  },
  {
    brand_kor: "스와로브스키",
    brand_eng: "Swarovski",
    main_category: 6,
  },
  {
    brand_kor: "스톤헨지",
    brand_eng: "Stone Henge",
    main_category: 6,
  },
  // {
  //   brand_kor: "세루아",
  //   brand_eng: "Samsung",
  //   main_category: 6,
  // },
  {
    brand_kor: "로즈몽 주얼리",
    brand_eng: "ROSEMONT Jewelry",
    main_category: 6,
    same_brand: ["로즈몽주얼리"],
  },
  {
    brand_kor: "로즈몽",
    brand_eng: "ROSEMONT",
    main_category: 6,
  },
  // {
  //   brand_kor: "로제도르",
  //   brand_eng: "RoseeDor",
  //   main_category: 6,
  // },
  // {
  //   brand_kor: "로만손",
  //   brand_eng: "ROMANSON",
  //   main_category: 6,
  // },
  // {
  //   brand_kor: "로아주",
  //   brand_eng: "ROAJU",
  //   main_category: 6,
  // },
  // {
  //   brand_kor: "리타모니카",
  //   brand_eng: "RITA MONICA",
  //   main_category: 6,
  // },
  {
    brand_kor: "레네레이드",
    brand_eng: "RENÉ RADÉ",
    main_category: 6,
  },
  {
    brand_kor: "라도",
    brand_eng: "RADO",
    main_category: 6,
  },
  {
    brand_kor: "필그림",
    brand_eng: "Pilgrim",
    main_category: 6,
  },
  {
    brand_kor: "판도라",
    brand_eng: "Pandora",
    main_category: 6,
  },
  {
    brand_kor: "누누핑거스",
    brand_eng: "NOONOO FINGERS",
    main_category: 6,
  },
  {
    brand_kor: "논논",
    brand_eng: "NONENON",
    main_category: 6,
    same_brand: ["논논주얼리 ( NONENON )"],
  },
  {
    brand_kor: "몽블랑",
    brand_eng: "Montblanc",
    main_category: 5,
  },
  {
    brand_kor: "밀튼스텔리",
    brand_eng: "Milton Stelly",
    main_category: 6,
    same_brand: ["밀튼스텔리 시계", "밀튼스텔리 쥬얼리"],
  },
  {
    brand_kor: "미도",
    brand_eng: "MIDO",
    main_category: 6,
  },
  // {
  //   brand_kor: "마이클 코어스 시계",
  //   brand_eng: "Michael Kors",
  //   main_category: 6,
  // },
  // {
  //   brand_kor: "마이클 코어스(주얼리)",
  //   brand_eng: "Michael Kors",
  //   main_category: 6,
  // },
  {
    brand_kor: "메트로시티주얼리",
    brand_eng: "METROCITYJEWELRY",
    main_category: 6,
  },
  {
    brand_kor: "마세라티",
    brand_eng: "MASERATI",
    main_category: 6,
  },
  {
    brand_kor: "마크엠",
    brand_eng: "MARKM",
    main_category: 6,
  },
  // {
  //   brand_kor: "마리끌레르 주얼리",
  //   brand_eng: "MARIECLAIRE JEWLRY",
  //   main_category: 6,
  //   same_brand: ["마리끌레르"],
  // },
  {
    brand_kor: "마마카사르",
    brand_eng: "MAMA CASAR",
    main_category: 6,
  },
  {
    brand_kor: "메종키츠네",
    brand_eng: "MAISON KITSUNÉ",
    main_category: 7,
  },
  {
    brand_kor: "루미녹스",
    brand_eng: "LUMINOX",
    main_category: 6,
  },
  // {
  //   brand_kor: "라츠유",
  //   brand_eng: "LOTSYOU",
  //   main_category: 6,
  // },
  {
    brand_kor: "론진",
    brand_eng: "Longines",
    main_category: 6,
  },
  {
    brand_kor: "라코스테 시계",
    brand_eng: "LACOSTE WATCH",
    main_category: 6,
    same_brand: ["라코스테 워치"],
  },
  {
    brand_kor: "제이에스티나",
    brand_eng: "J.ESTINA",
    main_category: 6,
  },
  {
    brand_kor: "해밀턴",
    brand_eng: "HAMILTON",
    main_category: 6,
  },
  {
    brand_kor: "해수엘",
    brand_eng: "Haesuul",
    main_category: 6,
  },
  {
    brand_kor: "게스 시계",
    brand_eng: "guess watch",
    main_category: 6,
    same_brand: ["게스 워치"],
  },
  // {
  //   brand_kor: "구찌 타임피스 앤 주얼리",
  //   brand_eng: "Gucci Timepieces and Jewelry",
  //   main_category: 6,
  // },
  {
    brand_kor: "그로바나",
    brand_eng: "Grovana",
    main_category: 6,
  },
  {
    brand_kor: "그랜드 세이코",
    brand_eng: "Grand Seiko)",
    main_category: 6,
  },
  {
    brand_kor: "골든듀",
    brand_eng: "Goldendew",
    main_category: 6,
  },
  {
    brand_kor: "겟미블링",
    brand_eng: "GETMEBLING",
    main_category: 6,
  },
  {
    brand_kor: "가민",
    brand_eng: "GARMIN",
    main_category: 6,
  },
  {
    brand_kor: "가가밀라노",
    brand_eng: "Gaga Milano",
    main_category: 6,
  },
  {
    brand_kor: "프레드릭 콘스탄트",
    brand_eng: "FREDERIQUE CONSTANT",
    main_category: 6,
  },
  {
    brand_kor: "페라리",
    brand_eng: "FERRARI",
    main_category: 6,
  },
  {
    brand_kor: "페라가모 (WATCH)",
    brand_eng: "FERRAGAMO WATCH",
    main_category: 6,
    same_brand: ["페라가모 시계"],
  },
  {
    brand_kor: "펜디",
    brand_eng: "FENDI",
    main_category: 6,
    same_brand: ["펜디 (WAT)", "펜디 (WATCH)"],
  },
  {
    brand_kor: "디즈니마블워치",
    brand_eng: "DISNEY MARVEL WATCH",
    main_category: 6,
    same_brand: ["디즈니"],
  },
  {
    brand_kor: "디네치",
    brand_eng: "DINECH",
    main_category: 6,
  },
  {
    brand_kor: "디디에두보",
    brand_eng: "Didier Dubot",
    main_category: 6,
  },
  {
    brand_kor: "다이아몬드 포레스트",
    brand_eng: "Diamond forest",
    main_category: 6,
  },
  // {
  //   brand_kor: "디아모모",
  //   brand_eng: "DIAMOMO",
  //   main_category: 6,
  // },
  {
    brand_kor: "다이아미",
    brand_eng: "Diame",
    main_category: 6,
  },
  // {
  //   brand_kor: "데이비드 율만",
  //   brand_eng: "DAVID YURMAN",
  //   main_category: 6,
  // },
  {
    brand_kor: "다니엘 웰링턴",
    brand_eng: "Daniel Wellington",
    main_category: 6,
    same_brand: ["다니엘웰링턴"],
  },
  {
    brand_kor: "다나버튼",
    brand_eng: "DANA BURTON",
    main_category: 6,
  },
  // {
  //   brand_kor: "디원밀라노",
  //   brand_eng: "D1 MILANO",
  //   main_category: 6,
  // },
  // {
  //   brand_kor: "꼼데가르송",
  //   brand_eng: "COMME DES GARCONS",
  //   main_category: 6,
  // },
  {
    brand_kor: "꼼에스타",
    brand_eng: "comesta",
    main_category: 6,
  },
  {
    brand_kor: "코치",
    brand_eng: "Coach",
    main_category: 6,
    same_brand: ["코치 (WATCH)", "코치 시계", "코치 워치"],
  },
  {
    brand_kor: "캘빈클라인 시계",
    brand_eng: "Calvin Klein",
    main_category: 6,
    same_brand: ["캘빈클라인 워치"],
  },
  {
    brand_kor: "에이피엠 모나코",
    brand_eng: "A.P.C. Monaco",
    main_category: 6,
    same_brand: ["에이피엠모나코"],
  },
  // {
  //   brand_kor: "1064 스튜디오",
  //   brand_eng: "1604 STUDIO",
  //   main_category: 6,
  // },
  {
    brand_kor: "화이트샌즈",
    brand_eng: "WHITE SANDS",
    main_category: 7,
  },
  // {
  //   brand_kor: "베르사체",
  //   brand_eng: "VERSACE",
  //   main_category: 7,
  // },
  {
    brand_kor: "발렌티노",
    brand_eng: "VALENTINO",
    main_category: 7,
  },
  {
    brand_kor: "언더아머",
    brand_eng: "Under Armour",
    main_category: 7,
  },
  {
    brand_kor: "투미",
    brand_eng: "TUMI",
    main_category: 7,
  },
  {
    brand_kor: "타미힐피거",
    brand_eng: "Tommy Hilfiger",
    main_category: 7,
  },
  // {
  //   brand_kor: "톰포드 아이웨어",
  //   brand_eng: "Tom Ford Beauty",
  //   main_category: 7,
  // },
  {
    brand_kor: "떰토",
    brand_eng: "Thumb Toe",
    main_category: 7,
  },
  {
    brand_kor: "톰브라운",
    brand_eng: "THOM BROWNE",
    main_category: 7,
  },
  // {
  //   brand_kor: "스와로브스키",
  //   brand_eng: "Swarovski",
  //   main_category: 7,
  // },
  {
    brand_kor: "스튜어트 와이츠먼",
    brand_eng: "STUART WEITZMAN",
    main_category: 7,
  },
  // {
  //   brand_kor: "스노우피크",
  //   brand_eng: "Snow Peak",
  //   main_category: 7,
  // },
  {
    brand_kor: "스케쳐스",
    brand_eng: "Skechers",
    main_category: 7,
  },
  // {
  //   brand_kor: "시로카라",
  //   brand_eng: "Shirocara",
  //   main_category: 7,
  // },
  {
    brand_kor: "세르지오 로시",
    brand_eng: "Sergio Rossi",
    main_category: 7,
  },
  {
    brand_kor: "생로랑 아이웨어",
    brand_eng: "SAINT LAURENT EYE",
    main_category: 7,
  },
  // {
  //   brand_kor: "루디 프로젝트 용품",
  //   brand_eng: "RUDY PROJECT",
  //   main_category: 7,
  // },
  {
    brand_kor: "롤링롤라이",
    brand_eng: "RollingRolleye",
    main_category: 7,
  },
  {
    brand_kor: "로라로라",
    brand_eng: "ROLAROLA",
    main_category: 7,
  },
  {
    brand_kor: "로베르타피에리(베트리나)",
    brand_eng: "ROBERTA PIERI(VETRINA)",
    main_category: 7,
  },
  {
    brand_kor: "로베르타 피에리",
    brand_eng: "ROBERTA PIERI",
    main_category: 7,
  },
  {
    brand_kor: "리에티",
    brand_eng: "RIETI",
    main_category: 7,
  },
  // {
  //   brand_kor: "레보",
  //   brand_eng: "REVO",
  //   main_category: 7,
  // },
  // {
  //   brand_kor: "레페토",
  //   brand_eng: "REPETTO",
  //   main_category: 7,
  // },
  // {
  //   brand_kor: "리무브",
  //   brand_eng: "REMOVE",
  //   main_category: 7,
  // },
  {
    brand_kor: "레이밴 쥬니어",
    brand_eng: "Ray-Ban Junior",
    main_category: 7,
  },
  {
    brand_kor: "레이밴",
    brand_eng: "Ray-Ban",
    main_category: 7,
  },
  // {
  //   brand_kor: "라주",
  //   brand_eng: "raju",
  //   main_category: 7,
  // },
  // {
  //   brand_kor: "프라다",
  //   brand_eng: "PRADA",
  //   main_category: 7,
  //   same_brand: ["프라다 아이웨어"],
  // },
  {
    brand_kor: "오클리",
    brand_eng: "Oakley",
    main_category: 7,
  },
  // {
  //   brand_kor: "노도니트",
  //   brand_eng: "NODOKNITS",
  //   main_category: 7,
  // },
  // {
  //   brand_kor: "느릿",
  //   brand_eng: "NERIT",
  //   main_category: 7,
  // },
  {
    brand_kor: "널디(H.콜라보)",
    brand_eng: "NERDY(H.Callabo)",
    main_category: 7,
  },
  {
    brand_kor: "널디",
    brand_eng: "NERDY",
    main_category: 7,
  },
  {
    brand_kor: "노티카",
    brand_eng: "NAUTICA",
    main_category: 7,
  },
  // {
  //   brand_kor: "멀버리 아이웨어",
  //   brand_eng: "MULBERRY EYE",
  //   main_category: 7,
  // },
  {
    brand_kor: "멀버리",
    brand_eng: "Mulberry",
    main_category: 7,
  },
  {
    brand_kor: "모스키노",
    brand_eng: "Moschino",
    main_category: 7,
  },
  {
    brand_kor: "몽블랑",
    brand_eng: "Montblanc",
    main_category: 7,
  },
  {
    brand_kor: "몽블랑",
    brand_eng: "Montblanc",
    main_category: 6,
  },
  // {
  //   brand_kor: "몽블랑 아이웨어",
  //   brand_eng: "Montblanc",
  //   main_category: 7,
  // },
  // {
  //   brand_kor: "몽클레르",
  //   brand_eng: "MONCLER",
  //   main_category: 7,
  // },
  {
    brand_kor: "몽클레르 키즈",
    brand_eng: "MONCLER",
    main_category: 7,
  },
  // {
  //   brand_kor: "몽클레어",
  //   brand_eng: "MONCLER",
  //   main_category: 7,
  // },
  // {
  //   brand_kor: "몽클레어 아이웨어",
  //   brand_eng: "MONCLER",
  //   main_category: 7,
  // },
  {
    brand_kor: "엠엠엘지",
    brand_eng: "MMLG",
    main_category: 7,
  },
  {
    brand_kor: "메트로시티",
    brand_eng: "METROCITY",
    main_category: 7,
  },
  {
    brand_kor: "맨즈 컬렉션",
    brand_eng: "Men's Collection",
    main_category: 7,
  },
  // {
  //   brand_kor: "막스마라",
  //   brand_eng: "MAXMARA",
  //   main_category: 7,
  // },
  {
    brand_kor: "마른파이브",
    brand_eng: "marun5",
    main_category: 7,
  },
  // {
  //   brand_kor: "마크앤로나",
  //   brand_eng: "MARK&LONA",
  //   main_category: 7,
  // },
  // {
  //   brand_kor: "마리끌레르 아이웨어",
  //   brand_eng: "MARIE CLAIRE EYE",
  //   main_category: 7,
  // },
  // {
  //   brand_kor: "마리끌레르",
  //   brand_eng: "Marie Claire",
  //   main_category: 7,
  // },
  // {
  //   brand_kor: "마지셔우드",
  //   brand_eng: "MARGESHERWOOD",
  //   main_category: 7,
  // },
  {
    brand_kor: "메종 마르지엘라",
    brand_eng: "MAISON MARGIELA",
    main_category: 7,
  },
  {
    brand_kor: "럭키슈에뜨",
    brand_eng: "LUCKY CHOUETTE",
    main_category: 7,
  },
  {
    brand_kor: "루이까또즈",
    brand_eng: "LOUIS QUATORZE",
    main_category: 7,
  },
  {
    brand_kor: "라츠유",
    brand_eng: "LOTSYOU",
    main_category: 7,
  },
  // {
  //   brand_kor: "루즈앤라운지",
  //   brand_eng: "Loose and Lounge",
  //   main_category: 7,
  // },
  // {
  //   brand_kor: "롱샴",
  //   brand_eng: "LONGCHAMP",
  //   main_category: 7,
  // },
  {
    brand_kor: "로이드",
    brand_eng: "LLOYD",
    main_category: 7,
  },
  {
    brand_kor: "렛츠다이어트",
    brand_eng: "LET`S DIET",
    main_category: 7,
  },
  {
    brand_kor: "리",
    brand_eng: "Lee",
    main_category: 7,
  },
  {
    brand_kor: "르쏘넷",
    brand_eng: "LE SONNET",
    main_category: 7,
  },
  {
    brand_kor: "르모티프",
    brand_eng: "Le Motif",
    main_category: 7,
  },
  // {
  //   brand_kor: "래쉬",
  //   brand_eng: "LASH",
  //   main_category: 7,
  // },
  {
    brand_kor: "랑방(베뜨리나)",
    brand_eng: "LANVIN(VETRINA)",
    main_category: 7,
    same_brand: ["랑방(베트리나)"],
  },
  {
    brand_kor: "라코스테",
    brand_eng: "LACOSTE",
    main_category: 7,
    same_brand: ["라코스테(국산의류)", "라코스테(대표)", "라코스테(수입)"],
  },
  {
    brand_kor: "레탑",
    brand_eng: "L'ETAPE",
    main_category: 7,
  },
  {
    brand_kor: "겐조(베트리나)",
    brand_eng: "KENZO(VETRINA)",
    main_category: 7,
  },
  // {
  //   brand_kor: "까롯",
  //   brand_eng: "KAROT",
  //   main_category: 7,
  // },
  {
    brand_kor: "캉골",
    brand_eng: "Kangol",
    main_category: 7,
  },
  // {
  //   brand_kor: "질스튜어트",
  //   brand_eng: "Jill Stuart",
  //   main_category: 7,
  //   same_brand: ["질스튜어트 뉴욕", "질스튜어트 아이웨어"],
  // },
  // {
  //   brand_kor: "제이린드버그",
  //   brand_eng: "J.Lindeberg",
  //   main_category: 7,
  //   same_brand: ["제이린드버그(병행수입)"],
  // },
  // {
  //   brand_kor: "제이에스티나",
  //   brand_eng: "J.ESTINA",
  //   main_category: 7,
  // },
  // {
  //   brand_kor: "훌라",
  //   brand_eng: "Hula",
  //   main_category: 7,
  // },
  // {
  //   brand_kor: "훌라(베트리나)",
  //   brand_eng: "Hula",
  //   main_category: 7,
  // },
  {
    brand_kor: "헤지스",
    brand_eng: "Hedges",
    main_category: 7,
    same_brand: ["헤지스 아이웨어"],
  },
  // {
  //   brand_kor: "게스",
  //   brand_eng: "guess",
  //   main_category: 7,
  // },
  {
    brand_kor: "구찌 아이웨어",
    brand_eng: "Gucci Eyewear",
    main_category: 7,
  },
  {
    brand_kor: "그랭드보떼",
    brand_eng: "GRAIN DE BEAUTE",
    main_category: 7,
  },
  // {
  //   brand_kor: "그래피티온마인드",
  //   brand_eng: "GRAFFITIONMIND",
  //   main_category: 7,
  // },
  // {
  //   brand_kor: "굿띵즈",
  //   brand_eng: "GOOD THINGS",
  //   main_category: 7,
  // },
  {
    brand_kor: "지방시",
    brand_eng: "GIVENCHY",
    main_category: 7,
    same_brand: ["지방시(베뜨리나)"],
  },
  {
    brand_kor: "가니",
    brand_eng: "GANNI",
    main_category: 7,
  },
  {
    brand_kor: "감탄브라",
    brand_eng: "gamtanbra",
    main_category: 7,
  },
  // {
  //   brand_kor: "플리츠마마",
  //   brand_eng: "Flit Mama",
  //   main_category: 7,
  // },
  // {
  //   brand_kor: "휠라",
  //   brand_eng: "FILA EYEWEAR",
  //   main_category: 7,
  // },
  {
    brand_kor: "페라가모 아이웨어",
    brand_eng: "FERRAGAMO",
    main_category: 7,
  },
  // {
  //   brand_kor: "펜디(베트리나)",
  //   brand_eng: "FENDI",
  //   main_category: 7,
  // },
  {
    brand_kor: "에코",
    brand_eng: "Echo",
    main_category: 7,
  },
  {
    brand_kor: "디스펑트",
    brand_eng: "DYSFUNCT",
    main_category: 7,
  },
  // {
  //   brand_kor: "듀퐁",
  //   brand_eng: "DuPont",
  //   main_category: 7,
  //   same_brand: ["듀퐁 아이웨어"],
  // },
  {
    brand_kor: "닥터마틴",
    brand_eng: "DR.MARTENS",
    main_category: 7,
  },
  {
    brand_kor: "도로시와",
    brand_eng: "DOROSIWA",
    main_category: 7,
  },
  {
    brand_kor: "돈포겟미",
    brand_eng: "DONTFORGETME",
    main_category: 7,
  },
  {
    brand_kor: "디케이엔와이 아이웨어",
    brand_eng: "DKNY EYEWEAR",
    main_category: 7,
  },
  // {
  //   brand_kor: "데스트리",
  //   brand_eng: "DESTREE",
  //   main_category: 7,
  // },
  {
    brand_kor: "데이즈데이즈",
    brand_eng: "Days Days",
    main_category: 7,
  },
  // {
  //   brand_kor: "데이니즈",
  //   brand_eng: "DAYNEEDS",
  //   main_category: 7,
  // },
  // {
  //   brand_kor: "디오스피스",
  //   brand_eng: "DAUSPICE",
  //   main_category: 7,
  // },
  {
    brand_kor: "데어플레이",
    brand_eng: "DAREPLAY",
    main_category: 7,
  },
  {
    brand_kor: "다나버튼",
    brand_eng: "DANA BURTON",
    main_category: 7,
  },
  {
    brand_kor: "닥스",
    brand_eng: "DAKS",
    main_category: 7,
  },
  // {
  //   brand_kor: "꾸레쥬",
  //   brand_eng: "COURREGES",
  //   main_category: 7,
  // },
  // {
  //   brand_kor: "쿠론",
  //   brand_eng: "Couronne",
  //   main_category: 7,
  // },
  {
    brand_kor: "코드그라피",
    brand_eng: "CODEGRAPHI",
    main_category: 7,
  },
  {
    brand_kor: "코치",
    brand_eng: "Coach",
    main_category: 7,
  },
  {
    brand_kor: "끌로에 아이웨어",
    brand_eng: "CLEO EYEWEAR",
    main_category: 7,
  },
  {
    brand_kor: "칼린",
    brand_eng: "Carin",
    main_category: 7,
  },
  {
    brand_kor: "캘빈클라인",
    brand_eng: "Calvin Klein",
    main_category: 7,
  },
  {
    brand_kor: "캘빈클라인 아이웨어",
    brand_eng: "Calvin Klein",
    main_category: 7,
  },
  {
    brand_kor: "불가리 아이웨어",
    brand_eng: "BVLGARI EYEWEAR",
    main_category: 7,
  },
  // {
  //   brand_kor: "버버리 아이웨어",
  //   brand_eng: "Burberry",
  //   main_category: 7,
  // },
  {
    brand_kor: "보테가 베네타 아이웨어",
    brand_eng: "BOTTEGA VENETA EYE",
    main_category: 7,
  },
  {
    brand_kor: "배럴",
    brand_eng: "Barrel",
    main_category: 7,
  },
  {
    brand_kor: "발리",
    brand_eng: "Bali",
    main_category: 7,
  },
  {
    brand_kor: "발렌시아가 아이웨어",
    brand_eng: "BALENCIAGA EYE",
    main_category: 7,
  },
  {
    brand_kor: "발렌시아가",
    brand_eng: "BALENCIAGA",
    main_category: 7,
  },
  {
    brand_kor: "나인",
    brand_eng: "Atelier Nain",
    main_category: 7,
  },
  {
    brand_kor: "아쉬",
    brand_eng: "Ashes",
    main_category: 7,
  },
  {
    brand_kor: "아미",
    brand_eng: "AMI",
    main_category: 7,
  },
  // {
  //   brand_kor: "알로봇",
  //   brand_eng: "Allobot",
  //   main_category: 7,
  // },
  {
    brand_kor: "앨리스마샤",
    brand_eng: "ALICE MARSHA",
    main_category: 7,
  },
  {
    brand_kor: "아키클래식",
    brand_eng: "Aki Classic",
    main_category: 7,
  },
  {
    brand_kor: "아크메드라비",
    brand_eng: "Acmedravi",
    main_category: 7,
  },
  {
    brand_kor: "더리얼랩",
    brand_eng: "The Real LAB",
    main_category: 8,
  },
  {
    brand_kor: "려원담",
    brand_eng: "ryeowondam",
    main_category: 8,
  },
  {
    brand_kor: "로얄제과",
    brand_eng: "ROYAL CNF",
    main_category: 8,
  },
  {
    brand_kor: "로잘리",
    brand_eng: "rosalie",
    main_category: 8,
  },
  {
    brand_kor: "리터 스포트",
    brand_eng: "RITTER SPORT",
    main_category: 8,
  },
  {
    brand_kor: "리콜라",
    brand_eng: "ricola",
    main_category: 8,
  },
  {
    brand_kor: "리쥬란",
    brand_eng: "REJURAN",
    main_category: 8,
  },
  {
    brand_kor: "리부트",
    brand_eng: "reboot",
    main_category: 8,
  },
  {
    brand_kor: "레디-큐",
    brand_eng: "Ready Q",
    main_category: 8,
  },
  {
    brand_kor: "레이델",
    brand_eng: "RAYDEL",
    main_category: 8,
  },
  {
    brand_kor: "라디메리",
    brand_eng: "Radimery",
    main_category: 8,
  },
  {
    brand_kor: "뉴트리원",
    brand_eng: "NutrioneLife",
    main_category: 8,
  },
  {
    brand_kor: "뉴트리원라이프",
    brand_eng: "NutrioneLife",
    main_category: 8,
  },
  {
    brand_kor: "누트라코스",
    brand_eng: "NUTRAKOS",
    main_category: 8,
  },
  {
    brand_kor: "뉴트라라이프",
    brand_eng: "Nurtalife",
    main_category: 8,
  },
  {
    brand_kor: "뉴온",
    brand_eng: "Nuon",
    main_category: 8,
  },
  {
    brand_kor: "노워리즈",
    brand_eng: "noworries",
    main_category: 8,
  },
  {
    brand_kor: "낫띵베럴",
    brand_eng: "NOTHING BETTER",
    main_category: 8,
  },
  {
    brand_kor: "니카",
    brand_eng: "nikka",
    main_category: 8,
  },
  {
    brand_kor: "뉴오리진",
    brand_eng: "Neworigin",
    main_category: 8,
  },
  {
    brand_kor: "뉴라덤",
    brand_eng: "NEURADERM",
    main_category: 8,
  },
  {
    brand_kor: "네이처메이드",
    brand_eng: "naturemade",
    main_category: 8,
  },
  {
    brand_kor: "네이처스 트루스",
    brand_eng: "Nature's Truth",
    main_category: 8,
  },
  {
    brand_kor: "네추럴라이즈",
    brand_eng: "Naturalize",
    main_category: 8,
  },
  {
    brand_kor: "나트루바",
    brand_eng: "NATRUBA",
    main_category: 8,
  },
  {
    brand_kor: "남양유업",
    brand_eng: "namyang",
    main_category: 8,
  },
  {
    brand_kor: "마이니",
    brand_eng: "MY NI",
    main_category: 8,
  },
  {
    brand_kor: "막심드파리",
    brand_eng: "MAXIM'S DE PARIS",
    main_category: 8,
  },
  {
    brand_kor: "마르스",
    brand_eng: "MARS",
    main_category: 8,
  },
  {
    brand_kor: "럭키클로버",
    brand_eng: "LUCKY CLOVER",
    main_category: 8,
  },
  {
    brand_kor: "로아커",
    brand_eng: "Loacker",
    main_category: 8,
  },
  {
    brand_kor: "링티",
    brand_eng: "lingtea",
    main_category: 8,
  },
  {
    brand_kor: "라이프타임",
    brand_eng: "lifetime",
    main_category: 8,
  },
  {
    brand_kor: "라이프스팬",
    brand_eng: "lifespan",
    main_category: 8,
  },
  {
    brand_kor: "레오네",
    brand_eng: "LEONE",
    main_category: 8,
  },
  {
    brand_kor: "리즈랩",
    brand_eng: "leedslab",
    main_category: 8,
  },
  {
    brand_kor: "락토바이브",
    brand_eng: "lactoviv",
    main_category: 8,
  },
  {
    brand_kor: "락티브",
    brand_eng: "Lactiv",
    main_category: 8,
  },
  {
    brand_kor: "락피도",
    brand_eng: "LACFIDO",
    main_category: 8,
  },
  {
    brand_kor: "경남제약",
    brand_eng: "Kyungnam Pharm",
    main_category: 8,
  },
  {
    brand_kor: "고려인삼이야기",
    brand_eng: "KOREAN GINSENG STORY",
    main_category: 8,
  },
  {
    brand_kor: "대한홍삼",
    brand_eng: "koreahongsam",
    main_category: 8,
  },
  {
    brand_kor: "고려은단",
    brand_eng: "Korea eundan",
    main_category: 8,
  },
  {
    brand_kor: "김정환홍삼",
    brand_eng: "kimsredginseng",
    main_category: 8,
  },
  {
    brand_kor: "휴럼",
    brand_eng: "Hurum",
    main_category: 8,
  },
  {
    brand_kor: "휴온스",
    brand_eng: "Huons",
    main_category: 8,
  },
  {
    brand_kor: "홍삼가건보",
    brand_eng: "HONGSAMGA KUNBO",
    main_category: 8,
  },
  {
    brand_kor: "길리안",
    brand_eng: "Guylian",
    main_category: 8,
  },
  {
    brand_kor: "그린핑거",
    brand_eng: "greenfinger",
    main_category: 8,
  },
  {
    brand_kor: "골드바",
    brand_eng: "GOLD BAR",
    main_category: 8,
  },
  {
    brand_kor: "고디바",
    brand_eng: "GODIVA",
    main_category: 8,
  },
  {
    brand_kor: "지앤씨",
    brand_eng: "GNC",
    main_category: 8,
  },
  {
    brand_kor: "글렌모레이",
    brand_eng: "Glen Moray",
    main_category: 8,
  },
  {
    brand_kor: "기라델리",
    brand_eng: "Ghirardelli",
    main_category: 8,
  },
  {
    brand_kor: "간만세",
    brand_eng: "ganmanse",
    main_category: 8,
  },
  {
    brand_kor: "드롭스",
    brand_eng: "DRUNK ELEPHANT",
    main_category: 8,
  },
  {
    brand_kor: "두드림",
    brand_eng: "Drinae",
    main_category: 8,
  },
  {
    brand_kor: "닥터프리오",
    brand_eng: "Dr.prio",
    main_category: 8,
  },
  {
    brand_kor: "닥터 내츄럴",
    brand_eng: "DR.NATURAL",
    main_category: 8,
  },
  {
    brand_kor: "닥터블릿",
    brand_eng: "DR.BLET",
    main_category: 8,
  },
  {
    brand_kor: "도펠헤르츠",
    brand_eng: "Doppelherz",
    main_category: 8,
  },
  {
    brand_kor: "다미래",
    brand_eng: "dmr",
    main_category: 8,
  },
  {
    brand_kor: "드시모네",
    brand_eng: "DeSimone",
    main_category: 8,
  },
  {
    brand_kor: "덴프스",
    brand_eng: "Denps",
    main_category: 8,
  },
  {
    brand_kor: "딥트 3일",
    brand_eng: "Deepte 3 Days",
    main_category: 8,
  },
  {
    brand_kor: "딥트3일",
    brand_eng: "Deepte 3 Days",
    main_category: 8,
  },
  {
    brand_kor: "센트룸",
    brand_eng: "CENTRUM",
    main_category: 8,
  },
];
