
import React, { useState, useEffect, useRef } from "react";
import TextField from "@mui/material/TextField";
import styled from "styled-components";
import BtnImg from "../../resources/lang_change_btn.png";
import { useLocation } from "react-router-dom";

export const BrandList = ({
  data,
  setBrandKeyword,
  typingBrandKeyword,
  handleKeywordSearch,
}) => {
  const location = useLocation();
  const [showKor, setShowKor] = useState(true);
  const [filteredData, setFilteredData] = useState(data);

  useEffect(() => {
    setFilteredData(
      data.filter((item) => {
        if (
          item.brand_kor.includes(typingBrandKeyword) ||
          item.brand_eng.includes(typingBrandKeyword) ||
          item.same_brand?.includes(typingBrandKeyword)
        ) {
          return true;
        } else {
          return false;
        }
      })
    );
  }, [typingBrandKeyword]);

  // 스크롤 관련
  const scrollContainerRef = useRef(null);
  useEffect(() => {
    // 컴포넌트가 DOM에 렌더링된 후에 scrollContainerRef를 초기화합니다.
    scrollContainerRef.current = document.getElementById("brandListContainer");

    // 이전에 저장된 스크롤 위치 가져오기
    const previousScrollPosition = sessionStorage.getItem("scrollPosition");
    if (previousScrollPosition) {
      scrollContainerRef.current.scrollTop = parseInt(
        previousScrollPosition,
        10
      );
    }

    // 컴포넌트가 언마운트 될 때 스크롤 위치 저장
    // return () => {
    //   sessionStorage.setItem(
    //     "scrollPosition",
    //     scrollContainerRef.current.scrollTop
    //   );
    // };
  }, []); // 컴포넌트가 처음 렌더링될 때만 실행되도록 []를 두 번째 매개변수로 전달합니다.

  return (
    <Container>
      {/* <BrandListContainer ref={scrollContainerRef}>
        {showKor
          ? filteredData.map((item) => {
              return (
                <BrandItem
                  onClick={() => {
                    handleKeywordSearch(item.brand_kor);
                  }}
                >
                  {item.brand_kor}
                </BrandItem>
              );
            })
          : filteredData.map((item) => {
              return (
                <BrandItem
                  onClick={() => {
                    handleKeywordSearch(item.brand_kor);
                  }}
                >
                  {item.brand_eng}
                </BrandItem>
              );
            })}
      </BrandListContainer> */}

      <BrandListContainer ref={scrollContainerRef} id="brandListContainer">
        {filteredData.map((item, index) => (
          <BrandItem
            key={index}
            id={`brand-item-${index}`}
            onClick={() => {
              sessionStorage.setItem(
                "scrollPosition",
                scrollContainerRef.current.scrollTop
              );
              handleKeywordSearch(item.brand_kor);
            }}
          >
            {showKor ? item.brand_kor : item.brand_eng}
          </BrandItem>
        ))}
      </BrandListContainer>
      <CharPickerContainer>
        <div
          onClick={() => {
            setShowKor(!showKor);
          }}
        >
          <LangChangeBtn src={BtnImg}></LangChangeBtn>
        </div>
      </CharPickerContainer>
    </Container>
  );
};

const Container = styled.div`
  // max-height: 400px;

  height: 100%;
  width: 100%;

  display: flex;
  flex-direction: row;
`;

// // overflow: hidden; /* 세로, 가로 스크롤 모두 숨김 */
// // overflow-y: auto; /* 컨텐츠가 넘칠 경우 스크롤 추가 */
// overflow-x: hidden; /* 가로 스크롤 숨김 */

// /* ( 크롬, 사파리, 오페라, 엣지 ) 동작 */
// .scroll::-webkit-scrollbar {
//   display: none;
// }

const BrandListContainer = styled.div`
  width: 90%;
  height: 70vh;
  padding-top: 0.5rem;

  overflow-y: scroll;
  overflow-x: hidden; /* 좌우 스크롤 제거 */

  -ms-overflow-style: none; /* 인터넷 익스플로러 */
  scrollbar-width: none; /* 파이어폭스 */
`;

const BrandItem = styled.div`  
  width: 100%;;
  height: 16px;
  margin: 0px 40px 16px 4px;
  font-family: Pretendard;
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: left;
  color: #000;

  margin-bottom: 1rem;

`

const CharPickerContainer = styled.div`
  width: 10%;
`

const LangChangeBtn = styled.img`
  width: 30px;
`