
import React, { useState, useEffect } from 'react';
import { SearchContainer, 
  StyledInput , 
  Layout, 
  Container, 
  SearchButton} from '../search/search.module'
import { client } from "../../client"
import { useNavigate } from "react-router-dom";
import { gql } from "@apollo/client";
import { AutoCompleteBox, AutoCompleteText, AutoCompleteContainer } from '../search/search.module';
import { MostViewdItem, MostViewdItemContainer, ItemImg, ItemName, ItemBrand, 
  ItemPrice, RankStatusContaienr } from './SearchRank.module';


export const MostViewed2 = ({}) => {

    const navigate = useNavigate(); // Initialize useNavigate
    const img = 'https://cdn.hddfs.com/files/goos/0051/20221117/66e09ec4.jpg?sf=webp&RS=212X212'
    const [ itemList, setItemList ]  = useState([
      {
       'img' : 'https://img.ssgdfs.com/upload/C00001/v1/images/product/2000/990/20180718F0002558.jpg?RS=500',
       'brand' : '르라보', 
       'name' : '어나더 13 샤워 젤', 
       'price' : '10$', 
       'ref_code' : 'J03801',
      },
      {
       'img' : 'https://img.ssgdfs.com/upload/C00001/goos/org/478/230116000176478.jpg?RS=500',
       'brand' : 'ADDICT LIP GLOW 001', 
       'name' : 'DIOR', 
       'price' : '10$', 
       'ref_code' : 'C021400001',
      },
      {
       'img' : 'https://static.lottedfs.com/prod/prd-img/56/13/04/01/00/02/20001041356_2.jpg/dims/resize/460x460',
       'brand' : '캉골', 
       'name' : '듀스 Ⅲ 스트링 백팩', 
       'price' : '20$', 
       'ref_code' : 'BP1426ECOS',
      },
      {
       'img' : 'https://cdn.hddfs.com/files/goos/0254/20230309/6490381d.jpg?sf=webp&RS=212X212',
       'brand' : '락드 키스 잉크 24아워 립컬러 M·A·C LOCKED KISS INK™ 24HR LIPCOLOUR #VICIOUS', 
       'name' : 'MAC', 
       'price' : '30$', 
       'ref_code' : 'SRH909',
      }
    ]);

   
    useEffect(() => {

    }, [])

  

  return (
    <div>
      <h3>인기 상품</h3>
      <MostViewdItemContainer>
        {itemList.map((item =>  {
          return <MostViewdItem 
            onClick={(e) => {
              e.preventDefault(); // Prevent the default anchor behavior
              navigate(`/product/${encodeURIComponent(item['ref_code'])}?brand=${encodeURIComponent(item['brand'])}`); // Use navigate to handle navigation
            }}
          >
            <ItemImg src={item['img']}></ItemImg>
            <ItemBrand>{item['brand']}</ItemBrand>
            <ItemName>{item['name']}</ItemName>
            {/* <ItemPrice>{item['price']}</ItemPrice> */}
          </MostViewdItem>
        }))}
      </MostViewdItemContainer>
    </div>
  );
};

