import React, { useState, useEffect } from 'react';
import {
  Layout,
  Container,
  TitleContainer,
  CategoryChip,
  ProductListContainer,
  ProductItemContainer,
  ProductItemImageContainer,
  Underline,
} from '../../style/Home/RecommendSection.module.js';
import { client } from '../../client';
import { GET_RECO_PRODUCT } from './gql/queries.js';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';
import BottomAd from '../ad-banner/BottomAd.js';

const CategoryContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(4, 1fr); /* 3 columns */
  grid-gap: 17px; /* Adjust the gap between items as needed */
  padding-top: 24px;
  padding-bottom: 24px;
  /* Add any additional styles for the container here */
`;

const CategoryItem = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  /* Add any additional styles for the category item here */

  img {
    /* Add styles for the image here */
    max-width: 100%; /* Ensure the image doesn't exceed the container width */
    height: auto;
  }

  p {
    /* Add styles for the text here */
    margin-top: 8px; /* Adjust the spacing between image and text */
    font-family: Pretendard;
    font-size: 14px;
    font-weight: normal;
    font-stretch: normal;
  }
`;

export const MainCategorySection = () => {
  const [selectedCategory, setSelectedCategory] = useState(
    localStorage.getItem('selectedCategory') == null
      ? '향수/바디/헤어'
      : localStorage.getItem('selectedCategory')
  );
  const [itemList, setItemList] = useState([]);

  const navigate = useNavigate();
  const handleClick = (ref_code, brand, price_normal_eng) => {
    navigate(
      `/product/${ref_code}?brand=${brand}&price_normal=${price_normal_eng}`
    );
  };

  const handleClickButton = (categoryName, id) => {
    const propsToPass = {
      categoryName: categoryName,
      id: id,
      // Add any other props you want to pass
    };
    navigate(`/mainCategoryFilter?categoryName=${categoryName}&id=${id}`, {
      state: propsToPass,
    });
  };

  useEffect(() => {}, []);

  const categoryList = [
    {
      id: 4,
      categoryName: '주류',
      imgSrc:
        'https://dfx-img.s3.ap-northeast-2.amazonaws.com/%E1%84%80%E1%85%B3%E1%84%85%E1%85%AE%E1%86%B8+63821%403x.png',
    },
    {
      id: 1,
      categoryName: '스킨케어',
      imgSrc:
        'https://dfx-img.s3.ap-northeast-2.amazonaws.com/%E1%84%80%E1%85%B3%E1%84%85%E1%85%AE%E1%86%B8+63818%403x.png',
    },
    {
      id: 2,
      categoryName: '메이크업',
      imgSrc:
        'https://dfx-img.s3.ap-northeast-2.amazonaws.com/%E1%84%80%E1%85%B3%E1%84%85%E1%85%AE%E1%86%B8+63819%403x.png',
    },
    {
      id: 3,
      categoryName: '향수/헤어',
      imgSrc:
        'https://dfx-img.s3.ap-northeast-2.amazonaws.com/%E1%84%80%E1%85%B3%E1%84%85%E1%85%AE%E1%86%B8+63820%403x.png',
    },
    {
      id: 5,
      categoryName: '가방/지갑',
      imgSrc:
        'https://dfx-img.s3.ap-northeast-2.amazonaws.com/%E1%84%80%E1%85%B3%E1%84%85%E1%85%AE%E1%86%B8+63822%403x.png',
    },
    {
      id: 6,
      categoryName: '시계/쥬얼리',
      imgSrc:
        'https://dfx-img.s3.ap-northeast-2.amazonaws.com/%E1%84%80%E1%85%B3%E1%84%85%E1%85%AE%E1%86%B8+63823%403x.png',
    },
    {
      id: 7,
      categoryName: '패션/잡화',
      imgSrc:
        'https://dfx-img.s3.ap-northeast-2.amazonaws.com/%E1%84%80%E1%85%B3%E1%84%85%E1%85%AE%E1%86%B8+63824%403x.png',
    },
    {
      id: 8,
      categoryName: '건강/식품',
      imgSrc:
        'https://dfx-img.s3.ap-northeast-2.amazonaws.com/%E1%84%80%E1%85%B3%E1%84%85%E1%85%AE%E1%86%B8+63825%403x.png',
    },
  ];

  return (
    <Layout>
      <Container>
        {/* <StyledInput type="text" placeholder="상품을 검색해주세요" /> */}
        <Underline></Underline>
        <TitleContainer> 카테고리/브랜드 </TitleContainer>

        {/* 카테고리 8개  / 3*4*/}
        <CategoryContainer>
          {/* 카테고리 아이템 (이미지 / 글) */}
          {categoryList.map((item) => {
            return (
              <CategoryItem
                onClick={() => handleClickButton(item.categoryName, item.id)}
              >
                <img src={item.imgSrc}></img>
                <p>{item.categoryName}</p>
              </CategoryItem>
            );
          })}
        </CategoryContainer>
      </Container>
    </Layout>
  );
};
