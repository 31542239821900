import React, { useState, useEffect } from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import { TopMenu } from "../component/_molecule/TopMenu";
import { HomeContainer, HomeInnerContainer } from "../style/Home/Home.module";
import { TabDisplayLayout } from "../component/coupon/TabDisplayLayout";
import { Layout, Container } from "../style/Home/RecommendSection.module";
import { GET_RECO_PRODUCT } from "../component/home/gql/queries";
import { BrandList } from "../component/category-filter/BrandList";
import { brandListData } from "../component/category-filter/mockData";
import { InputComponent } from "../component/category-filter/InputComponent";
import { useLocation, useParams, useNavigate } from "react-router-dom";
import Slider from "../component/category-filter/Slider";
import BottomAd from "../component/ad-banner/BottomAd";
import { Footer } from "../component/common/Footer";

import {
  PriceButton,
  FilterBrandButton,
  FilterButton,
  InputLayout,
  PriceInputStyled,
  BrandContainer,
} from "../style/Home/MainCategoruFilter.module";
import { CategoryAdv } from "../component/home/Adv/CategoryAdv";

export const MainCategoryFilter = () => {
  const location = useLocation();
  const navigate = useNavigate();

  const categoryName = location.state.categoryName;
  const categoryId = location.state.id;

  const [brandList, setBrandList] = useState(
    brandListData
      .filter((item) => {
        const params = new URLSearchParams(location.search);
        const id = params.get("id");

        if (item["main_category"] == id) return true;
        else return false;
      })
      .sort(function (a, b) {
        const brandA = a.brand_kor.toUpperCase(); // 대소문자 구분 없이 정렬하기 위해 대문자로 변환
        const brandB = b.brand_kor.toUpperCase(); // 대소문자 구분 없이 정렬하기 위해 대문자로 변환
        if (brandA < brandB) {
          return -1;
        }
        if (brandA > brandB) {
          return 1;
        }
        return 0;
      })
  );

  const [brandKeyword, setBrandKeyword] = useState("");
  const [typingBrandKeyword, setTypingBrandKeyword] = useState("");

  const handleKeywordSearch = (keyword) => {
    navigate(
      `/search?keyword=${encodeURIComponent(keyword)}&minPrice=${
        priceRange.min
      }&maxPrice=${priceRange.max}&categoryId=${categoryId.toString()}`
    ); // 클릭 시 "/search?keyword=제냐" 경로로 이동합니다.
  };

  const [showList, setShowList] = useState(false);
  const [showBrandList, setShowBrandList] = useState(true);

  const [value, setValue] = useState([1, 5000]);
  const [priceRange, setPriceRange] = useState({ min: 0, max: 5000 });
  const [tempPriceRange, setTempPriceRange] = useState({ min: 0, max: 5000 });

  // 토글

  const toggleBrand = () => {
    if (!showBrandList) {
      setShowBrandList(true);
    }
    setShowList(false);
  };

  const toggleShow = () => {
    if (!showList) {
      setShowList(true);
    }
    setShowBrandList(false);
  };

  // 가격 설정

  const handleSliderChange = (event, newValue) => {
    setValue(newValue);
    setTempPriceRange({ min: newValue[0], max: newValue[1] });
  };

  const handleInputChange = (name) => (event) => {
    const { value: newValue } = event.target;
    const newRange = { ...priceRange, [name]: Number(newValue) };
    setTempPriceRange(newRange);
    setValue([newRange.min, newRange.max]);
  };

  const handlePriceClick = () => {
    setPriceRange(tempPriceRange);
    toggleBrand();
  };

  // 뒤로가기 위함
  useEffect(() => {
    // 컴포넌트가 언마운트될 때 실행될 함수
    const handleUnload = () => {
      // sessionStorage.setItem("lastVisitedURL", window.location.pathname);
      sessionStorage.setItem("categoryName", categoryName);
    };

    // 컴포넌트가 언마운트될 때만 이벤트 리스너 등록
    window.addEventListener("beforeunload", handleUnload);

    // 컴포넌트가 언마운트될 때 이벤트 리스너 제거
    return () => {
      window.removeEventListener("beforeunload", handleUnload);
    };
  }, []); // 빈 배열을 전달하여 컴포넌트가 마운트될 때 한 번만 실행되도록 설정

  // Scroll to top when navigating from the root path
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <HomeContainer>
      <HomeInnerContainer>
        <TopMenu title={categoryName}></TopMenu>

        <Layout>
          <Container>
            <BrandContainer>
              <FilterBrandButton
                showBrandList={showBrandList}
                onClick={toggleBrand}
              >
                브랜드
              </FilterBrandButton>

              <FilterButton showList={showList} onClick={toggleShow}>
                가격대
              </FilterButton>
            </BrandContainer>

            {showBrandList && (
              <div>
                <InputLayout>
                  <InputComponent
                    typingBrandKeyword={typingBrandKeyword}
                    setTypingBrandKeyword={setTypingBrandKeyword}
                  >
                    인풋
                  </InputComponent>
                </InputLayout>

                <CategoryAdv categoryName={categoryName} />

                <BrandList
                  data={brandList}
                  typingBrandKeyword={typingBrandKeyword}
                  setBrandKeyword={setBrandKeyword}
                  handleKeywordSearch={handleKeywordSearch}
                ></BrandList>
              </div>
            )}

            {/* 모듈화 필요  */}
            {showList && (
              <div>
                <div style={{ position: "relative", minHeight: "400px" }}>
                  <Slider
                    name="Price"
                    value={value}
                    onChange={handleSliderChange}
                    min={0}
                    max={1000}
                    valueLabelFormat={(value) => `$${value}`}
                  />
                  <PriceInputStyled>
                    <input
                      type="number"
                      value={tempPriceRange.min}
                      onChange={handleInputChange("min")}
                    />
                  </PriceInputStyled>
                  &nbsp; ~ &nbsp;
                  <PriceInputStyled>
                    <input
                      type="number"
                      value={tempPriceRange.max}
                      onChange={handleInputChange("max")}
                    />
                  </PriceInputStyled>
                  <PriceButton onClick={handlePriceClick}>적용</PriceButton>
                </div>
              </div>
            )}
          </Container>
        </Layout>
      </HomeInnerContainer>
      <BottomAd></BottomAd>
      <Footer></Footer>
    </HomeContainer>
  );
};
