import React from 'react';

const ProgressBar = ({ label, value, maxValue, color, description }) => {
  const percentage = (value / maxValue) * 100;

  return (
    <div className="flex justify-between font-normal items-center mt-[14px]">
      <div className="w-[22%] text-left">{label}</div>

      <div className="w-[48%]">
        <div className="relative w-full h-[6px] bg-gray-200 rounded-full overflow-hidden">
          <div
            className="absolute h-full rounded-full"
            style={{
              width: `${percentage}%`,
              background: color,
            }}
          ></div>
        </div>
      </div>
      <div className="w-[30%] text-right">
        <span>{description}</span>
        <span className="font-bold text-black"> {Math.round(percentage)}%</span>
      </div>
    </div>
  );
};

export default ProgressBar;
