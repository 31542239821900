import React, { useState, useEffect } from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import { TopMenu } from "../component/_molecule/TopMenu";
import { HomeContainer, HomeInnerContainer } from "../style/Home/Home.module";
import { ProductSearchInput } from "../component/search/ProductSearchInput";
import {
  Layout,
  Container,
} from "../../src/style/Home/AirportCouponSection.module";
import { gql } from "@apollo/client";
import { useParams, useLocation } from "react-router-dom";
import { client } from "../client";
import { ProductDetailContainer } from "../component/product-detail/ProductDetailContainer";
import ReactGA from "react-ga4";
import styled from "styled-components";
import BottomAd from "../component/ad-banner/BottomAd";

export const ProductDetail = () => {
  const { productId } = useParams();
  const location = useLocation();
  const searchKeyword = new URLSearchParams(location.search).get(
    "searchKeyword"
  ); // Extract query parameter
  const brand = new URLSearchParams(location.search).get("brand"); // Extract query parameter
  const priceNormal = new URLSearchParams(location.search).get("price_normal"); // Extract query parameter

  const GET_PRODUCT = gql`
    query getProductDetailPage(
      $product_refcode: String!
      $brand: String
      $price_normal_eng: String
    ) {
      getProductDetailPage(
        product_refcode: $product_refcode
        brand: $brand
        price_normal_eng: $price_normal_eng
      ) {
        name

        brand
        ref_code
        ref_code_2
        img_url
        img_resized_url
        product_price {
          duty_free_site
          price_normal_eng
          price_member_eng
          is_soldout
          img_url
          img_resized_url
          price_member_KRW
          detail_page_url
        }
      }
    }
  `;
  // detail_page_url

  const [data, setData] = useState(null);

  useEffect(() => {
    console.log("productId...", productId);

    client
      .query({
        query: GET_PRODUCT,
        variables: {
          product_refcode: productId,
          brand: brand,
          price_normal_eng: priceNormal,
        },
        fetchPolicy: "no-cache",
      })
      .then((result) => {
        console.log("GET_PRODUCT...", result.data?.getProductDetailPage);
        setData(result.data?.getProductDetailPage);
      })
      .catch((err) => {
        console.log(err);
      });

    ReactGA.send({
      hitType: "pageview",
      page: "/product",
      productId: productId,
    });
  }, []);

  return (
    <HomeContainer>
      <HomeInnerContainer>
        <Layout>
          <Container>
            <TopMenu title="상품 상세" itemName={searchKeyword}></TopMenu>
            <ProductDetailContainer data={data}></ProductDetailContainer>
          </Container>
        </Layout>
      </HomeInnerContainer>
      <FooterLayout>
        <ContentTitle>안내사항</ContentTitle>
        <ContentItem>
          <div>- 구매를 위해서 온라인 면세점 로그인을 해주세요.</div>
          <div>- 당사가 제공하는 가격 정보는 온라인 면세점 회원가격이에요.</div>
          <div>
            - 각 면세덤 별 적립금 가격이 상이할 수 있으니 가격 확인 후, 구매결정
            &nbsp;&nbsp;부탁드려요.
          </div>
          <div>
            - 당사는 상품판매와 직접 관련이 없으며 상품주문, 배송 및 환불 등
          </div>
          <div>
            &nbsp;&nbsp;거래에 대한 일체의 의무와 책임은 해당 온라인 면세점에
            있음을 알려드
          </div>
          <div>&nbsp;&nbsp;려요.</div>
        </ContentItem>
        <CopyrightItem>
          © Copyright 2023 Duty Free Price Research. All Rights Reserved.
        </CopyrightItem>
      </FooterLayout>
    </HomeContainer>
  );
};

export const FooterLayout = styled.div`
  // height: 206px;
  // margin: 54px 0 0;
  background-color: #f2f2f2;
  padding-top: 30px;
  padding-bottom: 30px;
  padding-left: 30px;
  padding-right: 30px;
  width: 300px;

  display: flex;
  flex-direction: column;
`;

export const ContentTitle = styled.div`
  font-family: Pretendard;
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.69;
  letter-spacing: -0.13px;
  text-align: left;
  color: #777;
  font-weight: bold;
  margin-bottom: 10px;
`;

export const ContentItem = styled.div`
  font-family: Pretendard;
  font-size: 13px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.69;
  letter-spacing: -0.13px;
  text-align: left;
  color: #777;
`;

export const CopyrightItem = styled.div`
  font-family: Pretendard;
  font-size: 12px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.83;
  letter-spacing: -0.12px;
  text-align: left;
  color: #777;

  margin-top: 19px;
`;

export const AddContainer = styled.div`
  display: flex;
  flex-direction: column;
  // justify-content: center;
  // left: -30px;
`;
