import React, { useState, useEffect } from "react";
import { Button } from "@mui/material";
import { jwtDecode } from "jwt-decode";
import {
  HomeContainer,
  HomeInnerContainer,
  ProductItemContainer,
  ProductItemInfo,
} from "../style/Home/Home.module";
import { TopMenu } from "../component/home/TopMenu";
import { HomeBanner } from "../component/home/HomeBanner";
import { BottomBanner } from "../component/common/BottomBanner";
import { SearchInput } from "../component/home/SearchInput";
import { RecommendSection } from "../component/home/RecommendSection";
import styled from "styled-components";
import { MainCategorySection } from "../component/home/MainCategorySection";
import { TopBanner } from "../component/home/TopBanner";
import { MainAdv } from "../component/home/Adv/MainAdv";
import { gql } from "@apollo/client";
import BottomAd from "../component/ad-banner/BottomAd";
import { Footer } from "../component/common/Footer";
import { useNavigate } from "react-router-dom";
import { SubAdv } from "../component/home/Adv/SubAdv";

export const HomeV4 = () => {
  const CenterContainer = styled.div`
    display: flex;
    flex-direction: column;
    position: relative;
  `;
  const AiaFloatingButtonContainer = styled.div`
    position: fixed;
    bottom: 65px;
    right: 50%; /* 중앙 기준으로 우측 끝에 위치 */
    transform: translateX(190px); /* 우측으로 50px 이동 */

    color: #fff;
    padding: 10px 20px;
    border-radius: 80%;
    cursor: pointer;
    z-index: 1000;
    transition: background-color 0.3s ease, transform 0.3s ease;
  `;
  const FloatingButtonContainer = styled.div`
    position: fixed;
    bottom: 5px;
    right: 50%; /* 중앙 기준으로 우측 끝에 위치 */
    transform: translateX(190px); /* 우측으로 50px 이동 */

    color: #fff;
    padding: 10px 20px;
    border-radius: 80%;
    cursor: pointer;
    z-index: 1000;
    transition: background-color 0.3s ease, transform 0.3s ease;
  `;

  const AdContainer = styled.div`
    background-color: white;
    box-shadow: rgba(0, 0, 0, 0.25) 0px 25px 50px -12px;

    @media (max-width: 400px) {
      min-width: 360px; /* 400px 미만인 경우 */
      width: 100%; /* 400px 이상인 경우 */
    }
  `;

  // 디코딩해서 id를 확인합니다
  let isTest = false;
  try {
    const decodedToken = jwtDecode(localStorage.getItem("accessToken"));
    isTest = decodedToken.id === 251 || decodedToken.id === 277;
  } catch (error) {
    console.error("토큰 디코딩 오류:", error);
  }

  const navigate = useNavigate();
  useEffect(() => {
    // Session Storage에 companyId가 있는지 확인하여 리다이렉트
    const companyId = sessionStorage.getItem("companyId");

    if (companyId && companyId != 1) {
      navigate("/whisky365?companyId=" + companyId);
    }
  }, []);

  // AiaFloatingButtonContainer 클릭시 로그인 상태면 /aiappCoupon, 로그아웃 상태면 /login으로 이동
  const onClickAiaFloatingButton = () => {
    // 로그인 상태면 (accessToken 으로 체크한다.)
    const accessToken = localStorage.getItem("accessToken");

    if (accessToken) {
      alert("쿠폰 페이지로 이동합니다.");
      navigate("/eventCoupon");
    } else {
      // alert("로그인 후 이용해주세요.");
      navigate("/event1");
    }
  };

  useEffect(() => {
    const generateSessionId = () => {
      const characters = "0123456789";

      return Array.from(
        { length: 5 },
        () => characters[Math.floor(Math.random() * characters.length)]
      ).join("");
    };

    const getSessionId = () => {
      let sessionId = sessionStorage.getItem("sessionId");

      if (!sessionId) {
        sessionId = generateSessionId();
        sessionStorage.setItem("sessionId", sessionId);
      }

      return sessionId;
    };

    getSessionId();
  }, []);

  return (
    <HomeContainer>
      <CenterContainer>
        <HomeInnerContainer>
          <TopMenu></TopMenu>
          <HomeBanner> </HomeBanner>
          <SearchInput></SearchInput>
          {isTest ? (
            <a
              href="https://busandf.com/member_process/niceid_phone_return?intro=Y&return_url=/goods/view?no=31760&EncodeData=AgAFQ0U1MzNMhIdj5i4fIhIpxqB4a6g4j80%2BW1FKMjrS6MpPBlEzszdLdEGgsiyVXT9QK/YupH3vRkT2GEOGf4F0vdyi5obvrX2%2BaEHTDnbn7o2lmsvF6EJ13R2u6UHeCEp87Yv0WJ45xaBuW%2BXCV%2B6FEDiMvWEBqdBRXDzitgbvCzM0hXevtQdivgmBODtVa%2BxBZTy3KZE3w5zljaZMA/uRXEmKDSnv2yfAJIAMwse%2Br6RuScD6dXID7wY3keu9nWiuRAgjAqaKgN1NAnr0ZPSm5iY9Kc%2Brs%2BgH78yXvxC14/4c%2BhgobP%2BDvyRPbqCcUIpDPCs6uRhuxR67Tj2dtC1RykXfpAaiE1dhPwz0Mj9JCo7VdrJ4FCC6KbYxwt3xGuJVCadBIdF38CEo3abSq9cfgezXLxhNHQkU7nPBk2ebe6K/dl0JeE0k0os5FfSl6A19sAetd5frJvotldtwMO/sIC1tiIuCU3zGVO0F5ls77XhGtICNGnuxiuI%2BTxckW2ZFqZuibRZTLmeX4U1gfeah34U37pJpuIPgsYvNUiTDRIHII2fi4FQSaU2uwk9Gy0NtcohJHwqWcoa5ML428SlbH7CpPuvQ"
              style={{
                cursor: "pointer",
                color: "black",
                backgroundColor: "white",
              }}
            >
              <Button
                variant="primary"
                style={{
                  backgroundColor: "blue",
                  borderColor: "blue",
                  color: "white",
                }}
              >
                이동 가능한 링크
              </Button>
            </a>
          ) : null}

          <MainAdv></MainAdv>
          <MainCategorySection></MainCategorySection>

          <SubAdv></SubAdv>
          <RecommendSection></RecommendSection>
          {/* <DutyFreeCouponSection></DutyFreeCouponSection> */}
          {/* <AirportCouponSection></AirportCouponSection> */}
          {/* <PostSection></PostSection> */}
          <BottomBanner></BottomBanner>
        </HomeInnerContainer>
        <AdContainer>
          <BottomAd></BottomAd>
        </AdContainer>
        <Footer></Footer>
        <AiaFloatingButtonContainer onClick={() => onClickAiaFloatingButton()}>
          <img
            src={"https://dfx-img.s3.ap-northeast-2.amazonaws.com/aia2.png"}
            alt="로고 이미지"
            style={{ width: "50px", height: "50px", height: "auto" }}
          />
        </AiaFloatingButtonContainer>
        <FloatingButtonContainer>
          <a href="http://pf.kakao.com/_RjKPG">
            <img
              src={
                "https://dfx-img.s3.ap-northeast-2.amazonaws.com/kakaodfp.png"
              }
              alt="로고 이미지"
              style={{ width: "50px", height: "50px", height: "auto" }}
            />
          </a>
        </FloatingButtonContainer>
      </CenterContainer>
    </HomeContainer>
  );
};
