import styled from "styled-components";


export const MenuItemUnderlined = styled.div` 
  width: 60%;
  background-color: #fff;
  display: flex;
  padding-left: 60px;
  border-bottom: 1.5px solid #00000050; /* Adjust the color and thickness as needed */
  margin-bottom: 24px; /* Adjust the spacing between text and underline */
  margin-bottom: 44px;
`
export const MenuItem = styled.div` 
  width: 100%;
  height: 40px;
  background-color: #fff;
  display: flex;
  padding-left: 60px;
  align-items: center;
`


export const MenuContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 280px;
  padding-top: 50px;
  // margin-bottom: 50px;
`;
export const SnsContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center; /* 수평 정렬을 위한 중앙 정렬 */
  align-items: center; /* 수직 정렬을 위한 중앙 정렬 */
  gap: 5%; /* 간격을 조절하세요 */
  padding-left: 10px;
  padding-right: 10px;

  height: 10%;
  width: 100%;
`;


export const Footer = styled.div`
  background-color: #f2f2f2;
    display: flex;
    flex-direction: column;
    width: 280px;
    padding-left : 30px;
    
`

