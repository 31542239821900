import styled from "styled-components";

export const Layout = styled.div`
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
`

export const Container = styled.div`
    display: flex;
    flex-direction: column;
    // justify-content: space-between;
    width: 360px;
`

export const AutoCompleteContainer = styled.div`
    display: flex;
    flex-direction: column;
    // justify-content: space-between;
    width: 360px;
    min-height: 500px;
`

export const StyledInput = styled.input`  
    width: 330px;
    height: 42px;
    border-radius: 21px;
    background-color: #f5f5f5;
    border: none; /* or border: 0; */


    padding-left: 1rem;
    box-sizing: border-box; /* Add this line */
`

export const SearchButton = styled.div`
    background-image: url( ${props => props.url} );
    background-size: cover;
    width : 24px;
    height : 24px;
    margin-left: 1rem;
    margin-right: 1rem;

    position: absolute;
    right: 1.5rem; /* Adjust the right positioning as needed */
    top: 50%; /* Center vertically */
    transform: translateY(-80%); /* Center vertically */

`

export const SearchContainer = styled.div`
    display: flex; 
    flex-direction: row;
    justify-content: center;
    position: relative; 

    padding-bottom: 1rem;
    box-sizing: border-box; /* Add this line */
    border-bottom: 1px solid #e5e5e5; 

    // position: fixed;
    // margin-top: 8rem;
    // position: fixed; /* Fixed position */
    // top: 0; /* Stick to the top of the screen */
`

export const AutoCompleteBox = styled.div`
    display: flex; 
    flex-direction: row;
    margin-left: 1rem;
    margin-top: 0.7rem;  
    align-items: center; /* 수직 가운데 정렬 */
    
    font-family: Pretendard;
    font-size: 14px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;


`


export const AutoCompleteProductListTitle = styled.div`
    margin-top: 0px; 
    margin-left: 18px; 
    font-family: Pretendard;
    font-weight: bold;
    font-size: 16px;

`

export const AutoCompleteText = styled.div`
    margin-left: 11px;

`

export const ProductItemContainer = styled.div`
    margin-left: 11px;
    width: 45%;

`

export const ProductItemBrand = styled.div`  
    font-family: Pretendard;
    font-size: 15px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    margin-top: 3px;

`


export const ProductItemRefCode = styled.div`    
    font-family: Pretendard;
    font-size: 14px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #444;
    margin-top: 3px;

`

export const ProductItemImg = styled.div`

    border-radius: 6px;
    border: solid 1px #ececec;
    margin-bottom: 10px;
    margin-top: 20px;
`
export const ProductItemPrice = styled.div`  
    font-family: Pretendard;
    font-size: 17px;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;

    margin-top: 10px;
`


