
import React, { useState, useEffect } from 'react';
import {
  Layout,
  Container,
  TitleContainer,
  CuoponListContainer,
  CouponItem,
} from "../../style/Home/AirportCouponSection.module.js";
import {
  CategoryContainer,
  CategoryChip,
} from "../../style/Home/RecommendSection.module.js";
// import { CodeCheckModal } from "./CodeCheckModal.js";
import { useNavigate } from "react-router-dom";

import { client } from "../../client.js";
import { GET_AIA_COUPON } from "./gql/queries.js";

const CodeCheckModal = ({ children, closeModal }) => {
  const handleBackgroundClick = (e) => {
    if (e.target === e.currentTarget) {
      closeModal();
    }
  };

  return (
    <div style={modalStyle} onClick={handleBackgroundClick}>
      <div style={modalContentStyle}>{children}</div>
    </div>
  );
};

const modalStyle = {
  position: "fixed",
  top: 0,
  left: 0,
  width: "100%",
  height: "100%",
  backgroundColor: "rgba(0, 0, 0, 0.5)",
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
};

const modalContentStyle = {
  backgroundColor: "#fff",
  padding: "20px",
  borderRadius: "5px",
  textAlign: "center",
};

export const AirportTabLayout = () => {
  const navigate = useNavigate();
  const [selectedCategory, setSelectedCategory] = useState("ALL");
  const [coupons, setCoupons] = useState([]);
  const [filteredCoupons, setFilteredCoupons] = useState([]);
  // 모달 관련
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [inputCode, setInputCode] = useState("");
  const [selectedCoupon, setSelectedCoupon] = useState(null);

  const categories = ["ALL", "공항리무진", "식당"];
  const handleCategorySelect = (category) => {
    setSelectedCategory(category);
  };

  useEffect(() => {
    const fetchCoupons = async () => {
      client
        .query({
          query: GET_AIA_COUPON,
          fetchPolicy: "no-cache",
        })
        .then((result) => {
          setCoupons(result.data.getAIAEventCoupon);
        })
        .catch((err) => {
          console.log("error 발생", err);
        });
    };
    fetchCoupons();
  }, []);

  useEffect(() => {
    filterCoupons(coupons, selectedCategory);
  }, [selectedCategory, coupons]);

  const filterCoupons = (coupons, category) => {
    const categoryFiltered = coupons.filter((coupon) => {
      if (coupon.category === "airport") return true;
      else return false;
    });

    if (category === "ALL") {
      setFilteredCoupons(categoryFiltered);
    } else {
      const typeFilterd = categoryFiltered.filter((coupon) => {
        if (category === "공항리무진") {
          return coupon.type === "limosine";
        } else if (category === "식당") {
          return coupon.type === "dombe" || coupon.type === "cj";
        }
        return false;
      });
      setFilteredCoupons(typeFilterd);
    }
  };

  const handleClick = (coupon) => {
    if (coupon.id === 200) {
      setSelectedCoupon(coupon);
      setIsModalOpen(true);
    } else {
      navigate("/AiaCouponDisplay", { state: { coupon } });
    }
  };

  const handleModalSubmit = () => {
    if (inputCode === "13") {
      navigate("/AiaCouponDisplay", { state: { coupon: selectedCoupon } });
    } else {
      alert("코드가 틀렸습니다. 다시 시도해주세요.");
    }
    setIsModalOpen(false);
    setInputCode("");
  };

  const handleCloseModal = () => {
    setIsModalOpen(false);
    setInputCode("");
  };

  return (
    <Layout>
      <Container>
        <CategoryContainer>
          {categories.map((category) => (
            <CategoryChip
              key={category}
              active={selectedCategory === category}
              onClick={() => handleCategorySelect(category)}
            >
              {category}
            </CategoryChip>
          ))}
        </CategoryContainer>

        <CuoponListContainer>
          {filteredCoupons.map((coupon) => (
            <CouponItem key={coupon.id} onClick={() => handleClick(coupon)}>
              <img src={coupon.imageUrl}></img>
            </CouponItem>
          ))}
        </CuoponListContainer>

        {isModalOpen && (
          <CodeCheckModal closeModal={handleCloseModal}>
            <div>
              <h4>쿠폰코드를 입력해주세요</h4>
              <input
                type="text"
                value={inputCode}
                onChange={(e) => setInputCode(e.target.value)}
              />
              <button onClick={handleModalSubmit}>확인</button>
            </div>
          </CodeCheckModal>
        )}
      </Container>
    </Layout>
  );
};

