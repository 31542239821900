import React, { useState, useEffect } from 'react';
import { SnsShareContainer, BtnSet } from './SnsShare.module.js';
import { SnsShareBtn } from './SnsShareBtn.js';
import { ToastContainer, toast } from 'react-toastify';


export const SnsShare = ({ imgUrl }) => {


    useEffect(() => {


        const script = document.createElement("script");
        script.src = "https://developers.kakao.com/sdk/js/kakao.js";
        script.async = true;
        document.body.appendChild(script);
        return () => document.body.removeChild(script);
      }, []);


    
    // send kakao 
    const sendKakao = () => {
        // alert('카카오 공유')
        if (window.Kakao) {

            if(!window.Kakao.isInitialized()) {
                const kakao = window.Kakao;
                kakao.init("bd6295d7c2426350afac958e9355f2c6");
            }
            const kakao = window.Kakao;
            kakao.Link.sendDefault({
                objectType: "feed", // 카카오 링크 공유 여러 type들 중 feed라는 타입 -> 자세한 건 카카오에서 확인
                content: {
                title: 'DFP', // 인자값으로 받은 title
                description: "할인쿠폰을 받아보세요", // 인자값으로 받은 title
                imageUrl: imgUrl,
                link: {
                    mobileWebUrl: window.location.href, // 인자값으로 받은 route(uri 형태)
                    webUrl: window.location.href
                }
                },
                buttons: [
                  {
                    title: "쿠폰받기",
                    link: {
                      mobileWebUrl: window.location.href,
                      webUrl:window.location.href
                    }
                  }
                ]
            });
        } else {
            console.log('kakao 없음')
        }
    }

      

    // SMS 복사  
    const checkMobile = () => {
        var varUA = navigator.userAgent.toLowerCase(); //userAgent 값 얻기
        if ( varUA.indexOf('android') > -1) {
            //안드로이드
            console.log('and')
            return "android";
        } else if ( varUA.indexOf("iphone") > -1||varUA.indexOf("ipad") > -1||varUA.indexOf("ipod") > -1 ) {
            //IOS
            console.log('ios')
            return "ios";
        } else {
            //아이폰, 안드로이드 외
            return "other";
        }
    }
    const smsMsg = 'sms:' +(checkMobile() == 'ios' ? '&' : '?') + 'body='+ `message;sms:&body=지금 쿠폰을 확인해보세요! ${window.location.href}`

    // 클립보드 복사
    const notify = () => toast("주소 복사 완료")
    const currentUrl = window.location.href;    
    const unsecuredCopyToClipboard = (text) => { const textArea = document.createElement("textarea"); textArea.value=text; document.body.appendChild(textArea); textArea.focus();textArea.select(); try{document.execCommand('copy')}catch(err){console.error('Unable to copy to clipboard',err)}document.body.removeChild(textArea)};

    /**
     * Copies the text passed as param to the system clipboard
     * Check if using HTTPS and navigator.clipboard is available
     * Then uses standard clipboard API, otherwise uses fallback
    */
    const copyToClipboard = (content) => {
        notify()
        if (window.isSecureContext && navigator.clipboard) {
            navigator.clipboard.writeText(currentUrl);
        } else {
            unsecuredCopyToClipboard(currentUrl);
        }
    };

  return (
    <SnsShareContainer>
        <div onClick={sendKakao}>
            <SnsShareBtn type={'KAKAO'} ></SnsShareBtn>
        </div>
        <a href={smsMsg} ><SnsShareBtn type={'SMS'}></SnsShareBtn></a>
        <div  onClick={copyToClipboard} >
            <SnsShareBtn type={'URL'}></SnsShareBtn>  
        </div>
        <ToastContainer
            position="top-center"
            autoClose={300}
            hideProgressBar
            newestOnTop={false}
            closeOnClick
            rtl={false}
            pauseOnFocusLoss
            draggable
            pauseOnHover
            theme="dark"
            />
    </SnsShareContainer>
  );
}

