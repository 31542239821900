import styled from "styled-components";
import MuiSlider from "@mui/material/Slider";
import { styled as muiStyled } from "@mui/material";
import { useState } from "react";

const Slider = ({ name, value, onChange, min, max, valueLabelFormat }) => {
  return (
    <SliderContatiner>
      <PriceSlider
        sx={{ width: "80%" }}
        getAriaLabel={() => `${name} range`}
        valueLabelFormat={valueLabelFormat}
        step={50}
        min={min}
        max={max}
        value={value}
        onChange={onChange}
        valueLabelDisplay="on"
      />
    </SliderContatiner>
  );
};

const SliderContatiner = styled.div`
  position: relative;
  height: 100px;
  background-color: #efefef;
  justify-content: center;
  display: flex;
  border-radius: 10px;
  margin-bottom: 20px;
`;

const PriceSlider = muiStyled(MuiSlider)(() => ({
  position: "absolute",
  bottom: "25%",
  color: "black",
  "& .MuiSlider-thumb": {
    color: "#fff",
  },
  "& .MuiSlider-track": {
    color: "#703eff",
  },
  "& .MuiSlider-rail": {},
  "& .MuiSlider-valueLabel": {
    color: "#000",
    fontSize: "15px",
    fontWeight: "normal",
    top: "-5px",
    backgroundColor: "unset",
  },
}));

export default Slider;
