
import React, { useState, useEffect } from 'react';
import { useQuery } from '@apollo/client';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper } from '@mui/material';
import { CouponAdminAddButton } from './CouponAdminAddButton';


import { gql } from "@apollo/client";
import { client } from "../../../client"

// GraphQL 쿼리
const GET_COUPONS = gql`
query getCoupons($page:Int) {
    getCoupons(page:$page) {
    id
    title
    category
    location
    description
    main_image_url
      
    }
    
  }
`;
const DELETE_COUPON = gql`
mutation deleteCoupon($couponId:String) {
    deleteCoupon(couponId:$couponId) {
          is_success
      message
    }
  }
`;
const handleDeleteButton = (couponId) => {
    // name, category, location, image 변수를 이용하여 GraphQL mutation을 수행하여 데이터를 추가할 수 있음
    // 추가하는 로직을 작성하세요.
    client
    .mutate({
        mutation: DELETE_COUPON,
        variables: {
            "couponId": couponId
        }
    })
    .then((result) => {
        console.log(result)
        alert(JSON.stringify(result))
        window.location.reload(false);
    })
    .catch((error) => {
        console.log(error);
    });

}

export const CouponAdminSection = () => {

    useEffect(() => {
        client
        .query({
            query: GET_COUPONS,
            variables: {
                page:1
            }
        })
        .then((result) => {
            console.log(result)
            console.log(result.data.getCoupons)
            setCoupons(result.data.getCoupons)
        })
        .catch((error) => {
            console.log(error);
            alert(error.message);
        });

        
    }, [])

    const [ coupons, setCoupons]  = useState([])
    

  
    return (
        <div style={{margin: '2rem'}}>
            <CouponAdminAddButton></CouponAdminAddButton>
            <h3> 쿠폰리스트 </h3>

            <Table>
                <TableHead>
                <TableRow>
                    <TableCell><h3>id</h3></TableCell>
                    <TableCell><h3>이름</h3></TableCell>
                    <TableCell><h3>분류</h3></TableCell>
                    <TableCell><h3>장소</h3></TableCell>
                    <TableCell><h3>이미지</h3></TableCell>
                </TableRow>
                </TableHead>
                <TableBody>
                {coupons.map((coupon, index) => (
                    <TableRow key={index}>
                    <TableCell>{coupon.id}</TableCell>
                    <TableCell>{coupon.title}</TableCell>
                    <TableCell>{coupon.category}</TableCell>
                    <TableCell>{coupon.location}</TableCell>
                    <TableCell>
                        <img
                            src={coupon.main_image_url}
                            style={{ width: '100px', height: '100px' }} // 너비와 높이를 원하는 크기로 조정
                            alt={`Coupon Image for ${coupon.title.slice(0,5)}`}
                        />
                    </TableCell>
                    <TableCell>
                        <Button variant="contained" color="grey" onClick={() => handleDeleteButton(coupon.id)}>
                            삭제
                        </Button>
                    </TableCell>
                    </TableRow>
                ))}
                </TableBody>
            </Table>
            {/* <ul>
                {coupons.map((coupon, index) => (
                <li key={index}>{coupon.title}
                
                </li>
                ))}
            </ul> */}
        </div>
    );
}

