import React, { useState, useEffect } from "react";
import { useLocation } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import styled from "styled-components";
import { jwtDecode } from "jwt-decode"; // 여기를 수정했습니다.
import { gql } from "@apollo/client";
import { client } from "../../client";

const GET_ISSUED_COUPON = gql`
  query GetIssuedEventCoupon($eventCouponId: Int!) {
    getIssuedEventCoupon(event_coupon_id: $eventCouponId)
  }
`;

const ISSUE_COUPON = gql`
  mutation issueEventCoupon($event_coupon_id: Int!) {
    issueEventCoupon(event_coupon_id: $event_coupon_id)
  }
`;

const LimoCouponContainer = styled.div`
  position: relative;
`;

const LimoButton = styled.button`
  padding: 10px 20px;
  background: #28a74500;
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  width: 200px;
  height: 60px;

  position: absolute;
  top: 53%;
  left: 50%;
  transform: translate(-50%, -50%);

  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
`;

const LimoCouponNumberDiv = styled.div`
  position: absolute;
  top: 46%;
  left: 50%;
  transform: translate(-50%, -50%);

  width: 200px;
  background: #f8f9fa00;
  border-radius: 5px;

  font-size: 1rem;

  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
`;


const ShareButton = styled.button`
  position: fixed;
  bottom: 20px;
  right: 20px;
  background: transparent;
  border: none;
  cursor: pointer;
`;

export const AiaCouponDisplay = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const coupon = location.state?.coupon;

  const [couponData, setCouponData] = useState(coupon);
  const [isLimoCoupon, setIsLimoCoupon] = useState(false);
  const [limoCouponNumber, setLimoCouponNumber] = useState(null);
  const [userData, setUserData] = useState(null);
  const [loading, setLoading] = useState(true); // New loading state

  useEffect(() => {
    console.log(coupon);

    // 쿠폰 식별
    if (coupon.id == 9) {
      setIsLimoCoupon(true);
    }

    // 유저 식별
    const token = localStorage.getItem("accessToken");
    if (token) {
      const decodedToken = jwtDecode(token);
      setUserData(decodedToken);
    }
  }, [couponData]);

  useEffect(() => {
    // 유저가 쿠폰을 발급했는지 확인
    const fetchCoupon = async () => {
      setLoading(true); // Set loading to true before fetching
      try {
        const result = await client.query({
          query: GET_ISSUED_COUPON,
          fetchPolicy: "no-cache",
          variables: {
            eventCouponId: 1,
          },
        });
        console.log(result.data.getIssuedEventCoupon);
        if (result.data.getIssuedEventCoupon !== "발급된 쿠폰이 없습니다.") {
          setLimoCouponNumber(result.data.getIssuedEventCoupon);
        }
      } catch (error) {
        console.log(error);
      } finally {
        setLoading(false); // Set loading to false after fetching
      }
    };
    fetchCoupon();
  }, []);

  // {"id":1,"name":"AIAPP 쿠폰_스카이샵_10,000원","category":"dutyfree","type":"skyshop","imageUrl":"https://dfx-img.s3.ap-northeast-2.amazonaws.com/thumb_sky_10000.png","imageDetailUrl":"https://dfx-img.s3.ap-northeast-2.amazonaws.com/sky_10000.png","__typename":"AIAEventCoupon"}
  const handleIssueCoupon = () => {
    if (limoCouponNumber) {
      alert("이미 발급됐습니다.");
      return;
    }
    client
      .mutate({
        mutation: ISSUE_COUPON,
        variables: {
          event_coupon_id: 1,
        },
      })
      .then((result) => {
        console.log(result.data);
        alert("쿠폰이 발급됐습니다.");
        setLimoCouponNumber(result.data.issueEventCoupon);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  // SNS share
  useEffect(() => {
    const script = document.createElement("script");
    script.src = "https://developers.kakao.com/sdk/js/kakao.js";
    script.async = true;
    document.body.appendChild(script);
    return () => document.body.removeChild(script);
  }, []);

  // send kakao
  const sendKakao = (coupon) => {
    // alert('카카오 공유')
    if (window.Kakao) {
      if (!window.Kakao.isInitialized()) {
        const kakao = window.Kakao;
        kakao.init("bd6295d7c2426350afac958e9355f2c6");
      }
      const kakao = window.Kakao;
      kakao.Link.sendDefault({
        objectType: "feed", // 카카오 링크 공유 여러 type들 중 feed라는 타입 -> 자세한 건 카카오에서 확인
        content: {
          title: "면세점가격비교", // 인자값으로 받은 title
          description:
            "할인쿠폰을 받아보세요. K공항리무진 쿠폰은 AIA code: 13 을 입력 후 다운로드 받으세요", // 인자값으로 받은 title
          imageUrl:
            "https://dfx-img.s3.ap-northeast-2.amazonaws.com/kakao_.png",
          link: {
            mobileWebUrl: "https://dutyfree-price.com/", // 인자값으로 받은 route(uri 형태)
            webUrl: "https://dutyfree-price.com/",
          },
        },
        buttons: [
          {
            title: "쿠폰 받기",
            link: {
              mobileWebUrl: "https://dutyfree-price.com/", // 인자값으로 받은 route(uri 형태)
              webUrl: "https://dutyfree-price.com/",
            },
          },
        ],
      });
    } else {
      console.log("kakao 없음");
    }
  };

  if (loading) {
    return <div>Loading...</div>; // Display a loading message while fetching
  }

  return (
    <div style={{ position: "relative" }}>
      {/* {isLimoCoupon?.toString()}
      {JSON.stringify(userData)} */}
      <button
        onClick={() => navigate(-1)}
        style={{
          position: "absolute",
          top: "10px",
          right: "10px",
          width: "50vw",
          height: "40px",
          padding: "10px 20px",
          // background: "#007bff30",
          background: "#007bff00",
          color: "white",
          border: "none",
          borderRadius: "5px",
          cursor: "pointer",
        }}
      ></button>
      {coupon && isLimoCoupon ? (
        <LimoCouponContainer>
          <img
            src={coupon.imageDetailUrl}
            alt={coupon.name}
            style={{
              display: "block",
              margin: "0 auto",
              maxWidth: "100%",
              height: "auto",
            }}
          />
          <LimoCouponNumberDiv>
            {limoCouponNumber
              ? limoCouponNumber
              : "발급된 쿠폰이 아직 없습니다."}
          </LimoCouponNumberDiv>
          <LimoButton onClick={handleIssueCoupon}></LimoButton>
          <button
            onClick={() => navigate(-1)}
            style={{
              position: "absolute",
              top: "10px",
              right: "10px",
              width: "50vw",
              height: "40px",
              padding: "10px 20px",
              // background: "#007bff30",
              background: "#007bff00",
              color: "white",
              border: "none",
              borderRadius: "5px",
              cursor: "pointer",
            }}
          ></button>
        </LimoCouponContainer>
      ) : (
        <img
          src={coupon.imageDetailUrl}
          alt={coupon.name}
          style={{
            display: "block",
            margin: "0 auto",
            maxWidth: "100%",
            height: "auto",
          }}
        />
      )}
      <ShareButton onClick={() => sendKakao(coupon)}>
        <img
          src="https://dfx-img.s3.ap-northeast-2.amazonaws.com/btn-share%402x.png"
          alt="share"
          style={{ width: "40px" }}
        />
      </ShareButton>
    </div>
  );
};
