
import React, { useState, useEffect } from 'react';
import { client } from "../../client"
import { useNavigate } from "react-router-dom";
import { gql } from "@apollo/client";
import { Container, InputContainer, InputContainerName, InputContainerInput } from './SearchRank.module';
import { TopMenu } from '../_molecule/TopMenu';


export const MostViewd = ({}) => {

  // 10개의 입력값을 관리하는 상태 배열
  const [inputValues, setInputValues] = useState({
    1: "",
    2: "",
    3: "",
    4: "",
    5: "",
    6: "",
    7: "",
    8: "",
    9: "",
    10: "",
  });


  // 버튼 클릭 핸들러
  const handleSubmit = () => {
    // 입력된 값(inputValues)을 사용하여 원하는 작업 수행
    console.log("Input Values:", inputValues);
  };
  

  return (
    <Container> 
      <div> ref code를 입력하세요</div>
      {/* 1위부터 10위까지의 입력란을 반복하여 렌더링 */}
      {[1, 2, 3, 4, 5, 6, 7, 8, 9, 10].map((rank) => (
        <InputContainer key={rank}>
          <InputContainerName> {rank}번 </InputContainerName>
          <InputContainerInput
            type="text"
            placeholder="Enter your name"
            value={inputValues[rank]}
            onChange={(e) => {
              setInputValues({ ...inputValues, [rank]: e.target.value });
            }}
          />
        </InputContainer>
      ))}

      {/* 버튼 추가 */}
      <button onClick={handleSubmit}>Submit</button>
    </Container>
    )
};

