import React, { useState, useEffect } from 'react';
import {
  Layout,
  Container,
  StyledInput,
} from '../../style/Home/SearchInput.module.js';

import { useNavigate } from 'react-router-dom';

export const SearchInput = () => {
  //TODO: 액티브 상태 색상, 돋보기 아이콘
  const [inputValue, setInputValue] = useState('');
  const navigate = useNavigate();

  const handleInputClick = () => {
    // StyledInput 클릭 시 다른 URL로 이동
    navigate('/search');
  };

  return (
    <Layout>
      <Container>
        <StyledInput
          type="text"
          placeholder="14만 개 이상 면세품 할인가를 검색하세요"
          value={inputValue}
          onChange={(e) => setInputValue(e.target.value)}
          onClick={handleInputClick} // 클릭 이벤트 핸들러 추가
          style={{ color: 'black' }} // 텍스트 색상을 빨간색으로 변경
        />
        <img
          src={
            'https://dfx-img.s3.ap-northeast-2.amazonaws.com/btn_search%402x.png'
          }
          style={{
            position: 'absolute',
            top: 10,
            right: 10,
            width: '30px' /* 우측으로 float 시킴 */,
          }}
        ></img>
      </Container>
    </Layout>
  );
};
