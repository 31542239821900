
import React, { useState, useEffect } from 'react';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import { SearchContainer, ProductItemContainer, ProductItemInfo } from '../style/Home/Home.module';
import Autocomplete from '@mui/material/Autocomplete';
import { gql } from "@apollo/client";
import { client } from "../client";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { SignUpInputItemText, 
  SignUpContainer, SignUpInputListContainer, 
  SignUpInputItem} from '../style/Login.module';

  import { useNavigate } from "react-router-dom";


export const News = () => {
  const navigate = useNavigate();


  
    const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");


    return (
            <SignUpContainer> 
              <h1> 뉴스 페이지 </h1> 
            </SignUpContainer>
    );
}

