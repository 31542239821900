import styled, { keyframes } from "styled-components";


export const Container = styled.div`
  margin-left : 20px;
  margin-right : 20px;
  margin-top : 20px;
`


export const InputContainer = styled.div`
  display: flex; 
  flex-direction: row;
  width : 300px;
`

export  const InputContainerName = styled.div`
  width : 30%;

`

export const InputContainerInput = styled.input`
  margin-left: 20px; /* margin-top을 추가하여 위로 여백을 줍니다 */
  /* 다른 스타일을 추가하세요 */
`