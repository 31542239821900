import React, { useState, useEffect } from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import { TopMenu } from '../component/_molecule/TopMenu';
import { HomeContainer, HomeInnerContainer } from "../style/Home/Home.module";
import { ProductSearchInput } from "../component/search/ProductSearchInput";
import {
  Layout,
  Container,
} from "../../src/style/Home/AirportCouponSection.module";
import { SearchRankPage } from "../component/search-rank/SeaechRankPage";
import { useLocation } from "react-router-dom";

export const Search = () => {
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const keyword = queryParams.get("keyword");
  const minPrice = queryParams.get("minPrice");
  const maxPrice = queryParams.get("maxPrice");
  const categoryId = queryParams.get("categoryId");
  const [showDefaultPage, setShowDefaultPage] = useState(true);

  useEffect(() => {
    if (!keyword || keyword.trim() === "") {
      console.log("디폴트 페이지 보이기");
      setShowDefaultPage(true); // Show the default page
    } else {
      setShowDefaultPage(false); // Hide the default page
      // handleSearchBtnClick(keyword); // Perform the search
    }
  }, []);

  return (
    <HomeContainer>
      <HomeInnerContainer>
        <Layout>
          <Container>
            <TopMenu
              title="상품 검색하기"
              showDefaultPage={showDefaultPage.toString()}
              setShowDefaultPage={setShowDefaultPage}
            ></TopMenu>
            <ProductSearchInput
              keyword={keyword}
              setShowDefaultPage={setShowDefaultPage}
              minPrice={minPrice}
              maxPrice={maxPrice}
              categoryId={categoryId}
            ></ProductSearchInput>
            <div>{showDefaultPage}</div>
            {showDefaultPage ? <SearchRankPage /> : null}
          </Container>
        </Layout>
      </HomeInnerContainer>
    </HomeContainer>
  );
};



