
import React, { useState, useEffect } from 'react';
import { 
  Layout,AutoCompleteProductListTitle } from '../search/search.module'
import { client } from "../../client"
import { useNavigate } from "react-router-dom";
import { gql } from "@apollo/client";
import debounce from 'lodash/debounce'; // Import debounce
import { Underline } from '../../style/Home/RecommendSection.module';
import { AutoCompleteBox, AutoCompleteText, AutoCompleteContainer } from '../search/search.module';


export const AutoComplete = ({ keywordList, handleItemClick, handleProductItemClick}) => {
  
const keywordListV2 = Array.from(new Set(keywordList.map(obj => obj.keyword)))
.map(keyword => keywordList.find(obj => obj.keyword === keyword))



  return (
    <Layout>
      <AutoCompleteContainer >

        
          {keywordListV2.map((item, index) => {
            // console.log(item)
            if(item['type'] == 'BRAND') {
              return <AutoCompleteBox key={index} onClick={() => handleItemClick(item['keyword'])}>
                  
                  <img width='18' height='18' src='https://dfx-img.s3.ap-northeast-2.amazonaws.com/btn_search_brand%402x.png'></img>
                  <AutoCompleteText >
                      {item['keyword']} 
                  </AutoCompleteText>
              </AutoCompleteBox>
            } 
          })}
        <br/>

        <Underline></Underline>
          <AutoCompleteProductListTitle> 추천 상품 </AutoCompleteProductListTitle>

          {keywordListV2.map((item, index) => {
            // console.log(item)
            if(item['type'] != 'BRAND'){
              return <AutoCompleteBox key={index} onClick={() => handleProductItemClick(item['ref_code'])}>
                  
                  <img width='60' height='60' src={item['img']}></img>
                  <AutoCompleteText >
                      {item['keyword']} 
                      
                  </AutoCompleteText>
              </AutoCompleteBox>

            }
          })}

      </AutoCompleteContainer>
    </Layout>
  );
};

