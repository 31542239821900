import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import { TopMenu } from '../component/_molecule/TopMenu';
import { HomeContainer } from '../style/Home/Home.module';
import { useParams } from 'react-router-dom';
import { CouponDisplay } from '../component/coupon/CouponDisplay';



export const CouponDownload = () => {
    const { couponId } = useParams();
  return (
    <HomeContainer >
      <TopMenu title = '쿠폰 다운로드 '></TopMenu>
      <CouponDisplay couponId = {couponId}></CouponDisplay>
   </HomeContainer >
  );
}

