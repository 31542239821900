
import React, { useState, useEffect } from 'react';
import { client } from "../../client"
import { useNavigate } from "react-router-dom";
import { gql } from "@apollo/client";
import { Container, InputContainer, InputContainerName, InputContainerInput } from './SearchRank.module';
import { TopMenu } from '../_molecule/TopMenu';

const UPDATE_SEARCH_RANK_DATA = gql`
  mutation updateSearchRankData($updateSearchRankDataInput: [UpdateSearchRankDataInput]) {
    updateSearchRankData(updateSearchRankDataInput: $updateSearchRankDataInput) {
      is_success
    }
  }
`;

export const RealTimeRank = ({}) => {

  // 10개의 입력값을 관리하는 상태 배열
  const [inputValues, setInputValues] = useState({
    1: "",
    2: "",
    3: "",
    4: "",
    5: "",
    6: "",
    7: "",
    8: "",
    9: "",
    10: "",
  });


  // 버튼 클릭 핸들러
  const handleSubmit = () => {
   // Create an array to store the input values for each rank
   const updateSearchRankDataInput = [];

   // Loop through the input values state and add them to the mutation input
   for (let rank = 1; rank <= 10; rank++) {
    if(inputValues[rank] != '') {
      updateSearchRankDataInput.push({
        id: rank,
        type: 'REAL_TIME',
        real_time_name: inputValues[rank],
      });

    }
   }

    client
      .mutate({
        mutation: UPDATE_SEARCH_RANK_DATA,
        variables: { updateSearchRankDataInput },
      })
      .then((result) => {
        alert('성공 ', result);
        // Handle success if needed
      })
      .catch((error) => {
        alert('실패 ', error);
        // Handle error if needed
      });
  };
  

  return (
    <Container> 
      {/* 1위부터 10위까지의 입력란을 반복하여 렌더링 */}
      {[1, 2, 3, 4, 5, 6, 7, 8, 9, 10].map((rank) => (
        <InputContainer key={rank}>
          <InputContainerName> {rank}위 </InputContainerName>
          <InputContainerInput
            type="text"
            placeholder="Enter your name"
            value={inputValues[rank]}
            onChange={(e) => {
              setInputValues({ ...inputValues, [rank]: e.target.value });
            }}
          />
        </InputContainer>
      ))}

      {/* 버튼 추가 */}
      <button onClick={handleSubmit}>Submit</button>
    </Container>
    )
};

