
import React, { useState, useEffect } from 'react';
import { client } from "../../client"
import { useNavigate } from "react-router-dom";
import { gql } from "@apollo/client";
import { Container } from './Agreement.module';
import { TopMenu } from '../_molecule/TopMenu';

export const AgreementUse = ({}) => {
  

  return (
    <Container> 
      <TopMenu title = '이용 약관'></TopMenu>
      <h3>제 1장 총칙 </h3>

      <p>
      제 1 조 (목 적) 
      이 약관은 ㈜디에프피알 (전자상거래 사업자)가 운영하는 인터넷 서비스 "면세가격비교" (www.dutyfree-price.com) 및 ㈜디에프피알 관련 제반 서비스 사이트 (접속 가능한 유,무선 단말기의 종류와는 상관없이 이용 가능한 '회사' 가 제공하는 모든 "서비스" 를 의미하며, 이하 '사이트'라 함)에서 제공하는 상품 및 스펙정보, 가격정보 등 상품에 대한 정보 제공 및 광고서비스를 이용함에 있어 '회사' 와 이용자의 권리와 의무 및 책임사항 등을 규정함을 그 목적으로 합니다.  
      </p>

      <p>
      제 2 조 (정의) 
      '회사' 란 ㈜디에프피알이 재화 또는 용역(이하 '상품'이라 함) 및 서비스를 이용자에게 제공하기 위하여 컴퓨터 등 정보통신설비를 이용하여 상품을 거래할 수 있도록 설정한 가상의 영업장을 말하며, 아울러 '회사' 를 운영하는 사업자의 의미로도 사용합니다. 
      ② '서비스' 란 '회사' 와 계약을 통하여 입점 및 제휴한 쇼핑몰들의 상품 및 가격정보를 비교하여 회원의 구매를 돕는 서비스 및 기타 각종 서비스를 의미합니다. 
      ③ '이용자'란 '회사' 에 접속하여 이 약관에 따라 '회사' 가 제공하는 서비스를 받는 회원 및 비회원을 말합니다. 
      ④ '회원'이라 함은 '회사' 에 개인정보를 제공하여 회원등록을 한 자로서, '회사' 의 정보를 지속적으로 제공받으며, '회사' 가 제공하는 서비스를 계속적으로 이용할 수 있는 자를 말합니다. 
      ⑤ '비회원'이라 함은 회원에 가입하지 않고 '회사' 가 제공하는 서비스를 이용하는 자를 말합니다. 
      ⑥ '상품정보' 란 '회사' 가 보유한 웹 페이지 형태의 텍스트, 음성, 화상, 이미지 등의 각종 데이터를 지칭하며 보유 유형은 다음 각호와 같습니다. 
      o 가. '회사' 가 자체 제작한 경우 
      o 나. '회사' 가 계약 등을 통해 "업체회원" 이외의 업체로부터 확보한 경우 
      o 다. '회사' 가 "업체회원" 으로부터 제공받은 경우 
      ⑦ '가격정보' 란 '회사' 가 이용자에게 고지하는 상품의 가격을 말합니다. 
      </p>

      <p>
      제 3 조 (약관의 명시와 개정) 
      '회사' 는 이 약관의 내용과 상호 및 대표자의 성명, 영업소 소재지, 사업자등록번호, 통신판매업신고번호, 개인정보보호책임자, 연락처(전화, 팩스, 전자우편 주소 등) 등을 이용자가 쉽게 알 수 있도록 '회사' 의 초기 서비스화면(전면)에 게시합니다. 다만, 약관의 내용은 이용자가 연결화면을 통하여 볼 수 있도록 할 수 있습니다. 
      '회사' 는 이용자가 약관에 동의하기에 앞서 약관에 정해져 있는 내용 중 구매취소ㆍ배송책임ㆍ환불조건 등과 같은 중요한 내용을 이용자가 이해할 수 있도록 별도의 연결화면 또는 팝업화면 등을 제공하여 이용자의 확인을 구하여야 합니다. 
      '회사' 는 전자상거래 등에서의 소비자보호에 관한 법률, 약관의 규제 등에 관한 법률, 전자거래기본법, 전자서명법, 정보통신망 이용 촉진 및 정보보호 등에 관한 법률, 방문판매 등에 관한 법률, 소비자기본법 등 관련법을 위배하지 않는 범위에서 이 약관을 개정할 수 있습니다. 
      '회사' 는 약관을 개정할 경우에는 적용일자 및 개정사유를 명시하여 현행 약관과 함께 '회사' 의 초기화면에 그 적용일자 15일 이전부터 적용일자 전일까지 공지합니다. 다만, 이용자에게 불리하게 약관 내용을 변경하는 경우에는 최소한 30일 이상의 사전 유예기간을 두고 공지합니다. 이 경우 '회사' 는 개정 전과 개정 후 내용을 명확하게 비교하여 이용자가 알기 쉽도록 표시합니다. 
      '회사' 는 전자거래기본법, 전자서명법, 정보통신망 이용촉진 및 정보보호 등에 관한 법률 등 관계법령을 위배하지 않는 범위에서 합리적 사유가 있는 경우 회원의 사전 동의 없이 본 약관을 개정할 수 있습니다. 
      '회사' 는 개정된 약관의 공지 후 효력발생일 이전까지 거부 의사 표시 또는 탈퇴를 하지 않을 경우 개정 약관에 동의하는 것으로 간주합니다. 회원이 개정약관의 적용에 동의하지 않는 경우 '회사' 는 개정 약관의 내용을 적용할 수 없으며, 이 경우 '회사' 또는 회원은 이용계약을 해지할 수 있습니다. 
      이 약관에서 정하지 아니한 사항과 이 약관의 해석에 관하여는 전자상거래 등에서의 소비자보호에 관한 법률, 약관의 규제 등에 관한 법률, 공정거래위원회가 정하는 전자상거래 등에서의 소비자보호지침 및 관계 법령 또는 상관례에 따릅니다. 
      </p>

      <h3>제 2장 서비스의 제공 </h3>
      <p>
      제 4 조 (서비스의 제공 및 변경) 
      '회사' 는 다음과 같은 업무를 수행합니다. 
      o 가. 재화 또는 용역(이하 상품이라 함)에 대한 정보 제공 및 구매계약의 체결 
      o 나. 구매계약이 체결된 상품의 배송 
      o 다. 게시판형 서비스 
      o 라. 기타 '회사' 가 정하는 업무 
      '회사' 는 상품의 품절 또는 기술적 사양의 변경 등의 경우에는 장차 체결되는 계약에 의해 제공할 재화/용역의 내용을 변경할 수 있으며 해당 사항을 공지합니다. 
      '회사' 가 제공하기로 한 이용자와 계약을 체결한 서비스의 내용을 상품의 품절 또는 기술적 사양의 변경 등의 사유로 변경할 경우에는 그 사유를 이용자에게 통지 가능한 주소로 통지합니다. 단, '회사' 에 입점한 판매점에서 제공하는 상품이 품절 또는 사양의 변경 등이 있을 경우 통지의 의무는 해당 판매점에게 있습니다. 
      전항의 경우 '회사' 는 이로 인하여 이용자가 입은 손해를 배상합니다. 단, '회사' 의 고의 또는 과실이 없음을 입증하는 경우에는 그러하지 아니합니다. 
      </p>

      <p>
      제 5 조 (서비스의 중단) 
      '회사' 는 긴급한 시스템 점검, 증설 및 교체 등 부득이한 사유로 인하여 예고 없이 일시적으로 서비스를 중단할 수 있으며, 새로운 서비스의 교체 등 '회사' 가 적절하다고 판단하는 사유에 의하여 현재 제공되는 서비스를 완전히 중단할 수 있습니다. 
      사업 종목의 전환, 사업의 포기, 업체간의 통합 등의 이유로 서비스를 제공할 수 없게 되는 경우에는 '회사' 는 제8조에 정한 방법으로 이용자에게 통지합니다. 
      '회사' 는 서비스를 특정 범위로 분할하여 각 범위 별로 이용가능 시간을 별도로 지정할 수 있습니다. 다만 이 경우 그 내용을 공지합니다. 
      </p>

      <h3>제 3장 회원의 가입 및 탈퇴 </h3>
      <p>
      제 6조 (회원가입) 
      이용자는 '회사' 가 정한 가입 양식에 따라 회원정보를 기입한 후 이 약관에 동의한다는 의사표시를 함으로서 회원가입을 신청합니다. 
      '회사' 는 제1항과 같이 회원으로 가입할 것을 신청한 이용자 중 다음 각 호에 해당하지 않는 한 회원으로 등록합니다. 
      o 가. 만 14세 미만인 자 
      o 나. 회원가입 신청자가 과거 회원 자격을 상실한 적이 있는 경우('회사' 로부터 회원자격의 제재조치를 받고 임의탈퇴 또는 강제탈퇴 한 경우를 포함) 
      o 다. 회원 가입 시 기재한 내용에 허위, 누락, 오기가 있는 경우 
      o 라. 기타 회원으로 가입하는 것이 '회사' 가 제공하는 서비스에 현저한 지장이 있다고 판단되는 경우 
      회원가입의 성립시기는 '회사’의 이용 승낙의 의사가 해당 서비스화면에 게시하거나, email 또는 기타 '회사' 가 정하는 방법으로 이용자에게 도달한 시점으로 합니다. 
      회원은 제16조 제1항에 의한 등록사항에 변경이 있는 경우, 즉시 전자우편 또는 기타 방법으로 '회사' 에 그 변경사항을 알려야 합니다. ⑤ 회원이 이용계약 종료(해지 포함) 후 재가입하는 경우에는 최종 탈퇴 시의 아이디를 재사용할 수 없으며, 30일 이후 새로운 아이디로 재가입이 가능합니다. 
      </p>

      <p>
      제 7 조 (회원탈퇴 및 자격상실) 
      회원은 '회사' 에 언제든지 탈퇴를 요청할 수 있으며 '회사' 는 즉시 회원탈퇴를 처리합니다. 
      회원이 다음 각 호의 사유에 해당하는 경우, '회사' 는 회원 자격을 제한 및 정지시킬 수 있습니다. 
      o 가. 가입 신청 및 회원정보 변경 시 허위 내용을 등록한 경우 
      o 나.'회원' 의 행위로 인하여 '회사' 의 신용도 및 대외적 이미지가 저해된 경우 
      o 다. 타인의 '회사' 이용을 방해하거나 타인의 정보를 도용하는 등 전자거래질서를 위협하는 경우 
      o 라. '회사' 의 운영진, 임직원 또는 관계자를 사칭하는 경우 
      o 마. '회사' 의 서비스 운영에 고의 또는 과실로 불이익을 주거나 방해를 하는 경우 
      o 바. 타인의 명예를 훼손하거나 모욕하는 경우 
      o 사. 법령 또는 이 약관에서 금지하거나 공서양속에 반하는 행위를 하는 경우 
      회원은 전 항의 귀책사유로 인하여 '회사' 나 다른 회원 및 이용자에게 입힌 손해를 배상할 민사상의 책임이 있으며, 범죄 행위에 대해서는 형사 상의 책임을 부담합니다. 
      '회사' 는 회원 자격을 제한/정지 시킨 후, 동일한 행위가 반복되거나 상당기간 그 사유가 시정되지 아니하는 경우 '회사' 는 회원자격을 상실 시킬 수 있습니다. 
      '회사' 는 회원의 자격을 상실 시키는 경우에는 회원등록을 말소합니다. 이 경우 회원에게 이를 통지하고, 회원등록 말소 전에 최소한30일 이상의 기간을 정하여 소명할 기회를 부여합니다. 
      </p>

      <p>
      제 8 조 (회원에 대한 통지) 
      '회사' 는 회원에 대한 통지를 하는 경우, 회원이 '회사' 와 미리 약정하여 지정한 전자우편 주소를 원칙으로 하며 전화, 팩스 등 유, 무선 연락처로도 통지할 수 있습니다. 
      '회사' 는 불특정다수 회원에 대한 통지의 경우 1주일 이상 '회사' 게시판에 게시함으로써 개별 통지에 갈음할 수 있습니다. 다만, 회원 본인의 거래와 관련하여 중대한 영향을 미치는 사항에 대하여는 개별 통지합니다. 
      </p>

      <p>
      제 9 조 (대리행위 및 보증인의 부인) 
      '회사' 는 구매자와 판매자 간의 자유로운 상품의 거래를 위한 시스템을 운영 및 관리, 제공할 뿐이므로 구매자 또는 판매자를 대리하지 않으며, 이용자 사이에 성립된 거래에 관련된 책임과 회원이 제공한 정보에 대한 책임은 해당 이용자가 직접 부담하여야 합니다. 
      '회사' 를 통해 이루어지는 구매자와 판매자 간의 거래와 관련하여 판매 의사 또는 구매 의사의 존부 및 진정성, 등록 상품의 품질, 완전성, 안정성, 적법성 및 타인의 권리에 대한 비침해성, 구매자 또는 판매자가 입력하는 정보 및 그 정보를 통하여 링크된 URL에 게재된 자료의 진실성 또는 적법성 등 일체에 대하여 보증하지 아니하며, 이와 관련한 일체의 위험과 책임은 해당 이용자가 전적으로 부담합니다. 
      </p>

      <h3>제 4장 서비스 이용 </h3>

      <p>
      제 10 조 (구매 신청) 
      이용자는 '회사' 의 서비스상에서 다음 또는 이와 유사한 방법에 의하여 구매를 신청하며, '회사' 는 이용자가 구매신청을 함에 있어서 다음의 각 내용을 알기 쉽게 제공하여야 합니다. 단, 회원인 경우 제2호 내지 제4호의 적용을 제외할 수 있습니다. 
      o 가. 상품의 검색 및 선택 
      o 나. 성명, 주소, 전화번호, 전자우편주소(또는 이동전화번호) 등의 입력 
      o 다. 약관 내용, 청약철회권이 제한되는 서비스, 배송료ㆍ설치비 등의 비용부담과 관련한 내용에 대한 확인 
      o 라. 이 약관에 동의하고 위3호의 사항을 확인하거나 거부하는 표시(예, 마우스 클릭) 
      o 마. 상품의 구매신청 및 이에 관한 확인 또는 '회사' 의 확인에 대한 동의 
      o 바. 결제 방법의 선택 
      이용자는 상품을 구매하기 전에 반드시 판매자가 사이트 내에 작성한 상품정보와 거래의 조건을 정확하게 확인한 후 구매를 하여야 합니다. 구매하려는 상품의 내용과 거래의 조건을 확인하지 않고 구매하여 발생한 모든 손실, 손해는 이용자 본인에게 있습니다. 
      '회사' 는 판매자가 상품 등록한 상품의 내용과 거래조건에 대해서 어떠한 보증이나 대리를 하지 않습니다. 따라서 구매자는 상품 구매 시 스스로의 책임하에 상품을 구매하여야 합니다. 
      '회사' 가 이용자의 서비스 이용 편의를 위하여 직접 제작하거나 제휴업체 또는 제3자가 제공하는 방식으로 정보나 콘텐츠를 제공하는 경우라도, 이용자는 상품 구매 시 자신의 판단과 책임으로 결정하여 구매하여야 하며, '회사' 는 어떠한 경우에도 회원의 구매결정에 대하여 책임을 부담하지 않습니다. 
      미성년자의 상품구매 시 법정 대리인이 해당 계약에 대하여 동의를 하지 아니하면, 미성년자 본인 또는 법정대리인이 그 계약을 취소할 수 있습니다. 미성년자의 거래에 관한 상세한 사항은 관련 법령이 정한 바에 의합니다. 
      </p>

      <p>
      제 11 조 (계약의 성립) 
      '회사' 는 제9조와 같은 구매 신청에 대하여 다음 각 호에 해당하면 승낙하지 않을 수 있습니다. o 가. 신청 내용에 허위, 기재 누락, 오기가 있는 경우 o 나. 기타 구매 신청에 승낙하는 것이 '회사' 의 기술상 현저히 지장이 있다고 판단하는 경우 
      '회사' 의 승낙이 제12조 제1항의 수신확인통지형태로 이용자에게 도달한 시점에 계약이 성립한 것으로 봅니다. 
      '회사' 의 승낙의 의사표시에는 이용자의 구매 신청에 대한 확인 및 판매가능여부, 구매신청의 정정, 취소 등에 관한 정보 등을 포함하여야 합니다. 
      </p>

      <p>

      제 12 조 (지급방법) 
      '회사' 에서 구매한 상품에 대한 대금지급방법은 다음 각 호의 방법 중 가용한 방법으로 할 수 있습니다. 단, '회사' 는 이용자의 지급방법에 대하여 상품의 대금에 어떠한 명목의 수수료도 추가하여 징수할 수 없습니다. 
      o 가. 가상계좌 (현금 입금) 
      o 나. 신용카드 등의 각종 카드 결제 
      o 다. 기타 전자적 지급방법에 의한 대금 지급 등 
      이용자는 상품의 구매 시 결제방법을 사용함에 있어 반드시 본인 명의의 결제수단을 사용하여야 하며, 타인의 결제수단의 임의 사용 등을 하여서는 안 됩니다. 타인의 결제수단을 임의 사용함으로써 발생하는 '회사' 결제수단 소유자, 판매자의 손실과 손해에 대한 모든 책임은 구매자인 이용자에게 있습니다. 
      '회사'는 이용자가 매매대금 결제 시 사용한 결제 수단에 대해 정당한 사용권한을 가지고 있는지의 여부를 확인할 수 있으며, 이에 대한 확인이 완료될 때까지 거래 진행을 중지하거나 해당 거래를 취소할 수 있습니다 
      </p>

      <p>

      제13 조 (수신확인통지, 구매신청 변경 및 취소) 
      '회사' 는 이용자의 구매신청이 있는 경우 이용자에게 수신확인통지를 합니다. 
      수신확인통지를 받은 이용자는 의사표시의 불일치 등이 있는 경우에는 수신확인 통지를 받은 후 즉시 구매신청 변경 및 취소를 요청할 수 있고, '회사' 는 배송 전에 이용자의 요청이 있는 경우에는 지체 없이 그 요청에 따라 처리하여야 합니다. 다만, 이미 대금을 지불한 경우에는 제16조의 청약철회 등에 관한 규정에 따릅니다. 
      이용자가 구매(주문)신청일로부터 7일 이내 상품 대금에 대해 입금이 확인되지 않을 경우 '회사' 는 별도 의사 표시 없이 구매계약을 해제하는 것으로 처리할 수 있습니다. 
      </p>

      <p>

      제 14 조 (상품의 공급) 
      '회사' 는 직접 제공하는 상품에 대하여 이용자와 공급시기에 관한 별도의 약정이 없는 이상 이용자가 청약을 한 날로부터 7일 이내에 상품을 배송 할 수 있도록 필요한 조치를 취합니다. 다만, '회사' 는 이미 상품의 대금의 전부 또는 일부를 받은 경우에는 대금의 전부 또는 일부를 받은 날부터 3영업일 이내에 조치를 취합니다. 이때 '회사' 는 이용자가 상품의 공급 절차 및 진행사항을 확인할 수 있도록 적절한 조치를 합니다. 
      '회사' 는 이용자가 구매한 재화에 대해 배송수단, 수단별 배송비용 부담자, 수단별 배송기간 등을 명시합니v다. 
      '회사' 와 이용자 간에 상품의 인도시기 또는 용역의 제공 시기에 관하여 별도의 약정이 있는 경우에는 당해 약정이 이 약관에 우선합니다. 
      </p>

      <p>
      제 15 조 (상품의 배송) 
      '회사' 는 이용자가 상품을 구매한 경우 이용자의 결제에 대한 통지를 판매자로부터 받은 후 3영업일 이내에 배송을 개시할 수 있도록 합니다. 
      상품을 운송할 경우 이용자가 물품을 수령하기까지의 위험은 '회사' 가 부담합니다. 이에 따라 '회사' 는 이용자가 하자(파손, 멸실, 부패 등)없는 물품을 수령할 수 있도록 물품의 포장, 운송업체의 선정, 기타 필요한 조치를 하여야 합니다. 
      정확한 배송을 위해 이용자의 배송관련 정보는 개인정보처리방침에 의하여 판매자에게 제공될 수 있으며, '회사' 는 상품의 배송 이외의 목적으로 해당 정보를 사용하지 않습니다. 
      공휴일 및 기타 휴무일 또는 천재지변 등 불가피한 사유가 있는 경우 배송 소요기간은 지연될 수 있습니다. 
      </p>

      <p>

      제 16 조 (상품의 교환, 반품, 환불) 
      상품의 교환은 배송완료일을 기준으로 7영업일 이내에 교환 요청을 하여야 합니다. 교환 요청일이 지난 경우에는 판매자와 직접 처리하여야 합니다. 
      구매자는 ' 전자상거래 등에서의 소비자보호에 관한 법률' 제17조에 의해, 구매한 상품을 배송 받은 후 7일 이내에 반품 또는 교환을 요청할 수 있으며, 반품에 관한 일반적인 사항은 ' 전자상거래 등에서의 소비자보호에 관한 법률' 등 관련 법령이 판매자가 제시한 조건보다 우선합니다. 단, 다음 각 호의 경우에는 구매자가 반품 또는 교환을 요청할 수 없습니다.
      o 가. 구매자의 귀책사유로 말미암아 상품이 훼손된 경우 
      o 나. 구매자의 사용 또는 일부 소비에 의하여 상품의 가치가 현저히 감소한 경우 
      o 다. 시간의 경과에 의하여 재판매가 곤란할 정도로 상품의 가치가 현저히 감소한 경우 
      o 라. 복제가 가능한 상품의 포장을 훼손한 경우 
      o 마. 기타 구매자가 환불 또는 교환을 요청할 수 없는 합리적인 사유가 있는 경우 
      반품이 필요한 상품은 반품을 요청한 시점으로부터 3영업일 이내에 구매한 판매점으로 반품하여야 합니다. 
      '회사' 의 귀책사유로 인해 발생한 반품의 경우 배송에 필요한 모든 비용을 '회사' 가 부담합니다. 
      구매자의 단순 변심 또는 구매자 귀책사유로 인해 발생하는 반품의 경우 배송에 필요한 모든 비용을 구매자가 부담합니다. 
      환불 승인은 개별 판매자가 직접 처리하며 판매자의 환불승인일을 기준으로 현금결제의 경우 1영업일 후, 카드결제의 경우 카드사에 따라 5~7영업일 후 환불 및 승인 취소가 이루어 집니다. 
      '회사' 는 판매자가 교환, 반품에 대하여 사실확인 진행 절차에 적극적으로 임하지 않거나 지연하는 경우 구매자에게 교환 또는 반품의 원인을 파악한 후 해당 거래를 취소하여 구매자에게 보관중인 결제대금을 환불할 수 있습니다. 
      '회사' 는 구매자의 교환 또는 반품의 요청이 정당하지 않다고 판단하는 경우는 구매자의 의사표시를 취소하여 보관중인 결제대금을 판매자에게 지급할 수 있습니다. 또한 구매자의 교환, 반품 등이 접수된 날로부터 14일이 경과할 때까지 기 수령한 상품을 판매자에게 반환하지 않거나 연락(전화, 이메일 등)이 되지 않는 경우에는 교환, 반품의 의사를 철회한 것으로 간주하여 보관중인 결제대금을 판매자에게 지급할 수 있습니다. 
      판매된 상품의 취소, 반품, 교환, 환불과 관련한 업무 주체는 판매자이며 그에 따른 책임도 판매자에게 있으며, '회사' 는 취소, 반품, 교환, 환불 업무를 처리할 수 있는 시스템만을 제공 합니다. 
      정상적으로 구매 결정된 이후 발생하는 문제에 대해서는 구매자가 판매자와 직접 처리 합니다. 
      </p>

      <p>

      제 17 조 (청약철회 등) 
      '회사' 와 상품 등의 구매에 관한 계약을 체결한 이용자는 상품 배송이 완료된 날로부터 7일 이내에 청약을 철회할 수 있습니다. 
      구매자는 상품을 배송 받은 경우 다음 각호의 1에 해당하는 경우에는 반품 및 교환을 할 수 없습니다. 
      o 가. 구매자에게 책임 있는 사유로 상품이 멸실 또는 훼손된 경우 (다만, 상품의 내용 확인을 위하여 포장 등을 훼손한 경우에는 청약철회를 할 수 있습니다.) 
      o 나. 구매자의 사용 또는 일부 소비에 의하여 상품의 가치가 현저히 감소한 경우 
      o 다. 시간의 경과에 의하여 재판매가 곤란할 정도로 상품의 가치가 현저히 감소한 경우 
      o 라. 같은 성능을 지닌 상품으로 복제가 가능한 경우 그 원본인 상품의 포장을 훼손한 경우 
      o 마. 그 밖에 거래의 안전을 위하여 전자상거래 등에서의 소비자보호에 관한 법률 시행령 21조(청약철회 등의 제한)에서 정하는 경우 
      제2항 제2호 내지 제4호의 경우에 '회사' 가 사전에 청약철회 등이 제한되는 사실을 소비자가 쉽게 알 수 있는 곳에 명기하거나 시용 상품을 제공하는 등의 조치를 하지 않았다면 이용자의 청약철회 등이 제한되지 않습니다. 
      이용자는 제1항 및 제2항의 규정에 불구하고 상품의 내용이 표시ㆍ광고 내용과 다르거나, 계약내용과 다르게 이행된 경우에는 당해 상품을 공급받은 날로부터 3월 이내, 그 사실을 안 날 또는 알 수 있었던 날로부터 30일 이내에 청약철회 등을 할 수 있습니다. 
      </p>


      <p>

      제 18 조 (청약철회 등의 효과) 
      '회사' 는 본 약관 제16조에 따라 청약철회가 있은 후 이용자로부터 상품을 반환 받은 경우 3영업일 이내에 이미 지급받은 상품의 대금을 환급합니다. 
      '회사' 는 위 대금을 환급함에 있어서 이용자가 신용카드 등의 결제수단으로 상품의 대금을 지급한 때에는 지체 없이 당해 결제수단을 제공한 사업자로 하여금 상품의 대금의 청구를 정지 또는 취소하도록 요청합니다. 
      청약철회 등의 경우 공급받은 상품의 반환에 필요한 비용은 이용자가 부담합니다. '회사' 는 이용자에게 청약철회등을 이유로 위약금 또는 손해배상을 청구하지 않습니다. 다만 상품의 내용이 표시/광고 내용과 다르거나 계약내용과 다르게 이행되어 청약철회등을 하는 경우 상품의 반환에 필요한 비용은 '회사' 가 부담합니다. 
      이용자가 상품을 제공받을 때 배송비를 부담한 경우에 '회사' 는 청약 철회 시 그 비용을 누가 부담하는지를 이용자가 알기 쉽도록 명확하게 표시합니다. 
      이용자는 구매한 상품에 청약철회의 원인이 발생한 경우 수령한 상품을 임의로 사용하거나 훼손되도록 방치하여서는 안 됩니다. 청약철회 상품의 임의사용이나 상품 보관의 미흡으로 인한 상품 훼손에 대하여 합당한 비용을 부담하여야 합니다. 
      </p>


      <p>

      제 19 조 (포인트) 
      ㈜디에프피알은 회원의 재화 등의 구매, 리뷰 작성, 각종 이벤트 참여 등 일정한 서비스 이용을 조건으로, 면세가격비교 내 별도 안내페이지의 기재에 따라 회원에게 면세가격비교에서 사용할 수 있는 포인트를 지급할 수 있습니다.
      1포인트는 면세가격비교 내에서 원화 1원과 같은 가치를 가지며, 회원은 ㈜디에프피알이 정한 조건에 따라 서비스 이용 과정에서 포인트를 단독으로 또는 다른 결제 수단과 함께 사용할 수 있습니다. 
      포인트의 부여 및 사용에 관한 조건 등 관련한 상세 내용은 ㈜디에프피알이 정한 정책에 따르되, ㈜디에프피알은 별도 안내페이지 등을 통하여 포인트와 관련한 상세한 내용을 회원에게 안내합니다. 
      포인트의 유효기간은 지급일로부터 1년이며 유효기간이 지나면 자동적으로 소멸하는 것을 원칙으로 합니다. 다만, ㈜디에프피알은 포인트 별로 상이한 유효기간을 정하여 회원에게 부여할 수 있고, 이 경우 포인트 관련 안내페이지에서 그를 안내하도록 하겠습니다. 
      포인트는 유효기간의 만료일이 빠른 순서로 사용되며 회원을 탈퇴하면 미사용한 포인트는 소멸됩니다. 
      회원은 포인트를 본인의 거래에 대해서만 사용할 수 있으며, 어떠한 경우라도 포인트를 타인에게 매매 또는 양도하거나, 실질적으로 매매 또는 양도와 동일하게 볼 수 있는 행위를 하여서는 아니됩니다.
      부정한 방법으로 포인트를 획득한 사실이 확인될 경우 ㈜디에프피알은 회원에게 이를 통지하고 소명기회를 부여한 뒤 포인트 회수 또는 형사 고발 등 필요한 조치를 취할 수 있습니다. 
      </p>


      <p>

      제 5장 회원정보 
      제 20 조 (개인정보보호) 
      '회사' 는 이용자의 정보수집 시 구매계약 이행에 필요한 최소한의 정보를 수집합니다. 다음 사항을 필수사항으로 하며 그 외 사항은 선택사항으로 합니다. 
      o 가. 성명 
      o 나. 희망ID 
      o 다. 비밀번호 
      o 라. 전자우편주소 
      o 마. 닉네임 
      '회사' 는 이용자의 개인식별이 가능한 개인정보를 수집하는 때에는 반드시 당해 이용자의 동의를 받습니다. 
      제공된 개인정보는 당해 이용자의 동의 없이 목적 외의 이용이나 제3자에게 제공할 수 없으며, 이에 대한 모든 책임은 '회사' 가 집니다. 다만, 다음의 경우에는 예외로 합니다. 
      o 가. 배송 업무상 배송업체에게 배송에 필요한 최소한의 이용자의 정보(성명, 주소, 전화번호)를 알려주는 경우 
      o 나. 통계작성, 학술연구 또는 시장조사를 위하여 필요한 경우로서 특정 개인을 식별할 수 없는 형태로 제공하는 경우 
      o 다. 상품의 거래에 따른 대금 정산을 위하여 필요한 경우 
      o 라. 도용 방지를 위하여 본인확인에 필요한 경우 
      o 마. 법률의 규정 또는 법률에 의하여 필요한 불가피한 사유가 있는 경우 
      '회사' 는 제2항과 제3항에 의해 이용자의 동의를 받아야 하는 경우에 개인정보보호 책임자의 신원(소속, 성명 및 전화번호 기타 연락처), 정보의 수집목적 및 이용목적, 개인정보의 보유 기간 및, 이용 기간, 제3자에 대한 정보제공 관련 사항(제공 받는 자, 제공목적 및 제공할 정보의 내용) 등 정보통신망 이용촉진 및 정보보호등에 관한 법률 제22조 제2항이 규정한 사항을 미리 명시하거나 고지해야 하며 이용자는 언제든지 이 동의를 철회할 수 있습니다. 
      이용자는 언제든지 '회사' 가 가지고 있는 자신의 개인정보에 대해 열람 및 오류정정을 요구할 수 있으며 '회사' 는 이에 대해 지체 없이 필요한 조치를 취할 의무를 집니다. 이용자가 오류의 정정을 요구한 경우에 '회사' 는 그 오류를 정정할 때까지 당해 개인정보를 이용하지 않습니다. 
      '회사' 는 개인정보 보호를 위하여 관리자를 한정하여 그 수를 최소화하며 신용카드, 은행계좌 등을 포함한 이용자의 개인 정보의 분실, 도난, 유출, 변조 등으로 인한 이용자의 손해에 대하여 모든 책임을 집니다. 
      '회사' 또는 그로부터 개인정보를 제공받은 제3자는 개인정보의 수집목적 또는 제공받은 목적을 달성한 때에는 당해 개인정보를 지체 없이 파기합니다. 
      기타 개인정보 처리와 관련한 내용은 '개인정보처리방침'에 고지하고 있으며 약관과 별도의 동의를 얻습니다. 
      </p>

      <p>

      제 21 조 (ID및 비밀번호 관리) 
      회원의 ID와 비밀번호에 관한 관리책임은 회원에게 있습니다. 
      회원은 자신의 ID 및 비밀번호를 제3자에게 이용하게 해서는 안됩니다. 
      회원이 자신의 ID 및 비밀번호를 도난 당하거나 제3자가 사용하고 있음을 인지한 경우에는 바로 '회사' 에 통보하고 '회사' 의 안내가 있는 경우에는 그에 따라야 합니다. 

      </p>


      <p>
      제 22 조 (이용자의 금지행위) 
      신청 또는 변경 시 허위내용의 등록 
      타인의 정보 도용 
      '회사' 에 게시된 정보의 변경
      '회사' 가 정한 정보 이외의 정보(컴퓨터 프로그램 등)의 송신 또는 게시 
      '회사' 기타 제3자의 저작권 등 지적재산권에 대한 침해 
      '회사' 기타 제3자의 명예를 손상시키거나 업무를 방해하는 행위 
      외설 또는 폭력적인 메시지/화상/음성 기타 공서양속에 반하는 정보를 공개 또는 게시하는 행위 
      </p>


      <p>

      제 6장 권리관계 및 저작권 등 
      제 23 조 ('회사' 와 연결된 '몰'과의 관계) 
      '회사' 와 연결된 '몰'(웹사이트)이란 하이퍼링크(예: 하이퍼링크의 대상에는 문자, 그림 및 동화상 등이 포함됨)방식 등으로 연결된 경우를 말합니다. 
      '회사' 는 연결된 '몰'(웹사이트)이 독자적으로 제공하는 상품에 의하여 이용자와 행하는 거래에 대해서 보증 책임을 지지않는다는 뜻을 '회사' 의 초기화면 또는 연결되는 시점의 팝업화면으로 명시한 경우에는 그 거래에 대한 보증책임을 지지 않습니다. 
      </p>

      <p>

      제 24 조 (저작권 및 게시물의 관리) 
      '회사' 가 작성한 저작물에 대한 저작권, 기타 지적재산권은 '회사' 에 귀속합니다. 
      이용자는 '회사' 를 이용함으로써 얻은 정보 중 '회사' 에게 지적재산권이 귀속된 정보를 '회사' 의 사전 승낙 없이 복제, 송신, 출판, 배포, 방송 기타 방법에 의하여 영리목적으로 이용하거나 제3자에게 이용하게 하여서는 안됩니다. 
      '회사' 는 약정에 따라 이용자에게 귀속된 저작권을 사용하는 경우 당해 이용자에게 통보하여야 합니다. 
      '회사' 는 이용자가 게시하거나 작성하는 모든 내용물(회원간 전달 포함)이 다음 각 호의 경우에 해당한다고 판단되는 경우 사전통지 없이 삭제할 수 있으며, 이에 대해 '회사' 는 어떠한 책임도 지지 않습니다. 
      o 가. '회사' 다른 이용자 또는 제3자를 비방하거나 중상모략으로 명예를 손상시키는 내용인 경우 
      o 나. 공공질서 및 미풍양속에 위반되는 내용의 정보, 문장, 도형 등의 유포에 해당하는 경우 
      o 다. 범죄적 행위에 결부된다고 인정되는 내용인 경우 
      o 라. '회사' 의 저작권, 제3자의 저작권 등 기타 권리를 침해하는 내용인 경우 
      o 마. 불필요하거나 승인되지 않은 광고 등 게시물을 게재하는 경우 
      o 바. 기타 관계 법령 및 '회사' 의 지침, 규칙 등에 위반된다고 판단되는 경우 
      o 사. 게시글이 게시판 성격에 부합하지 않는 내용인 경우 
      </p>

      <p>
      제 25 조 ('회사' 의 면책) 
      '회사' 는 회원의 귀책사유로 인한 서비스 이용의 장애 또는 손해에 대하여 책임을 지지 않습니다. 
      '회사' 는 회원의 컴퓨터 오류에 의해 손해가 발생한 경우 또는 회원이 신상정보 및 전자우편 주소를 잘못 기재하여 손해가 발생한 경우 책임을 지지 않습니다. 
      '회사' 는 회원이 서비스에 게재한 각종 정보, 자료 등에 대해 정확성, 적법성을 보장하지 않습니다.
      회원간에 이루어지는 상품의 매매와 관련하여 발생하는 상품의 배송, 청약철회 또는 반품 등의 거래 진행은 거래의 당사자인 판매자 회원과 구매자 회원간에 수행되어야 하며, '회사' 는 거래진행에는 관여하지 않으며 어떠한 책임도 부담하지 않습니다. 
      회원이 자신의 개인정보를 타인에게 유출 또는 제공함으로써, 발생하는 피해에 대해서 '회사' 는 일절 책임을 지지 않습니다. 
      '회사' 의 서비스 화면에서 링크, 배너 등을 통하여 연결된 '회사' (이하 피 연결'회사') 와 회원간에 이루어진 거래에 '회사' 는 개입하지 않으며, 해당 거래에 대하여 책임을 지지 않습니다. 
      '회사' 는 서비스 화면에 표시되는 판매자 회원 또는 제3자가 제공하는 상품 또는 정보 등의 정확성, 적시성, 타당성 등에 대하여 보증 하지 않으며, 그와 관련하여 어떠한 책임도 부담하지 아니 합니다. 
      </p>

      <h3>제 7장 기타 </h3>
      <p>
      제 26 조 (기타 사항) 
      '회사' 는 필요한 경우 특정 서비스나 기능의 전부 또는 일부를 사이트를 통해 미리 공지한 후 일시적 또는 영구적으로 수정하거나 중단할 수 있습니다. 
      각 당사자는 상대방의 서면동의 없이 이 약관 상의 권리와 의무를 제3자에게 양도하거나 처분할 수 없습니다. 
      이 약관과 관련하여 당사자간의 합의에 의하여 추가로 작성된 계약서, 협정서, 통보서 등과 '회사' 의 정책변경, 법령의 제 개정 또는 공공기관의 고시나 지침 등에 의하여 '회사' 가 공지하는 내용도 이용계약의 일부를 구성합니다. 
      </p>


      <p>

      제 27 조 (재판관할 및 준거법) 
      '회사' 와 이용자간에 발생한 전자거래 분쟁에 관한 소송은 제소 당시의 이용자의 주소에 의하고, 주소가 없는 경우에는 거소를 관할하는 지방법원의 전속관할로 합니다. 다만, 제소 당시 이용자의 주소 또는 거소가 분명하지 않거나 외국 거주자의 경우에는 민사소송법상의 관할법원에 제기합니다. 
      '회사' 와 이용자간에 제기된 소송에는 한국법을 적용합니다. 
      </p>

      <p>
      (부칙) 본 약관은 2023년 11월 1일부터 시행합니다.
      </p>

        
    </Container>)
};

