import React from "react";
import { useEffect, useState } from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import { Login } from "./route/Login";
import { SignUp } from "./route/SignUp";
import { News } from "./route/News";
import { CouponV2 } from "./route/CouponV2";
import { Header } from "./component/common/Header";
import { CouponDownload } from "./route/CouponDownload";
import { Admin } from "./route/Admin";
import { Search } from "./route/Search";
import { Post } from "./route/Post";
import { ProductDetail } from "./route/ProductDetail";
import { Agreement } from "./route/Agreement";
import { Company } from "./route/Company";
import ReactGA from "react-ga4";
import RouteChangeTracker from "./RouteChangeTracker";
import { MainCategoryFilter } from "./route/MainCategoryFilter";
import { HomeV4 } from "./route/HomeV4";
import AiaEvent2 from "./route/aiapp/AiaEvent2";
import AiaEvent1 from "./route/aiapp/AiaEvent1";
import AiaCoupon from "./route/aiapp/AiaCoupon";
import styled from "styled-components";
import { AdAdmin } from "./component/admin-ad/AdAdmin";
import { AdUploader } from "./component/admin-ad/AdUploader";
import { AdDetails } from "./component/admin-ad/AdDetails";
import { AiaCouponDisplay } from "./route/aiapp/AiaCouponDisplay";
import { DfpEvent1 } from "./route/aiapp/DfpEvent1";
import { DfpCouponPage } from "./route/aiapp/DfpCouponPage";

import Whiskey from "./component/whisky365/Whisky";
import { MerchandiseList } from "./component/whisky365/MerchandiseList";
import { Feedback } from "./component/whisky365/Feedback";
import { MyPage } from "./component/whisky365/MyPage";
import { SignUpBsdf } from "./component/whisky365/sign-up/SignUpBsdf";
import { LoginBsdf } from "./component/whisky365/sign-up/LoginBsdf";
import { FindPassword } from "./route/set-pw/findPassword";
import { ResetPassword } from "./route/set-pw/resetPassword";

const gaTrackingId = process.env.REACT_APP_GA_TRACKING_ID; // 환경 변수에 저장된 추적ID 가져오기
ReactGA.initialize("G-1CB0ZSGML5", { debug: true }); // react-ga 초기화 및 debug 사용

function App() {
  RouteChangeTracker();

  const Container = styled.div`
    min-height: 100vh; /* 페이지 전체를 포함하도록 최소 높이 설정 */
    display: flex;
    flex-direction: column; /* 플렉스 방향을 열로 설정 */
  `;

  return (
    <Container>
      <Header></Header>
      <Routes>
        <Route path="/" element={<HomeV4 />} />
        <Route path="/news" element={<News />} />
        <Route path="/coupon/:couponId" element={<CouponDownload />} />
        <Route path="/coupon" element={<CouponV2 />} />
        <Route path="/post" element={<Post />} />
        <Route path="/admin123" element={<Admin />} />
        <Route path="/search" element={<Search />} />
        <Route path="/product/:productId" element={<ProductDetail />} />
        <Route path="/agreement/:agreementId" element={<Agreement />} />
        <Route path="/company/" element={<Company />} />
        <Route
          path="/mainCategoryFilter"
          element={<MainCategoryFilter />}
        ></Route>

        {/* 어드민 */}
        <Route path="/adminad/" element={<AdAdmin />} />
        <Route path="/uploadad/" element={<AdUploader />} />
        <Route path="/details/" element={<AdDetails />} />
        {/* AIA 이벤트 */}
        <Route path="/AiaCouponDisplay" element={<AiaCouponDisplay />} />
        {/* 일반 이벤트 */}
        <Route path="/event1/" element={<AiaEvent1 />} />
        <Route path="/eventCoupon/" element={<AiaCoupon />} />
        {/* 로그인 */}
        <Route path="/login" element={<Login />} />
        <Route path="/signup" element={<SignUp />} />

        {/* bsdf 로그인 관련 */}
        <Route path="/signupbsdf" element={<SignUpBsdf />} />
        <Route path="/loginbsdf" element={<LoginBsdf />} />
        {/* whisky365 */}
        <Route path="/whisky365" element={<MerchandiseList />} />
        <Route path="/whisky365/:productId" element={<Whiskey />} />
        <Route path="/whisky365/review" element={<Feedback />} />

        {/* My Page */}
        <Route path="/mypage" element={<MyPage />} />

        {/* reset PW */}
        <Route path="/findPassword" element={<FindPassword />} />
        <Route path="/resetPassword" element={<ResetPassword />} />
      </Routes>
      {/* <Footer></Footer> */}
    </Container>
  );
}

export default App;
