
import React, { useState, useEffect } from 'react';
import { useQuery } from '@apollo/client';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper } from '@mui/material';



import { gql } from "@apollo/client";
import { client } from "../../../client"

// GraphQL 쿼리
const CREATE_POST = gql`
mutation createPost($create_data:PostInput!) {
    createPost(create_post:$create_data) 
  }
`;

export const PostAdminAddButton = ({ updatePosts }) => {


    const [title, setTitle] = useState('');
    const [subtitle, setSubtitle] = useState('');
    const [blogLink, setBlogLink] = useState('');
    const [category, setCategory] = useState('1'); // Initialize with a default category value

    const [image, setImage] = useState(null);

    const handleImageChange = (event) => {
        const selectedImage = event.target.files[0];
        setImage(selectedImage);
    }
    const handleCategoryChange = (event) => {
        setCategory(event.target.value);
    }


    // 추가 버튼을 눌렀을 때 호출되는 함수
    const handleAddPost = () => {
        if (title === '' || blogLink === '') {
            alert('제목과 블로그 링크를 입력해주세요.');
            return;
        }
        client.mutate({
            mutation: CREATE_POST,
            variables: {
                "create_data" :{
                  "title" :title,
                  "subtitle": subtitle,
                  "post_category_id": Number(category), 
                  "link_url": blogLink
                }
              }
        })
        .then((result) => {
            console.log(result)
            alert(JSON.stringify(result));
            updatePosts({
                "title" :title,
                "subtitle": subtitle,
                "post_category_id": Number(category), 
                "link_url": blogLink
              });
        })
        .catch((error) => {
            console.log(error);
        });

    }

  
    return (
        <div style={{ marginTop: '2rem', marginBottom: '4rem' }}>
            <h3>포스팅</h3>
            <div>제목</div>
            <input onChange={(e) => setTitle(e.target.value)} value={title} />
            <div>부제목</div>
            <input onChange={(e) => setSubtitle(e.target.value)} value={subtitle} />

            <div>분류</div>
            <select onChange={handleCategoryChange} value={category}>
                <option value="1">흥미진진한</option>
                <option value="2">특별한 경험</option>
                <option value="2">쇼핑가이드</option>
                <option value="2">환상의 장소</option>
            </select>
            <div>블로그 링크</div>
            <input onChange={(e) => setBlogLink(e.target.value)} value={blogLink} />
            <div>이미지</div>
            <input type="file" accept="image/*" onChange={handleImageChange} />
            <Button variant="contained" color="primary" onClick={handleAddPost}>
                추가
            </Button>
        </div>
    );
}

