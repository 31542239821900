import styled from "styled-components";


export const PostCardContainer = styled.div`  
  display: flex;
  flex-direction: column;
  width: 156px;
  height: 200px;
  background-image: url( ${props => props.url} );
  background-size: contain;

`


export const Title = styled.div`  
font-family: Pretendard;
font-size: 16px;
font-weight: bold;
font-stretch: normal;
font-style: normal;
line-height: normal;
letter-spacing: -0.16px;
text-align: left;
color: #fff;
margin-top: 130px;
padding-left : 14px;
`



export const Desc = styled.div`  
font-family: Pretendard;
font-size: 13px;
font-weight: normal;
font-stretch: normal;
font-style: normal;
line-height: 1.38;
letter-spacing: -0.13px;
text-align: left;
color: #fff;
padding-left : 14px;
white-space:pre-wrap;
    
`



export const PostCardContainerV2 = styled.div`  
  display: flex;
  flex-direction: column;
  width: 170px;
  height: 218px;
  background-image: url( ${props => props.url} );
  background-size: contain;

` 


export const TitleV2 = styled.div`  
font-family: Pretendard;
font-size: 16px;
font-weight: bold;
font-stretch: normal;
font-style: normal;
line-height: normal;
letter-spacing: -0.16px;
text-align: left;
color: #fff;
margin-top: 144px;
padding-left : 14px;
`



export const DescV2 = styled.div`  
font-family: Pretendard;
font-size: 13px;
font-weight: normal;
font-stretch: normal;
font-style: normal;
line-height: 1.38;
letter-spacing: -0.13px;
text-align: left;
color: #fff;
padding-left : 14px;
white-space:pre-wrap;
    
`
