import styled from 'styled-components';

export const Layout = styled.div`
  display: flex;
  justify-content: center;
  // height: 120px;
  padding: 0 20px;
`;

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 360px;
`;

export const SearchContainer = styled.div`
  padding: 0px 10px;
`;

export const Underline = styled.div`
  border-bottom: 1.5px solid #00000050; /* Adjust the color and thickness as needed */
  margin-bottom: 24px; /* Adjust the spacing between text and underline */
`;

export const TitleContainer = styled.div`
  font-family: Pretendard;
  font-size: 22px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #000;
`;

export const CategoryContainer = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  padding-top: 10px;
`;

export const CategoryChip = styled.div`
  background-color: ${(props) => (props.active ? '#703dff' : '#efefef')};
  color: ${(props) => (props.active ? '#fff' : '#444')};

  padding: 6px 16px;
  border-radius: 14px;
  // background-color: #efefef;

  font-family: Pretendard;
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;

  white-space: nowrap; /* 텍스트 줄바꿈 금지 */
  margin-right: 8px;
  margin-top: 8px;
`;

export const ProductListContainer = styled.div`
  display: flex;
  flex-direction: column;
  padding-top: 20px;
`;

export const ProductItemContainer = styled.div`
  display: flex;
  flex-direction: row;
  padding-bottom: 18px;
`;

export const ProductItemImageContainer = styled.div`
  width: 100px;
  height: 100px;
  border-radius: 6px;
  border: solid 1px #ececec;

  img {
    max-width: 100%; /* 이미지의 최대 너비를 컨테이너 너비에 맞게 조절 */
    height: auto; /* 이미지 높이 자동 조절하여 비율 유지 */
    display: block; /* 인라인 요소 사이의 공백 제거 */
  }
`;

export const ProductItemInfoContainer = styled.div`
  width: 200px;
  // height: 100px;
  margin-left: 10px;
`;

export const ProductItemBrandNameInfo = styled.div`
  height: 47px;
`;

export const ProductBrand = styled.div`
  width: 100%;
  font-family: Pretendard;
  font-size: 12px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: left;
  color: #444;

  margin-top: 7px;
`;
export const ProductName = styled.div`
  width: 100%;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;

  font-family: Pretendard;
  font-size: 14px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: left;
  color: #444;

  margin-top: 5px;
`;

export const ProductItemPriceInfo = styled.div`
  display: flex;
  flex-direction: column;

  width: 210px;
  border-radius: 6px;
  background-color: #f8f8f8;

  padding-left: 0.8rem;
  padding-top: 0.3rem;
  padding-bottom: 0.5rem;
  box-sizing: border-box; /* padding과 border를 요소 크기에 포함 */
`;

export const ProductDutyFreeSite = styled.div`
  width: 35%;

  font-family: Pretendard;
  font-size: 12px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: left;
  color: #777;
`;

export const ProductPriceEng = styled.div`
  width: 40%;
  height: 16px;
  font-family: Pretendard;
  font-size: 14px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: left;
  color: #000;

  margin-right: 4px;
`;

export const ProductPriceKr = styled.div`
  height: 14px;
  font-family: Pretendard;
  font-size: 12px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: left;
  color: #777;
`;

export const ProductPriceContainer = styled.div`
  padding-top: 0.2rem;
  display: flex;
  flex-direction: row;
  align-items: center;
`;

export const LowestPrice = styled.div`
  font-family: Pretendard;
  font-size: 12px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: left;
  color: #ff4040;
`;

export const AdvContainer = styled.div`
  width: 360px;
  display: block;
  margin-top: 20px;
  justify-content: end;
  position: relative;
  overflow: hidden;
`;

export const CategoryAdvContatiner = styled.div`
  width: 360px;
  display: block;
  justify-content: end;
  border-bottom: 12px solid #efefef; /* 하단에 굵은 회색 라인 */
  padding-bottom: 20px;
  margin-bottom: 20px;
`;

export const LogoWrapper = styled.div`
  position: relative;
  display: flex;
  justify-content: flex-end;
`;

export const AdvWrapper = styled.div`
  display: flex;
`;

export const AdLogo = styled.img`
  width: 160px;
  height: auto;

  &:hover {
    cursor: grab;
  }
`;

export const AdBanner = styled.img`
  width: 360px;
  height: 250px;
  flex-shrink: 0;
  padding: 10px 0px;

  &:hover {
    cursor: grab;
  }
`;
