import styled, { keyframes } from "styled-components";


export const Container = styled.div`
  margin-left : 10%;
  margin-right : 10%;
`


export const RightStartDiv = styled.div`
  display : flex; 
  flex-direction : row-reverse;
`