import {
  AdBanner,
  AdLogo,
  AdvContainer,
  AdvWrapper,
  Layout,
  LogoWrapper,
} from '../../../style/Home/RecommendSection.module';
import Logo from '../../../resources/Ad/KCND LOGO 1.png';
import img01 from '../../../resources/Ad/240507_01.jpg';
import img07 from '../../../resources/Ad/240507_07_신규가입.jpg';
import React, { useEffect, useState, useRef } from 'react';
import Carousel from 'react-material-ui-carousel';
import { client } from '../../../client';
import { BannerClicked, LogoClicked, createObserver } from './Ad.module';

import WhiskyBanner from '../../whisky365/img/banner/whisky365Banner.gif';

const LogType = {
  AD: 'AD',
  GENERAL_LOG: 'GENERAL_LOG',
  TEST: 'TEST',
};

export const MainAdv = () => {
  const boxRef = useRef([]);
  const [items, setItems] = useState([
    {
      key: 1,
      name: "Whisky365 Banner",
      src: "https://dfx-img.s3.ap-northeast-2.amazonaws.com/whisky1.gif",
    },
    {
      key: 2,
      name: "Whisky365 Banner",
      src: "https://dfx-img.s3.ap-northeast-2.amazonaws.com/whisky2.gif",
    },
    {
      key: 3,
      name: "Whisky365 Banner",
      src: "https://dfx-img.s3.ap-northeast-2.amazonaws.com/whisky3.gif",
    },
    {
      key: 4,
      name: "Whisky365 Banner",
      src: "https://dfx-img.s3.ap-northeast-2.amazonaws.com/whisky4.gif",
    },
    {
      key: 5,
      name: "Whisky365 Banner",
      src: "https://dfx-img.s3.ap-northeast-2.amazonaws.com/whisky5.gif",
    },
  ]);

  // useEffect(() => {
  //   const fetchAds = async () => {
  //     try {
  //       const { data } = await client.query({
  //         query: GET_AD_BANNER,
  //         variables: {
  //           visible: true,
  //         },
  //       });

  //       const tempAds = data.getAdBannerList
  //         .filter((ad) => ad.account_id === 1) // accountId가 1인 요소로 필터링
  //         .map((ad, idx) => {
  //           return {
  //             ...ad,
  //             key: idx,
  //           };
  //         });

  //       setItems(tempAds);
  //     } catch (error) {
  //       console.error('Error fetching ads:', error);
  //     }
  //   };

  //   fetchAds();
  // }, []);

  // // View Log
  const [bannerIndex, setBannerIndex] = useState(items[0]?.key);

  // useEffect(() => {
  //   const checkItems = () => {
  //     if (items.length > 0) {
  //       createObserver(bannerIndex, items, boxRef);
  //     } else {
  //       setTimeout(checkItems, 50);
  //     }
  //   };

  //   checkItems();
  // }, [bannerIndex]);

  const handleCarouselChange = (index) => {
    setBannerIndex(index);
  };

  return (
    <Layout>
      <AdvContainer>
        <Carousel
          interval={5000}
          onChange={(index) => handleCarouselChange(index)}
        >
          {items.map((item, i) => (
            <AdvWrapper key={i} item={item}>
              <AdBanner
                key={item.key}
                src={item.src}
                alt={item.name}
                // onClick={() => {
                //   alert("11월 오픈 예정입니다");
                // }}
                onClick={() => {
                  BannerClicked(item);
                  window.location.pathname = "/whisky365"; // 원하는 경로로 변경
                }}
                ref={(ref) => (boxRef.current[i] = ref)}
              />
            </AdvWrapper>
          ))}
        </Carousel>
      </AdvContainer>
    </Layout>
  );
};
