import { useEffect } from "react";
import { useState } from "react";
import { useLocation } from "react-router";
import {
  HomeContainer,
  HomeInnerContainer,
} from "../../style/Home/Home.module";
import { TopMenu } from "../_molecule/TopMenu";
import styled from "styled-components";
import {
  Button,
  FormControl,
  Input,
  InputLabel,
  MenuItem,
  Select,
  TextField,
} from "@mui/material";
import { Footer } from "../common/Footer";
import { client } from "../../client";

import AWS from "aws-sdk";
import { MODIFY_AD_BANNER } from "./Ad.gql";

export const AdDetails = () => {
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const data = Object.fromEntries(queryParams.entries());
  const [selectedImg, setSelectedImg] = useState(data.image_url);
  const [selectedFile, setSelectedFile] = useState(null);

  const [formData, setFormData] = useState({
    id: data.id,
    account_id: data.account_id,
    advertiser: data.advertiser,
    image_url: data.image_url,
    name: data.name,
    url_link: data.url_link,
    visible: data.visible,
    memo: data.memo,
  });

  const ACCESS_KEY = "AKIASCKXHNIYMQBREL6F";
  const SECRET_KEY = "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";
  const REGION = "ap-northeast-2";
  const S3_BUCKET = "dfx-img";

  // AWS S3 설정
  AWS.config.update({
    accessKeyId: ACCESS_KEY,
    secretAccessKey: SECRET_KEY,
    region: REGION,
    logger: console,
  });

  const myBucket = new AWS.S3({
    params: { Bucket: S3_BUCKET },
  });

  const handleUpload = async () => {
    if (!selectedFile) {
      const imgUrl = formData.image_url;

      console.log(typeof formData.id);
      console.log(typeof formData.visible);

      handleSubmit(imgUrl);
      return;
    }

    const params = {
      Body: selectedFile,
      Bucket: S3_BUCKET,
      Key: `upload/${selectedFile.name}`,
    };

    try {
      const data = await myBucket.upload(params).promise();
      console.log("File uploaded successfully. ");

      const tempUrl = myBucket.getSignedUrl("getObject", {
        Key: params.Key,
      });

      const imgUrl = tempUrl.split("?")[0];

      handleSubmit(imgUrl);
    } catch (err) {
      console.error("Error uploading file:", err);
    }
  };

  const handleSubmit = async (imgUrl) => {
    try {
      const res = await client.mutate({
        mutation: MODIFY_AD_BANNER,
        variables: {
          id: parseInt(formData.id),
          account_id: parseInt(formData.account_id),
          advertiser: formData.advertiser,
          name: formData.name,
          image_url: imgUrl,
          url_link: formData.url_link,
          visible: Boolean(formData.visible),
          memo: formData.memo,
        },
      });
      console.log("success!:", res);
      window.location.href = "/adminad";
    } catch (err) {
      console.error(err);
    }
  };

  const handleChange = (event) => {
    const { name, value } = event.target;

    setFormData({ ...formData, [name]: value });
  };

  const handleFileChange = (e) => {
    const file = e.target.files[0];
    setSelectedFile(file);
    setSelectedImg(URL.createObjectURL(file));
  };

  useEffect(() => {
    return () => {
      if (selectedImg && selectedImg.startsWith("blob:")) {
        URL.revokeObjectURL(selectedImg);
      }
    };
  }, [selectedImg]);

  return (
    <HomeContainer>
      <HomeInnerContainer>
        <TopMenu title={"AD Details"} />
        <form>
          <FormControl fullWidth sx={{ marginBottom: 2, marginTop: 2 }}>
            <TextField
              label="id"
              id="id"
              name="id"
              value={formData.id}
              disabled
            ></TextField>
          </FormControl>

          <FormControl fullWidth sx={{ marginBottom: 2 }}>
            <TextField
              label="account_id"
              id="account_id"
              name="account_id"
              value={formData.account_id}
              onChange={handleChange}
            ></TextField>
          </FormControl>

          <FormControl fullWidth sx={{ marginBottom: 2 }}>
            <TextField
              label="advertiser"
              id="advertiser"
              name="advertiser"
              value={formData.advertiser}
              onChange={handleChange}
            ></TextField>
          </FormControl>

          <FormControl fullWidth sx={{ marginBottom: 2 }}>
            <TextField
              label="name"
              id="name"
              name="name"
              value={formData.name}
              onChange={handleChange}
            ></TextField>
          </FormControl>

          <FormControl fullWidth sx={{ marginBottom: 2 }}>
            <TextField
              label="url_link"
              id="url_link"
              name="url_link"
              value={formData.url_link}
              onChange={handleChange}
            ></TextField>
          </FormControl>

          <FormControl fullWidth sx={{ marginBottom: 2 }}>
            <InputLabel>visible</InputLabel>
            <Select
              label="visible"
              name="visible"
              value={formData.visible}
              onChange={handleChange}
            >
              <MenuItem value={true}>노출</MenuItem>
              <MenuItem value={false}>비노출</MenuItem>
            </Select>
          </FormControl>

          <FormControl fullWidth sx={{ marginBottom: 2 }}>
            <TextField
              label="memo"
              id="memo"
              name="memo"
              value={formData.memo}
              onChange={handleChange}
            ></TextField>
          </FormControl>

          <FormControl fullWidth sx={{ marginBottom: 2 }}>
            <ImageContainer>
              <img src={selectedImg} alt={data.name} />
            </ImageContainer>

            <Input type="file" onChange={handleFileChange} />
          </FormControl>

          <Button fullWidth onClick={handleUpload}>
            Upload
          </Button>
        </form>
      </HomeInnerContainer>
      <Footer></Footer>
    </HomeContainer>
  );
};

const ImageContainer = styled.div`
  width: 360px;

  img {
    width: 100%;
    height: auto;
    max-height: 250px;
    display: block;
  }
`;
