
import React, { useState, useEffect } from 'react';
import { client } from "../client"
import { useNavigate } from "react-router-dom";
import { useParams } from 'react-router-dom';
import { gql } from "@apollo/client";
import { Container , RightStartDiv} from '../component/company/Company.module';
import { TopMenu } from '../component/_molecule/TopMenu';

export const Company = ({}) => {
  

  return (
    <div>
      <TopMenu title = '회사 소개'></TopMenu>
      <Container> 
        <br/>
        <div>㈜디에프피알은 여행, 쇼핑 빅데이터 및 AI 기반 트레블테크 기업으로서 면세쇼핑 편의를 혁신하는 역사적 사명을 가지고 있습니다. 면세상품의 최저 가격을 신속, 정확하게 구매고객에게 제공함으로써 스마트하고 선진적인 쇼핑체험을 제공해 나가고 있습니다. </div>
        <br/>
        <h3>‘면세가격비교’(Duty Free Price) 모바일 서비스의 장점</h3>
        <br/>
        <div>
        <li> <strong>최저가로 구매할 수 있다. </strong>소비자는 인터넷 면세점의 회원가격 기준 최저가 정보를 한 눈에 보고 빠르게 구매를 결정할 수 있습니다. 
        </li>
        <br/>
        <li> <strong>시간과 비용을 절약할 수 있다. </strong>소비자는 국내외 주요 인터넷 면세점 사이트 및 앱에서 각각 개별적으로 수행하던 제품가격 비교 시간을 획기적으로 단축할 수 있습니다.
        </li>
        <br/>
        <li> <strong>편리하게 이용할 수 있다. </strong>면세상품의 회원 할인가격을 확인하기 위해서는 각각의 인터넷 면세점 사이트 별로 회원가입 및 로그인해야 했던 불편함을 완전 해소하여, 고객은 상품검색 후 즉각적으로 최저가 판매 상품페이지로 이동하여 구매가 가능합니다. 
        </li>
        <br/>
        <li> <strong>변동이 심한 면세품 가격이 매일 업데이트 된다. </strong>면세점의 상품 가격은 환율, 물류비용, 판매량 등의 변수에 따라 수시로 변동될 수 있기에 매일 면세품의 가격을 업데이트 합니다.
        </li>
        <br/>
        <li>‘면세가격비교’ 서비스는 모바일 앱, 인터넷-모바일 브라우저를 통해 사용 가능합니다.
        </li>
        <div>
        <br/>
        <div>
        ‘면세가격비교’ 서비스는 해외여행 전 면세점 최저가를 한 눈에 확인하고 소비자의 합리적인 소비를 돕는 유용한 서비스입니다. 
        </div>
         <div>
        향후, ‘면세가격비교’ 서비스는 해외 공항 및 면세점과의 연계로 여행자의 여행경로 상, 면세상품의 최저가격 정보를 제공하고, 아울러 아직 상품화 되지 않은 현지 여행정보 및 맛집 정보를 함께 제공하여 글로벌 서비스로의 성장을 꿈꾸며 ㈜디에프피알은 힘차게 달리고 있습니다. 
        </div>
        <br/>
        <br/>
        <RightStartDiv>
          <h2>
            대표이사 이창원
          </h2>
        </RightStartDiv>
        </div>
        </div>

     </Container>
    </div>
  );
};

