import React from "react";

import whiskyLogo from "../img/UnionLogo.svg";

export const ExplanationModal = ({ onClose }) => {
  return (
    <div className="z-20 font-CustomSans fixed inset-0 flex items-center justify-center">
      <div className="bg-white z-50 py-6 rounded-[20px] shadow-lg max-w-md w-[370px] relative">
        <div className="text-center">
          <div className=" flex justify-center mt-[15px]">
            <img src={whiskyLogo} alt="whisky365" />
          </div>
          <div className=" flex justify-center mt-[30px]">
            <span className="font-bold text-[18px] leading-[21px]">
              프리미엄 위스키 한정 수량 특가
            </span>
          </div>
          <div className="text-[16px] text-black leading-[23px] px-0 text-pretty mt-[30px] font-normal">
            면세점가격비교 앱 X 부산면세점, <br />
            프리미엄 위스키를 세계 최저가에 만나보세요. <br /> 빠른 구매 필수!
          </div>

          <div className="rounded-lg mb-[30px] mt-[12px] text-[13px] leading-[20px] font-normal text-Gray70">
            *현재는 인천공항, 김해공항 출국 예정자만 <br /> 출국 전 48시간까지
            주문 가능합니다.
          </div>

          <div className="px-6 w-full flex flex-col items-center">
            <button
              onClick={() => onClose()}
              className="bg-black text-white font-semibold py-3 px-12  w-full"
            >
              확인
            </button>
          </div>
        </div>
      </div>

      <div
        className="fixed inset-0 bg-black opacity-50 z-2"
        onClick={onClose}
      ></div>
    </div>
  );
};

export default ExplanationModal;
