import styled from "styled-components";


export const Layout = styled.div`
  // width: 459px;
  height: 280px;
  // margin: 54px 0 0;
  background-color: #f2f2f2;
  padding-left: 30px;
  padding-bottom: 100px;
  box-sizing: border-box; /* padding을 width에 포함시키기 위해 box-sizing을 설정 */

  @media (max-width: 400px) {
    width: 360px;
  }
`;

export const Title = styled.div`  
font-family: Pretendard;
font-size: 23px;
font-weight: normal;
font-stretch: normal;
font-style: normal;
line-height: 0.96;
letter-spacing: -0.23px;
text-align: left;
color: #777;

margin-bottom: 10px;
padding-top: 27px;

`

export const ContentItem = styled.div`    
font-family: Pretendard;
font-size: 13px;
font-weight: normal;
font-stretch: normal;
font-style: normal;
line-height: 1.69;
letter-spacing: -0.13px;
text-align: left;
color: #777;
`

export const CopyrightItem = styled.div`    
font-family: Pretendard;
font-size: 12px;
font-weight: normal;
font-stretch: normal;
font-style: normal;
line-height: 1.83;
letter-spacing: -0.12px;
text-align: left;
color: #777;

margin-top: 19px;
`