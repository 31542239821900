import styled from "styled-components";


export const SnsShareContainer = styled.div`  
  display: flex;
  flex-direction: row;

  width: 100%;
  align-items: center;
  justify-content: center;

  position: absolute;
  bottom : 185px;

  
`
export const BtnSet = styled.div`  
  display: flex;
  flex-direction: row;

  width: 100%;
  justify-content: space-around; 
  
`
export const SnsShareBtnContainer = styled.div`
    background-image: url( ${props => props.url} );
    background-size: cover;
    width : 50px;
    height : 50px;
    margin-left: 10px;
    margin-right: 10px;
`