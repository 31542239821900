
import { AppBarMenu } from '../../style/BottonAppBar.module.js'
import React, { useState, useEffect } from 'react';
import { useNavigate } from "react-router-dom";
import Drawer from '@mui/material/Drawer';
import { SnsShareBtnContainer } from './SnsShare.module.js';


export const SnsShareBtn = ({ type }) => {
  let kakao = 'https://dfx-img.s3.ap-northeast-2.amazonaws.com/btn_share_kakao%402x.png'
  let sms = 'https://dfx-img.s3.ap-northeast-2.amazonaws.com/btn_share_sms%402x.webp'
  let url = 'https://dfx-img.s3.ap-northeast-2.amazonaws.com/btn_share_sms%402x.png'
  let imgSrc = null
  if( type == 'KAKAO') {
    imgSrc = kakao
  } else if ( type == 'SMS') {
    imgSrc = sms
  } else {
    imgSrc = url
  }
   
  return (
    <SnsShareBtnContainer url = {imgSrc}>
    </SnsShareBtnContainer>
  );
}

