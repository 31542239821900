import { gql } from "@apollo/client";

export const GET_COUPON = gql`
query getCoupon($couponId:Int) {
  getCoupon(couponId:$couponId) {
    id
    category
    title
    main_image_url
    coupon_image {
      origin_image_url
    }
  }
}
`;

export const GET_AIA_COUPON = gql`
  query getAIAEventCoupon {
    getAIAEventCoupon {
      id
      name
      category
      type
      imageUrl
      imageDetailUrl
    }
  }
`;