import { gql } from "@apollo/client";

export const CREATE_REVIEW = gql`
  mutation createReview(
    $productID: Int!
    $userID: Int!
    $rating: Int!
    $comment: String!
    $quality_score: Int!
    $repurchase_score: Int!
    $photo_urls: [String]!
  ) {
    createReview(
      productID: $productID
      userID: $userID
      rating: $rating
      comment: $comment
      quality_score: $quality_score
      repurchase_score: $repurchase_score
      photo_urls: $photo_urls
    )
  }
`;

export const GET_WHISKY_PRODUCTS = gql`
  query getWhiskyProducts($companyId: Int!) {
    getWhiskyProducts(companyId: $companyId) {
      id
      img_url
      name
      name_eng
      price_normal
      price_member
      tot_stock
      reg_stock
    }
  }
`;


export const GET_WHISKY_PRODUCTS_DETAIL = gql`
  query getWhiskyProductDetail($id: Int!) {
    getWhiskyProductDetail(id: $id) {
      id
      img_url
      name
      name_eng
      price_normal
      price_member
      reg_stock
      tot_stock
      bs_comparisons {
        price_member
        duty_free_site
        logo_url
      }
      product_description_img_url
      goods_seq
      prev_next_info {
        prev_item_id
        next_item_id
      }
    }
  }
`;

export const GET_EXCHANGERATE = gql`
  query getExchangeRate {
    getExchangeRate {
      id
      rate
      date
      created_at
      updated_at
    }
  }
`;

export const GET_REVIEW_RATING = gql`
  query getReviewPage($productId: Int!) {
    getReviewPage(productId: $productId) {
      average_rating
      quality_satisfaction
      repurchase_willingness
      total_reviews {
        id
        rating
      }
    }
  }
`;

export const GET_REVIEW_BEST = gql`
  query getReviewPage($productId: Int!) {
    getReviewPage(productId: $productId) {
      best_review_count
      best_reviews {
        id
        rating
        comment
        user {
          nickname
        }
        photos {
          url
        }
      }
      total_reviews {
        id
        rating
        comment
        created_at
        user {
          nickname
        }
        quality_score
        repurchase_score
        photos {
          url
        }
      }
    }
  }
`;

export const SIGN_UP_BSDF = gql`
  mutation signUpWithBSDF($input: create_user_input_with_bsdf) {
    signUpWithBSDF(create_user_input_with_bsdf: $input)
  }
`;

export const GET_ORDERS = gql`
  query getOrders($userid: Int!) {
    getOrders(userid: $userid) {
      id
      order_seq
      member_seq
      exchange_no
      settleprice
      regist_date
      goods_seq
      goods_name
      item_seq
      image
      d_area
      d_date
      d_time
      d_minute
      d_ship
      product {
        id
        name
        price_member
        img_url
      }
    }
  }
`;
