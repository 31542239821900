import styled from "styled-components";



export const MostViewdItemContainer = styled.div`
    display: flex; 
    flex-direction: row;

    overflow-x: auto; /* Allow horizontal scrolling */
    white-space: nowrap; /* Prevent line breaks for child items */
    max-width: 100%; /* Allow the container to expand horizontally */

    margin-bottom : 49px;

`
export const MostViewdItem = styled.div`
    display: flex; 
    flex-direction: column;

    margin-right : 12px;
`

export const ItemImg = styled.img`  
    width: 122px;
    height: 122px;
    margin: 0 0 8px;
    background-color: #e2e2e2;
`
export const ItemBrand = styled.div`  
    width: 120px;
    font-family: Pretendard;
    font-size: 12px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: left;
    color: #444;
    overflow: hidden;     
    text-overflow: ellipsis;
    margin-bottom: 8px;
`

export const ItemName = styled.div`  
    font-family: Pretendard;
    font-size: 13px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.31;
    letter-spacing: normal;
    text-align: left;
    color: #444;   
    /* 넘치는 텍스트를 숨기기 */
    overflow: hidden;     
    text-overflow: ellipsis;
    overflow: hidden;     
    text-overflow: ellipsis;

`

export const ItemPrice = styled.div`    
    font-size: 16px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: left;
    color: #000;
}
`


export const RankStatusContaienr = styled.div`

    display: flex; 
    flex-direction: row-reverse;
    align-items: center; /* Vertically align the content to the center */
    margin-right: 30px;

    font-family: Pretendard;
    font-size: 12px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: left;
    color: #777;

    & > *:nth-child(2) {
        /* Styling for the second child element */  
        margin-right: 5px;
    }

`
export const RealTimePopularContainer = styled.div`
    display: grid;
    grid-template-columns: 1fr 1fr; /* Two columns with 50% width each */
    grid-template-rows: repeat(5, 1fr); /* Five rows with equal height */

    margin-top: 27px;
`


export const RealTimePopularItem = styled.div`

    display: flex; 
    flex-direction: row;

    display: grid;
    grid-template-columns: 10% 60% 30%;

    height: 46px;


    & > *:nth-child(1) {
        /* Styling for the second child element */  
        font-family: Pretendard;
        font-size: 14px;
        font-weight: 600;
        font-stretch: normal;
        font-style: normal;
        line-height: normal;
        letter-spacing: normal;
        text-align: center;
    }


    & > *:nth-child(2) {
        /* Styling for the second child element */  
        font-family: Pretendard;
        font-size: 14px;
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        line-height: normal;
        letter-spacing: normal;
        text-align: left;
        color: #000;
    }
    
`
export const KeywordContainer = styled.div`
    display : flex;
    flex-wrap: wrap; /* Allow children to wrap onto new lines */
`

export const KeywordItem = styled.div`
    display: inline-block; /* Make it inline-level, allows variable width */

    white-space: normal; /* Allow line breaks */

    display : flex;  
    border-radius: 18px;
    border: solid 1px #c7c7c7;
    background-color: #fff;

    padding-top: 10px;
    padding-left: 14px;
    padding-bottom: 10px;
    padding-right: 14px;
    margin-right : 8px;
    margin-bottom : 8px;

    font-family: Pretendard;
    font-size: 14px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: left;
    color: #444;
    
`