
import React, { useState, useEffect } from 'react';
import { Layout, Container, TitleContainer, 
    CuoponListContainer, CuoponItem
} from '../../style/Home/AirportCouponSection.module.js'
import { CategoryContainer, CategoryChip, 
} from '../../style/Home/RecommendSection.module.js'
import { ImageList, ImageListItem } from '@mui/material';

import { ButtonSeeMore } from '../../style/common.module.js';
import { useNavigate } from "react-router-dom";
import { TabMenu } from '../_molecule/TabMenu.js';
import { PostCardList } from './PostDisplay.module.js';
import { PostCardV2 } from '../_molecule/PostCardV2.js';

export const PostDisplayLayout = () => {


  const [selectedCategory, setSelectedCategory] = useState('현지맛집');
  const categories = [
    '현지맛집',
    '특별한 경험',
    '환상의 장소',
    '쇼핑 가이드',
];

const itemData = [
  {
    id:1,
    img : 'https://dfx-img.s3.ap-northeast-2.amazonaws.com/img_travel_info01%402x.jpg', 
    title: '영국 런던', 
    desc: '런던으로 여행간다면? \n필수 쇼핑 리스트', 

  },
  {
    id:2,
    img : 'https://dfx-img.s3.ap-northeast-2.amazonaws.com/img_travel_info02%402x.jpg', 
    title: '프랑스 파리 2', 
    desc: '사랑과 낭만의 도시\n파리에서의 여행 꿀팁', 

  },
  {
    id:3,
    img : 'https://dfx-img.s3.ap-northeast-2.amazonaws.com/img_travel_info01%402x.jpg', 
    title: '영국 런던 2', 
    desc: '런던으로 여행간다면? \n필수 쇼핑 리스트',  
  },
  {
    id:4,
    img : 'https://dfx-img.s3.ap-northeast-2.amazonaws.com/img_travel_info02%402x.jpg', 
    title: '프랑스 파리', 
    desc: '사랑과 낭만의 도시\n파리에서의 여행 꿀팁', 

  },
]
console.log(itemData.length)
const handleCategorySelect = (category) => {
  setSelectedCategory(category);
}

  return (
    <Layout>
      <Container >
        <CategoryContainer> 
              {categories.map(category => (
                <CategoryChip
                    key={category}
                    active={selectedCategory === category}
                    onClick={() => handleCategorySelect(category)}
                >
                    {category}
                </CategoryChip>
            ))}
        </CategoryContainer>
            
        <PostCardList>
            {itemData.map((item) => (
            <a href="https://blog.naver.com/dutyfreeprice2023">
              
                <PostCardV2 key={item['id']} imgSrc = {item.img} title = {item.title} desc={item.desc}></PostCardV2>
            </a>
            ))}
        </PostCardList>
       
      </Container>
    </Layout>
  );
}

