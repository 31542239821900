import React, { useState, useEffect } from "react";
import { useMutation, gql } from "@apollo/client";
import { client } from "../../client";

// GraphQL mutation for resetting the password
const RESET_PASSWORD = gql`
  mutation ResetPassword($token: String!, $password: String!) {
    resetPassword(token: $token, password: $password)
  }
`;

export const ResetPassword = () => {
  const [token, setToken] = useState("");
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [error, setError] = useState("");
  const [message, setMessage] = useState("");
  const [isSubmitted, setIsSubmitted] = useState(false);

  // Extract token from URL
  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search);
    const tokenParam = urlParams.get("token");
    if (tokenParam) {
      setToken(tokenParam);
    } else {
      setError("유효하지 않은 링크입니다.");
    }
  }, []);

  const handlePasswordChange = (e) => {
    setPassword(e.target.value);
    setError("");
    setMessage("");
  };

  const handleConfirmPasswordChange = (e) => {
    setConfirmPassword(e.target.value);
    setError("");
    setMessage("");
  };

  const validatePassword = (password) => {
    return password.length >= 6; // Example: enforce minimum length
  };

  const handleSubmit = async () => {
    setError(""); // Reset existing error state

    if (!validatePassword(password)) {
      setError("비밀번호는 최소 6자 이상이어야 합니다.");
      return;
    }

    if (password !== confirmPassword) {
      setError("비밀번호가 일치하지 않습니다.");
      return;
    }

    try {
      const result = await client.mutate({
        mutation: RESET_PASSWORD,
        variables: { token, password },
      });

      if (result.data.resetPassword) {
        setIsSubmitted(true);
        setMessage("비밀번호가 성공적으로 재설정되었습니다.");
      } else {
        setError("비밀번호 재설정에 실패했습니다.");
      }
    } catch (error) {
      console.error("에러 발생:", error); // Log error details
      setError("비밀번호 재설정에 실패했습니다.");
    }
  };

  return (
    <div style={styles.outerContainer}>
      <div style={styles.container}>
        <h2 style={styles.title}>비밀번호 재설정</h2>
        <p style={styles.description}>새로운 비밀번호를 입력해 주세요.</p>
        <input
          type="password"
          placeholder="새 비밀번호 입력"
          value={password}
          onChange={handlePasswordChange}
          style={styles.input}
        />
        <input
          type="password"
          placeholder="비밀번호 확인"
          value={confirmPassword}
          onChange={handleConfirmPasswordChange}
          style={styles.input}
        />
        <button
          onClick={handleSubmit}
          disabled={isSubmitted}
          style={isSubmitted ? styles.disabledButton : styles.button}
        >
          {isSubmitted ? "비밀번호 재설정 완료" : "비밀번호 재설정"}
        </button>
        {error && <p style={styles.error}>{error}</p>}
        {message && <p style={styles.message}>{message}</p>}
      </div>
    </div>
  );
};

const styles = {
  outerContainer: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    height: "100vh",
  },
  container: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    width: "100%",
    maxWidth: "500px",
    minWidth: "350px",
    margin: "0 auto",
    padding: "20px",
    border: "1px solid #ddd",
    borderRadius: "8px",
    boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
    transform: "translateY(-70%)",
  },
  title: {
    fontSize: "24px",
    fontWeight: "bold",
    color: "#333333",
    marginBottom: "10px",
  },
  description: {
    fontSize: "16px",
    color: "#666666",
    marginBottom: "20px",
  },
  input: {
    padding: "12px",
    marginBottom: "15px",
    width: "100%",
    borderRadius: "4px",
    border: "1px solid #ccc",
  },
  button: {
    padding: "12px 20px",
    backgroundColor: "#4CAF50",
    color: "#ffffff",
    border: "none",
    borderRadius: "4px",
    cursor: "pointer",
    width: "100%",
  },
  disabledButton: {
    padding: "12px 20px",
    backgroundColor: "#cccccc",
    color: "#666666",
    border: "none",
    borderRadius: "4px",
    cursor: "not-allowed",
    width: "100%",
  },
  error: {
    color: "red",
    marginTop: "15px",
  },
  message: {
    color: "green",
    marginTop: "15px",
  },
};
