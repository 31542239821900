import styled from "styled-components";


export const AppBarMenu = styled.div`
    display: flex; 
    flex-direction: row;
    justify-content: center;
    align-items: center;
    flex-grow:1;
    height: 64px;
`
