import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import { TopMenu } from '../component/_molecule/TopMenu';
import { HomeContainer } from '../style/Home/Home.module';
import { PostDisplayLayout } from '../component/post/PostDisplayLayout';

export const Post = () => {
  return (
    <HomeContainer >
      <TopMenu title = '여행정보'></TopMenu>
      <PostDisplayLayout></PostDisplayLayout>
   </HomeContainer >
  );
}

