
import React, { useState, useEffect } from 'react';
import { client } from "../client"
import { useNavigate } from "react-router-dom";
import { useParams } from 'react-router-dom';
import { gql } from "@apollo/client";
import { AgreementUse } from '../component/agreement/AgreementUse';
import { AgreementPrivacy } from '../component/agreement/AgreementPrivacy';

export const Agreement = ({}) => {
  const { agreementId } = useParams();
  

  return (
    <div>
      <div> {agreementId == 1 ? <AgreementUse/> : <AgreementPrivacy/>
        } </div>
    </div>
  );
};

