import styled from "styled-components";


export const PostCardList = styled.div`
    display: flex;
    flex-direction: row;
    display: flex;
    flex-wrap: wrap; /* 요소들이 한 줄에 두 개씩 나열되도록 설정 */

    margin-top : 24px;
    gap: 11px;
    justify-content: center;
`
