
import React, { useState, useEffect } from 'react';
import { SearchContainer, 
  StyledInput , 
  Layout, 
  Container, 
  SearchButton} from '../search/search.module'
import { client } from "../../client"
import { useNavigate } from "react-router-dom";
import { gql } from "@apollo/client";
import debounce from 'lodash/debounce'; // Import debounce
import { AutoCompleteBox, AutoCompleteText, AutoCompleteContainer } from '../search/search.module';
import { RealTimePopularContainer, RealTimePopularItem, RankStatusContaienr } from './SearchRank.module';
import RankUpImg from '../../resources/rank_up.png';
import RankDownImg from '../../resources/rank_down.png';

export const RealTimePopular = ({ data }) => {

    const navigate = useNavigate(); // Initialize useNavigate


    const getRandomRankStatus = () => {
        const rankStatusOptions = [
        <RankStatusContaienr>
            <div>1</div>
            <img src={RankUpImg} alt="뒤로가기" style={{ width: "9px", height: "8px" }} />
            
        </RankStatusContaienr>,
        <RankStatusContaienr>
            <div>1</div>
            <img src={RankDownImg} alt="뒤로가기" style={{ width: "9px", height: "8px" }} />
            
        </RankStatusContaienr>,
        <RankStatusContaienr>-</RankStatusContaienr>,
        ];
        const randomIndex = Math.floor(Math.random() * rankStatusOptions.length);
        return rankStatusOptions[randomIndex];
    };

  

  return (
    <div>
      <h3>실시간 인기 검색어</h3>
      <RealTimePopularContainer>
        {data?.map((item, idx) => {
            return <RealTimePopularItem>
                    <div>{item['rank']}</div> 
                    <div  
                      onClick={(e) => {
                          e.preventDefault(); // Prevent the default anchor behavior
                          navigate(`/search?keyword=${encodeURIComponent(item['name'])}`); // Use navigate to handle navigation
                        }}>{item['name']}
                    </div>
                    <div>{getRandomRankStatus()}</div>
                </RealTimePopularItem>
        })}
      </RealTimePopularContainer>
    </div>
  );
};

