export const PurchaseModal = ({ onClose, purchaseUrl, setIframeUrl }) => {
  const confirm = async () => {
    // 현재 브라우저가 iOS 또는 Safari인지 확인하는 함수
    const isIOSOrSafari = () => {
      const ua = navigator.userAgent.toLowerCase();
      return (
        (/safari/.test(ua) && !/chrome/.test(ua)) || /iphone|ipad|ipod/.test(ua)
      );
    };

    // alert("purchaseUrl: " + purchaseUrl);
    await onClose();

    // Open the purchase URL in the default browser
    window.open(purchaseUrl, "_system"); // or '_blank' for new tab

    // window.open(purchaseUrl, "_blank"); // 새 탭이나 창에서 URL 열기

    // if (isIOSOrSafari()) {
    //   window.location.href = purchaseUrl; // URL로 직접 이동
    // } else {
    //   setIframeUrl(purchaseUrl);
    // }

    window.scrollTo({ top: 0, behavior: "smooth" });
  };

  return (
    <section className="z-20 bottom-0 left-0 right-0 font-CustomSans fixed flex items-center justify-center w-full min-w-[320px]">
      <div className="h-[172px] bg-white z-50 w-full max-w-[400px] rounded-t-[20px] shadow-lg relative">
        <div className="mt-[40px] text-center px-8">
          <span className="text-[16px] leading-[23px] font-normal text-pretty">
            현재는 인천공항, 김해공항 출국 예정자만 <br /> 출국 전 48시간까지
            주문 가능합니다.
          </span>
        </div>

        <div className="w-full max-h-[50px] flex flex-col items-center absolute bottom-0">
          <button
            onClick={confirm}
            className="bg-black text-white font-semibold py-3 w-full"
          >
            확인
          </button>
        </div>
      </div>

      <div
        className="fixed inset-0 bg-black opacity-50 z-8"
        onClick={onClose}
      ></div>
    </section>
  );
};
