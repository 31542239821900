
import React, { useState, useEffect } from 'react';
import styled from "styled-components";
import { useNavigate } from "react-router-dom";

const Layout = styled.div`
  height: 120px;
  display: flex;
  align-items: center;
  justify-content: center; /* 추가: 수평 가운데 정렬 */
`;

export const BottomBanner = () => {
  const navigate = useNavigate(); // Initialize useNavigate

  return (
    <Layout>
      <img
        src="https://dfx-img.s3.ap-northeast-2.amazonaws.com/%E1%84%80%E1%85%A5%E1%86%B7%E1%84%89%E1%85%A2%E1%86%A8%E1%84%92%E1%85%AA%E1%84%86%E1%85%A7%E1%86%AB+%E1%84%8B%E1%85%B5%E1%84%83%E1%85%A9%E1%86%BC+%E1%84%87%E1%85%A5%E1%84%90%E1%85%B3%E1%86%AB+.png"
        style={{ height: "auto", width: "320px" }}
        onClick={() => navigate(`/search`)}
      ></img>
    </Layout>
  );
};

