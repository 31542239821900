
import React, { useState, useEffect } from 'react';
import { useQuery } from '@apollo/client';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper } from '@mui/material';

import { PostAdminAddButton } from './PostAdminAddButton';


import { gql } from "@apollo/client";
import { client } from "../../../client"

// GraphQL 쿼리
const GET_ALL_POST = gql`
query getAllPostList {
    getAllPostList {
        id
      title
      subtitle
      link_url
    }
  }
`;
const DELETE_POST = gql`
mutation deletePost($id:Int!) {
    deletePost(id:$id) 
  }
`;

export const PostAdminSection = () => {

    const [posts, setPosts] = useState([]);

    // Function to fetch the updated list of posts
    const fetchPosts = () => {
        client
            .query({
                query: GET_ALL_POST,
                variables: {}
            })
            .then((result) => {
                console.log(result);
                setPosts(result.data.getAllPostList);
            })
            .catch((error) => {
                console.log(error);
                alert(error.message);
            });
    };

    // Function to update the posts state (called from child component)
    const updatePosts = (newPost) => {
        // Create a new array with the updated post added
        const updatedPosts = [...posts, newPost];
        setPosts(updatedPosts);
    };

    const handleDeleteButton = (id) => {
        client
            .mutate({
                mutation: DELETE_POST,
                variables: {
                    "id": id
                }
            })
            .then((result) => {
                // console.log('식제완료',result);
                alert(JSON.stringify(result));
                window.location.reload(false);
                // // After successful delete, fetch the updated list of posts
                // fetchPosts(); // 변경된 부분: 삭제 후에 데이터를 다시 가져오는 fetchPosts 함수를 여기에서 호출합니다.
                

            })
            .catch((error) => {
                console.log(error);
            });
    };
    useEffect(() => {
        // Fetch the initial list of posts
        fetchPosts();
    }, []);


  
    return (
        <div style={{margin: '2rem'}}>
            <PostAdminAddButton updatePosts={updatePosts} ></PostAdminAddButton>
            <h3> 포스팅 리스트 </h3>

            <Table>
                <TableHead>
                <TableRow>
                    <TableCell><h3>id</h3></TableCell>
                    <TableCell><h3>제목</h3></TableCell>
                    <TableCell><h3>부제목</h3></TableCell>
                    <TableCell><h3>블로그 링크</h3></TableCell>
                    <TableCell><h3>이미지</h3></TableCell>
                </TableRow>
                </TableHead>
                <TableBody>
                {posts.map((post, index) => (
                    <TableRow key={index}>
                    <TableCell>{post.id}</TableCell>
                    <TableCell>{post.title}</TableCell>
                    <TableCell>{post.subtitle}</TableCell>
                    <TableCell>{post.link_url}</TableCell>
                    <TableCell>
                        <img
                            src={post.post_images?.[0]?.img_url}
                            style={{ width: '100px', height: '100px' }} // 너비와 높이를 원하는 크기로 조정
                            alt={`Coupon Image for ${post.title.slice(0,5)}`}
                        />
                    </TableCell>
                    <TableCell>
                        <Button variant="contained" color="grey" onClick={() => handleDeleteButton(post.id)}>
                            삭제
                        </Button>
                    </TableCell>
                    </TableRow>
                ))}
                </TableBody>
            </Table>
            {/* <ul>
                {coupons.map((coupon, index) => (
                <li key={index}>{coupon.title}
                
                </li>
                ))}
            </ul> */}
        </div>
    );
}

