import styled from "styled-components";


export const TopMenu = styled.div` 
  width: 100%;
  height: 62px;
  background-color: #fff;
  z-index : 100;
  position: absolute;
  top: 0;
  padding-left: 2rem;

  display: flex;
  flex-direction: row;
  align-content: space-between;
  justify-content: center;

`

export const MenuItem = styled.div` 
    flex: 1;
    align-self: center;

`


