import styled, { keyframes } from "styled-components";


export const Container = styled.div`
  margin-left : 20px;
  margin-right : 20px;
  margin-top : 20px;
`

export const Table = styled.table`
  margin : 30px;
  border: 2px solid #dddddd; /* Set border for the entire table */


  th, td {
    border: 1px solid #dddddd; /* Set border for table cells */
    // text-align: left;
    padding: 8px;
  }
  th {
    background-color: #f2f2f2;
  }

`

export const CenterContainer = styled.div`
  margin-top: 30px;
  padding-top: 30px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border-top: 2px solid #000; /* 위에 줄 추가 */
`