import React from "react";
import styled from "styled-components";


const BottomComponent = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: white;
  width: 100%;
  padding-top: 1rem;
  padding-bottom: 1rem;

  max-width: 460px; /* 최대 너비를 360px로 설정 */
  text-align: center;
`;

const Link = styled.a`
  text-decoration: none;
  color: #0000ee;
`;

const Image = styled.img`
  margin: 60px;
  width: 360px; /* 이미지가 컨테이너의 너비에 맞추도록 설정 */
  height: auto; /* 이미지의 비율을 유지하며 높이를 자동으로 설정 */
  margin: 0 auto; /* 이미지를 중앙 정렬 */
`;

const BottomAd = () => {
  return (
    <BottomComponent>
      <Link
        href="https://dud6.short.gy/klimosales
"
      >
        <Image
          src="https://dfx-img.s3.ap-northeast-2.amazonaws.com/limo2.png"
          alt="Limo"
        />
      </Link>
    </BottomComponent>
  );
};

export default BottomAd;
