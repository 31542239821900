
import React, { useState, useEffect } from 'react';
import { useQuery } from '@apollo/client';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper } from '@mui/material';



import { gql } from "@apollo/client";
import { client } from "../../../client"

// GraphQL 쿼리
const CREATE_COUPON = gql`
mutation createCoupon($createCouponInput:CreateCouponInput) {
    createCoupon(createCouponInput:$createCouponInput) {
      is_success
      message
    }
  }
`;

export const CouponAdminAddButton = () => {


    const [name, setName] = useState('');
    const [category, setCategory] = useState('');
    const [location, setLocation] = useState('');
    const [image, setImage] = useState(null); // 이미지를 파일 형태로 저장


     // 이미지를 선택했을 때 호출되는 함수
     const handleImageChange = (event) => {
        const selectedImage = event.target.files[0];
        setImage(selectedImage);
    }

    // 분류를 선택했을 때 호출되는 함수
    const handleCategoryChange = (event) => {
        setCategory(event.target.value);
    }

    // 추가 버튼을 눌렀을 때 호출되는 함수
    const handleAddCoupon = () => {
        // name, category, location, image 변수를 이용하여 GraphQL mutation을 수행하여 데이터를 추가할 수 있음
        // 추가하는 로직을 작성하세요.
        if(name == '' || location=='') {
            alert('이름과 장소를 입력해주세요')
        }
        client
        .mutate({
            mutation: CREATE_COUPON,
            variables: {
                "createCouponInput": {
                    "category": category, 
                    "title" :name, 
                    "location": location, 
                    "coupon_provider": {
                      "name" : "파리바게뜨"
                    },
                    "main_image_url" :'https://www.smarttravel.co.kr/wp-content/uploads/sites/1/2020/12/%E1%84%80%E1%85%B5%E1%86%B7%E1%84%91%E1%85%A9-%E1%84%87%E1%85%A2%E1%86%A8%E1%84%86%E1%85%B5%E1%84%83%E1%85%A1%E1%86%BC-1.jpg', 
                    "coupon_images": {
                      "resized_image_url": "https://m.bonnydesign.co.kr/web/product/big/20200224/1d25ac3ddc0c8efd52f9abdc6cb6d7b4.png"
                    }
                    
                  }
            }
        })
        .then((result) => {
            console.log(result)
            alert(JSON.stringify(result))
            window.location.reload(false);
        })
        .catch((error) => {
            console.log(error);
        });

    }

  
    return (
        <div style={{marginTop: '2rem', marginBottom: '4rem'}}>
            <h3> 쿠폰추가 </h3>
            <div>이름</div>
            <input onChange={(e) => setName(e.target.value)} value={name} />
            <div>분류</div>
            <select onChange={handleCategoryChange} value={category}>
                <option value="공항">공항</option>
                <option value="면세점">면세점</option>
            </select>
            <div>장소</div>
            <input onChange={(e) => setLocation(e.target.value)} value={location} />
            <div>이미지</div>
            <input type="file" accept="image/*" onChange={handleImageChange} />
            <Button variant="contained" color="primary" onClick={handleAddCoupon}>
                추가
            </Button>

            
        </div>
    );
}

