
import { AppBarMenu } from '../../style/BottonAppBar.module.js'
import React, { useState, useEffect } from 'react';
import { useNavigate } from "react-router-dom";
import Drawer from '@mui/material/Drawer';
import { MenuContainer, MenuItem } from './DrawerMenu.module.js';
import { PostCardContainerV2, TitleV2, DescV2 } from './PostCard.module.js';

export const PostCardV2 = ( { imgSrc, title, desc }) => {


  return (
    <PostCardContainerV2 url ={imgSrc}>
        <TitleV2>{title}</TitleV2>
        <DescV2>{desc}</DescV2>
    </PostCardContainerV2>
  );
}

