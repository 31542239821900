import { useLocation, useNavigate } from "react-router";
import ProgressBar from "./UIComponents/ProgressBar";
import Rating from "./UIComponents/Rating";

export const FeedbackRating = ({ ratingData, data }) => {
  const navigate = useNavigate();
  const location = useLocation();

  const handleFeedbackBtn = () => {
    const accessToken = localStorage.getItem("accessToken");

    if (accessToken) {
      navigate("/whisky365/review", { state: { data, accessToken } });
    } else {
      navigate("/loginbsdf", { state: { from: location } });
    }
  };

  return (
    <div>
      <div className="font-bold text-[16px] leading-[19px] mt-[30px]">
        <span>사용자 평점</span>
      </div>

      {ratingData ? (
        <>
          <div className="flex h-[68px] mt-[15px] rounded-[6px] bg-backgroundGray items-center justify-center">
            <div>
              <Rating rating={ratingData.average_rating} />
            </div>
          </div>
          <div className="flex h-[44px] mt-[8px] rounded-[6px] bg-backgroundGray text-center items-center justify-center text-[14px] w-full p-0">
            <span>
              <span className="font-bold text-Purple">
                {parseInt(ratingData.repurchase_willingness)}%
              </span>
              의 고객님들이 재구매의사가 있다고 답변주셨어요
            </span>
          </div>

          <div className="mt-[25px] text-[13px] mx-[16px] text-Gray70">
            <ProgressBar
              label="품질만족도"
              value={ratingData.quality_satisfaction}
              maxValue={100}
              color="#00C3CC"
              description="최고예요"
            />
            <ProgressBar
              label="재구매의사"
              value={ratingData.repurchase_willingness}
              maxValue={100}
              color="linear-gradient(to right, #0080FF, #B346D9)"
              description="있어요"
            />
          </div>

          <div className="w-full flex flex-col items-center my-[40px]">
            <button
              onClick={handleFeedbackBtn}
              className="bg-black text-white font-semibold py-3 mb-[30px]  w-full"
            >
              리뷰쓰기
            </button>
          </div>
        </>
      ) : (
        <>
          <div className="flex h-[68px] mt-[15px] rounded-[6px] bg-backgroundGray items-center justify-center">
            <div>
              <Rating rating={0} />
            </div>
          </div>
          <div className="flex h-[44px] mt-[8px] rounded-[6px] bg-backgroundGray text-center items-center justify-center text-[14px] w-full p-0">
            <span>
              <span className="font-bold text-Purple">{0}%</span>의 고객님들이
              재구매의사가 있다고 답변주셨어요
            </span>
          </div>

          <div className="mt-[25px] text-[13px] mx-[16px] text-Gray70">
            <ProgressBar
              label="품질만족도"
              value={0}
              maxValue={100}
              color="#00C3CC"
              description="최고예요"
            />
            <ProgressBar
              label="재구매의사"
              value={0}
              maxValue={100}
              color="linear-gradient(to right, #0080FF, #B346D9)"
              description="있어요"
            />
          </div>

          <div className="w-full flex flex-col items-center my-[40px]">
            <button
              onClick={handleFeedbackBtn}
              className="bg-black text-white font-semibold py-3 mb-[30px]  w-full"
            >
              리뷰쓰기
            </button>
          </div>
        </>
      )}
    </div>
  );
};
