
import React, { useState, useEffect } from 'react';
import { Layout, 
} from '../../style/Home/AirportCouponSection.module.js'
import { Container } from './CouponDisplay.module.js';
import { SnsShare } from '../_molecule/SnsShare.js';
import { client } from "../../client";
import { GET_COUPON } from './gql/queries.js';
import { CancelBtn } from './CouponDisplay.module.js';
import { useNavigate } from "react-router-dom";


export const CouponDisplay = ( { couponId }) => {
  const navigate = useNavigate();
    // 쿠폰 카카오 공유

    useEffect(() => {
    
        client
          .query({
            query: GET_COUPON,
            fetchPolicy: "no-cache",
            variables: {
                couponId : Number(couponId)
            }
          })
          .then((result) => {
            console.log(result.data.getCoupon);
            setCouponData(result.data.getCoupon)
          })
          .catch((err) => {
            console.log('error 발생', err)
          });
      }, [couponId]);


 const [couponData , setCouponData] = useState(null)


  return (
    <Layout>
      <Container >
      {/* onClick={copyToClipboard} */}
        <CancelBtn onClick={() => {navigate(-1);}} url ="https://dfx-img.s3.ap-northeast-2.amazonaws.com/btn_gnb_close%402x.png" >
          {/* 버튼 자리  */}
        </CancelBtn>
        <img src={couponData?.coupon_image?.[0]?.origin_image_url}></img>
        <SnsShare imgUrl={couponData?.coupon_image?.[0]?.origin_image_url}></SnsShare>
      </Container>
    </Layout>
  );
}

