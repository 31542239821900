import styled from "styled-components";

export const ButtonSeeMore = styled.div`  
    width: 140px;
    height: 46px;
    margin: auto;
    margin-top : 24px;
    padding: 15px 15px 15px 15px;
    box-sizing: border-box; /* padding과 border를 요소 크기에 포함 */
    border-radius: 23px;
    border: solid 1px #c7c7c7;
    background-color: #fff;
    text-align: center;
    

    font-family: Pretendard;
    font-size: 14px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;  
    color: #444;

`