
import React, { useState, useEffect } from 'react';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import { HomeContainer, ProductItemContainer, ProductItemInfo } from '../style/Home/Home.module';
import { HomeBanner } from '../component/home/HomeBanner';
import Autocomplete from '@mui/material/Autocomplete';

import { gql } from "@apollo/client";
import { client } from "../client";
import { TopMenu , MenuItem} from '../component/_molecule/admin/Admin.module';
import { CouponAdminSection } from '../component/_molecule/admin/CouponAdminSection';
import { PostAdminSection } from '../component/_molecule/admin/PostAdminSection';
import { SearchRank } from '../component/admin-search-rank/SearchRank';

export const Admin = () => {

    const [selectedMenu, setSelectedMenu] = useState("쿠폰"); // 기본 메뉴 선택

    // 해당 메뉴에 따라 콘텐츠를 반환하는 함수
    const renderContent = () => {
        switch (selectedMenu) {
            case "쿠폰":
                return (
                    <CouponAdminSection>
                    </CouponAdminSection>
                );
            case "여행 정보":
                return (
                    <PostAdminSection>
                    </PostAdminSection>
                );
            case "검색 순위":
                return (
                    <SearchRank>
                    </SearchRank>
                );  
            default:
                return null;
        }
    };

    return (
        <div>
            <TopMenu>
                <MenuItem>
                    <h3>DFP 어드민</h3>
                </MenuItem>
                <MenuItem onClick={() => setSelectedMenu("쿠폰")}>
                    쿠폰
                </MenuItem>
                <MenuItem onClick={() => setSelectedMenu("여행 정보")}>
                    여행 정보
                </MenuItem>
                <MenuItem onClick={() => setSelectedMenu("검색 순위")}>
                    검색 순위
                </MenuItem>
            </TopMenu>
            {renderContent()}
        </div>
    );
}

