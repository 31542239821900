import React, { useState } from "react";
import axios from "axios";
import { useMutation, gql } from "@apollo/client";

import { client } from "../../client";

export const FindPassword = () => {
  const [email, setEmail] = useState("");
  const [isSent, setIsSent] = useState(false);
  const [error, setError] = useState("");
  const [message, setMessage] = useState("");

  const handleEmailChange = (e) => {
    setEmail(e.target.value);
    setIsSent(false);
    setMessage("");
  };

  const validateEmail = (email) => {
    const regex = /^[\w-]+(\.[\w-]+)*@([\w-]+\.)+[a-zA-Z]{2,7}$/;
    return regex.test(email);
  };

  const FORGOT_PASSWORD = gql`
    mutation ForgotPassword($email: String!) {
      forgotPassword(email: $email) {
        result
      }
    }
  `;

  const handleSend = async () => {
    setError(""); // 기존 에러 상태 초기화

    try {
      console.log(
        "API 요청 URL:",
        `${process.env.REACT_APP_REST_URL}/auth/forgot-password`,
        "https://dev-api.dutyfree-price.com/auth/forgot-password"
      );
      console.log("전송할 이메일:", email);

      const response = await axios.post(
        // `${process.env.REACT_APP_REST_URL}/auth/forgot-password`,
        "https://dev-api.dutyfree-price.com/auth/forgot-password",
        { email }
      );

      console.log("응답 데이터:", response.data); // 서버로부터 받은 응답 데이터
      setIsSent(true);
      setMessage("이메일이 발송됐습니다.");
    } catch (error) {
      console.error("에러 발생:", error); // 에러 세부사항 출력
      setError("이메일 발송에 실패했습니다. 다시 시도해 주세요.");
    }
  };

  const handleSendGql = () => {
    setError(""); // Reset existing error state

    if (!validateEmail(email)) {
      setError("유효한 이메일 주소를 입력해 주세요.");
      return;
    }

    client
      .mutate({
        mutation: FORGOT_PASSWORD,
        variables: { email },
        fetchPolicy: "no-cache",
      })
      .then((result) => {
        console.log("Mutation result:", result.data?.forgotPassword);

        // Check the result of the mutation
        if (result.data?.forgotPassword.result === "success") {
          setIsSent(true);
          setMessage("이메일이 발송됐습니다."); // Success message
        } else {
          setError("이메일 발송에 실패했습니다. 다시 시도해 주세요.");
        }
      })
      .catch((err) => {
        console.error("에러 발생:", err); // Log error details
        setError("이메일 발송에 실패했습니다. 다시 시도해 주세요.");
      });
  };

  return (
    <div style={styles.outerContainer}>
      <div style={styles.container}>
        <h2 style={styles.title}>비밀번호 찾기</h2>
        <p style={styles.description}>
          비밀번호를 재설정할 이메일을 입력해 주세요.
        </p>
        <input
          type="email"
          placeholder="이메일 입력"
          value={email}
          onChange={handleEmailChange}
          style={styles.input}
        />
        <button
          onClick={handleSendGql}
          disabled={isSent}
          style={isSent ? styles.disabledButton : styles.button}
        >
          {isSent ? "발송됨" : "발송"}
        </button>
        {error && <p style={styles.error}>{error}</p>}
        {message && <p style={styles.message}>{message}</p>}
      </div>
    </div>
  );
};

const styles = {
  outerContainer: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    height: "100vh",
  },
  container: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    width: "100%",
    maxWidth: "500px",
    minWidth: "350px",
    margin: "0 auto",
    padding: "20px",
    border: "1px solid #ddd",
    borderRadius: "8px",
    boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
    transform: "translateY(-70%)",
  },
  title: {
    fontSize: "24px",
    fontWeight: "bold",
    color: "#333333",
    marginBottom: "10px",
  },
  description: {
    fontSize: "16px",
    color: "#666666",
    marginBottom: "20px",
  },
  input: {
    padding: "12px",
    marginBottom: "15px",
    width: "100%",
    borderRadius: "4px",
    border: "1px solid #ccc",
  },
  button: {
    padding: "12px 20px",
    backgroundColor: "#4CAF50",
    color: "#ffffff",
    border: "none",
    borderRadius: "4px",
    cursor: "pointer",
    width: "100%",
  },
  disabledButton: {
    padding: "12px 20px",
    backgroundColor: "#cccccc",
    color: "#666666",
    border: "none",
    borderRadius: "4px",
    cursor: "not-allowed",
    width: "100%",
  },
  error: {
    color: "red",
    marginTop: "15px",
  },
  message: {
    color: "green",
    marginTop: "15px",
  },
};
