import styled from "styled-components";

export const Layout = styled.div`
  display: flex;
  justify-content: center;
  height: 60px;
  padding: 0px 10px;

  // margin-top: 4rem;
`;

export const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 60px;
  width: 360px;
  padding-right: 6px;
  // padding: 0 20px;
`;

export const Title = styled.div`  
font-family: Pretendard;
font-size: 18px;
font-weight: 600;
font-stretch: normal;
font-style: normal;
line-height: normal;
letter-spacing: normal;
text-align: center;
color: #000;
`

export const DrawerMenuButton = styled.div`
    display: flex;
    align-items: center; /* Vertically centers the content */
    height: 60px;
`