import styled from "styled-components";

export const FilterBrandButton = styled.button`
  background-color: ${(props) => (props.showBrandList ? "#703eff" : "#efefef")};
  color: ${(props) => (props.showBrandList ? "#fff" : "#000")};
  border-radius: 20px;
  padding: 7px 5px;
  cursor: pointer;
  font-size: 16px;
  outline: none;
  width: 85px;
  border: none;
  transition: background-color 0.3s ease;
`;

export const FilterButton = styled.button`
  background-color: ${(props) => (props.showList ? "#703eff" : "#efefef")};
  color: ${(props) => (props.showList ? "#fff" : "#000")};
  border-radius: 20px;
  padding: 7px 5px;
  cursor: pointer;
  font-size: 16px;
  outline: none;
  width: 85px;
  border: none;
  transition: background-color 0.3s ease;
`;

export const PriceButton = styled.button`
  background-color: black;
  position: absolute;
  color: #fff;
  outline: none;
  border: none;
  width: 22%;
  height: 40px;
  border-radius: 10px;
  right: 0;

  &: hover {
    cursor: grab;
  }
`;

export const PriceInputStyled = styled.div`
  display: inline-flex;
  align-items: center;
  position: relative;
  width: 33%;
  height: 40px;
  font-size: 16px;
  font-weight: 600;
  border-radius: 10px;
  text-align: center;
  border: 3px solid #efefef;

  input {
    width: 100%;
    height: 100%;
    padding-left: 30px;
    border: none;
    border-radius: 10px;
    font-size: 16px;
    font-weight: 600;
    outline: none;
  }

  &::before {
    content: "$";
    position: absolute;
    left: 10px;
    font-size: 16px;
    font-weight: 600;
  }
`;

export const InputLayout = styled.div`
  background-color: #;
  justify-content: center;
  display: flex;
  border-bottom: 12px solid #efefef; /* 하단에 굵은 회색 라인 */
  padding-bottom: 20px;
  margin-bottom: 20px;
`;

export const BrandContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(4, 1fr); /* 3 columns */
  grid-gap: 20px; /* Adjust the gap between items as needed */
  padding-top: 24px;
  padding-bottom: 24px;
  /* Add any additional styles for the container here */
  width: 360px;
`;
